import { useEffect } from 'react';

import {
  useOverviewMutation,
} from 'client/services/api';
import type { Overview } from 'client/services/types/overview';
import { useAppSelector } from 'client/store';

const useMortgageOverview = () => {
  const [postOverview, { isLoading, isError, data: overviewData }] =
    useOverviewMutation({
      fixedCacheKey: 'overview',
    });

  const state = useAppSelector<Overview>((appState) => appState.overview);

  // Refetch the auth data if we've refreshed the page and it's not cached
  useEffect(() => {
    const refetchOverview = async () => {
      await postOverview();
    };

    if (!overviewData && !state?.overview) {
      refetchOverview();
    }
  }, [overviewData, state, postOverview]);

  return {
    isLoading,
    isError,
    overviewData: state,
  };
};

export default useMortgageOverview;
