import { AppContent } from '../AppContent';

const redemptionStatement: AppContent['redemptionStatement'] = {
  pageTitle: 'Request a redemption statement',
  backButtonLabel: 'Back',
  requestInformation: {
    openingParagraph:
      'If you would like to know the outstanding balance on your mortgage, we can send you a redemption statement in the post.',
    listHeading: 'What you need to know',
    sectionListItems: [
      'We can calculate to the month end or to a specific date.',
      "We'll post your redemption statement within 5 working days.",
      'We can only email you if the email you provide matches the one we already hold on our system.',
      'We can only deliver to the correspondence address we currently hold on our system.',
    ],
  },
  formInstruction: 'Complete the following information:',
};

export default redemptionStatement;
