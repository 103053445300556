import styled from "styled-components";

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing_02};
  padding-bottom: ${({ theme }) => theme.spacing_02};
`;

export const TextIconContainer = styled.div`
  display: flex;
  align-items: center;`;