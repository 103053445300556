export interface EmailSchemaErrorMessages {
  wrongType: string;
  empty: string;
  onlySpaces: string;
  badPattern: string;
  tooLong: string;
}

interface EmailSchemaOptions {
  required?: boolean;
}

export function EmailSchema(
  errorMessages: EmailSchemaErrorMessages,
  options?: EmailSchemaOptions,
) {
  return (value: any) => {
    const ONLY_SPACES_REGEX = /^\s+$/;
    const MAX_LENGTH = 320;
    const VALID_TYPE = 'string';

    // an email string must:
    //   begin with 1 or more of these characters: A-Z0-9_!#$%&'*+/=?`{|}~^-
    //   then optionally have a dot (.) followed by 1 or more of these characters: A-Z0-9_!#$%&'*+/=?`{|}~^-
    //   then have 1 @ symbol
    //   then have 1 or more alphanumeric characters
    //   then optionally have a dot or hyphen (.-) followed by 1 or more alphanumeric characters
    //   then end with an alphanumeric character
    //   it is also case insensitive
    const VALID_EMAIL_REGEX =
      /^[A-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[A-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[A-Z0-9]+(?:[.-][A-Z0-9]+)*$/i;

    if (options?.required && !value) {
      return errorMessages.empty;
    }

    if (typeof value !== VALID_TYPE) {
      return errorMessages.wrongType;
    }

    if (value.length > MAX_LENGTH) {
      return errorMessages.tooLong;
    }

    if (ONLY_SPACES_REGEX.test(value)) {
      return errorMessages.onlySpaces;
    }

    if (value && !VALID_EMAIL_REGEX.test(value)) {
      return errorMessages.badPattern;
    }

    return undefined;
  };
}
