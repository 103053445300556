import React, { useEffect } from 'react';

import {
  Button,
  ButtonPattern,
  Grid,
  GridItem,
  Link,
  Main,
  Notification,
  Paragraph,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { RedemptionUnavailableContent } from './RedemptionUnavailablePage.config';

function RedemptionUnavailablePage() {
  const content = useContent<RedemptionUnavailableContent>();
  const navigate = useNavigate();
  const { requestFormsEnabled } = window.appConfig;

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '2',
      stepName: 'Redemption - Unable to show figures',
      action: 'Error',
      actionNarrative: 'Unable to show figures',
      applicationState: 'Declined',
    });
  }, []);

  const {
    heading,
    requestPaperStatementParagraph,
    orText,
    callUsText,
    telLinkNumber,
    callUsSuffix,
    availabilityText,
    returnLinkLabel,
  } = content;

  return (
    <Main>
      <Grid>
        <GridItem xs={12} lg={8}>
          <Notification
            sentiment="warning"
            heading={heading}
            data-testid="redemption-unavailable-notification"
          >
            <Paragraph data-testid="redemption-unavailable-notification-paragraph-1">
              {requestFormsEnabled ? (
                <>
                  <Text data-testid="request-paper-statement-paragraph">
                    <Link
                      onClick={() => {
                        logTealiumButtonClickEvent({
                          label: 'link/request-paper-statement',
                        });
                        navigate(routes.RequestRedemptionStatement);
                      }}
                      data-testid="request-paper-statement-link"
                    >
                      {requestPaperStatementParagraph}
                    </Link>
                  </Text>
                  <Text>{orText}</Text>
                  <Text>{callUsText.toLowerCase()}</Text>
                </>
              ) : (
                <Text>{callUsText}</Text>
              )}
              <Link
                href={`tel:${telLinkNumber.replace(/\s/g, '')}`}
                data-testid="help-telephone-link"
                onClick={() => {
                  logTealiumButtonClickEvent({
                    label: 'button/helpline',
                  });
                }}
              >
                {telLinkNumber}
              </Link>
              {callUsSuffix}
            </Paragraph>
            <Paragraph
              marginBottom="none"
              data-testid="redemption-unavailable-notification-paragraph-2"
            >
              {availabilityText}
            </Paragraph>
          </Notification>
          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-mortgage-overview',
                });
                navigate(routes.MortgageOverview);
              }}
              data-testid="return-to-overview-button"
            >
              {returnLinkLabel}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default RedemptionUnavailablePage;
