import React from 'react';

import { useTheme } from 'styled-components';

function IllustrationCardError() {
  const theme: any = useTheme();

  return (
    <svg
      width="64"
      height="48"
      viewBox="0 0 64 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Bank card with cross icon</title>
      <g clipPath="url(#clip0_7124_53939)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55 1.50098C57.6888 1.50098 59.8818 3.62328 59.9954 6.28409L60 6.50098V27.5908C60 30.2796 65.9124 36.3964 58.8996 44.042L49.8831 46.942L46.5937 45.4484L44.2412 43.501H5C2.31125 43.501 0.118182 41.3787 0.00461951 38.7179L0 38.501V6.50098C0 3.81222 2.12231 1.61916 4.78311 1.5056L5 1.50098H55ZM58 21.501H2V38.501C2 40.0987 3.24892 41.4046 4.82373 41.4959L5 41.501H43.0284C44.6261 41.501 48.3525 46.8763 48.4437 45.3015L53.5751 45.8562L58.8996 44.042L62.5905 38.2419V32.1254L58 26.589V21.501ZM13 33.501V35.501H8V33.501H13ZM20 33.501V35.501H15V33.501H20ZM27 33.501V35.501H22V33.501H27ZM23 27.501V29.501H8V27.501H23ZM58 12.501H2V19.501H58V12.501ZM55 3.50098H5C3.40232 3.50098 2.09634 4.7499 2.00509 6.3247L2 6.50098V10.501H58V6.50098C58 4.9033 56.7511 3.59732 55.1763 3.50607L55 3.50098Z"
          fill={theme.color_icon_default_2}
        />
        <rect
          x="41"
          y="24.501"
          width="22"
          height="22"
          rx="11"
          stroke={theme.color_icon_default_1}
          strokeWidth="2"
        />
        <path
          d="M48 31.501L56 39.501"
          stroke={theme.color_icon_default_1}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M56 31.501L48 39.501"
          stroke={theme.color_icon_default_1}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7124_53939">
          <rect
            width="64"
            height="47"
            fill="white"
            transform="translate(0 0.500977)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IllustrationCardError;
