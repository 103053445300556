import { AppContent } from '../AppContent';

const addReminder: AppContent['addReminder'] = {
  pageHeading: 'Add a reminder',
  backButtonLabel: 'Back',
  continueButtonLabel: 'Continue',
  reminderTypeLabel: 'What do you want to be reminded about?',
  renewalDateLabel: "What's the renewal or expiry date?",
  renewalSupportiveText:
    "We'll send you a reminder email 30 days before this date.",
  noteLabel: 'Add a note (optional)',
  noteSupportiveText: 'Notes will appear in your reminder email.',
  validationErrors: {
    reminderType: {
      required: 'Choose what you want to be reminded about',
    },

    renewalDate: {
      invalidDate: 'Check the date and try again',
      invalidMonth: 'Check the month and try again',
      invalidYear: 'Check the year and try again',
    },
    note: {
      tooLong: "Notes can't be longer than 320 characters. Try again",
    },
  },
};

export default addReminder;
