import { AppContent } from '../AppContent';

const duplicateStatement: AppContent['duplicateStatement'] = {
  pageHeading: 'Request a duplicate statement',
  backButtonLabel: 'Back',
  openingSection: {
    firstParagraph:
      "We'll send you a mortgage statement each year on the anniversary of when you took out your mortgage with us.",
    secondParagraph:
      "If you'd like another copy of this statement, fill in this form.",
  },
  whatYouNeedToKnow: {
    heading: 'What you need to know',
    listItems: [
      "Select 'Interim' in the statement type for a statement covering the last 12 months.",
      "Select 'Annual' in the statement type for a duplicate statement.",
      "You'll receive your statement within 5 working days.",
      'We can only email you if the email you provide matches the one we already hold on our system.',
      'We can only deliver to the correspondence address we currently hold on our system.',
    ],
  },
  formInstruction: 'Complete the following information:',
};
export default duplicateStatement;
