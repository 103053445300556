import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';

const mortgageOverview: AppContent['mortgageOverview'] = {
  title: 'Your balance',
  tabHeaders: {
    overview: 'Overview',
    manage: 'Manage',
    tools: 'Tools',
  },

  overviewTab: {
    welcomeMessage: 'Welcome',
    notifications: {
      heading: 'Notifications',
      switchNotification: {
        heading: "It's time to switch your deal",
        supportiveText: 'A new deal could save you money.',
      },
      futureFeaturesNotification: {
        line1: 'Now you can manage your mortgage online',
        line2: "We'll be rolling out more features soon.",
        linkText: 'Let us know what you think.',
      },
    },
    propertyPanel: {
      propertyValueLabel: 'Estimated Property Value',
      sincePurchaseLabel: ' since purchase',
    },
    propertyValue: {
      title: 'Estimated Property Value',
      paragraph1: "We've based this on our valuation from local market data.",
      paragraph2:
        "Your home's true value might be higher or lower than the figure shown. This will depend on your home's condition and if you've made any changes we don't know about.",
      okayButtonLabel: 'Okay',
    },
    loanToValuePanel: {
      heading: 'Loan to Value (LTV)',
      equityLabel: 'Equity',
      balanceLabel: 'Loan Balance',
      whatIsLTVModal: {
        label: 'What is Loan to Value?',
        heading: 'Loan to Value',
        supportiveText:
          'Loan to value (LTV) is the percentage of borrowing you take out against your home. For example, if you have a £150,000 mortgage on a £200,000 house, the loan to value would be 75%.',
      },
      borrowMoreCTA: {
        url: routes.BorrowMore,
        heading: 'Borrow more',
        supportiveText: 'Our maximum LTV is 80%.',
      },
    },
    stampBookPanel: {
      heading: 'Your Birmingham Midshires mortgages',
      supportiveTextPart1: 'You have ',
      mortgageSingular: ' mortgage',
      mortgagePlural: ' mortgages',
      supportiveTextPart2: ' out of a maximum of ',
      findOutMore: 'Find out how much you could borrow',
    },
    mortgageInfoPanel: {
      accountNumberLabel: 'Mortgage account number',
      mortgageTermLabel: 'Remaining mortgage term',
      interestRatesLabel: 'Interest rates',
      subAccountsLabel: ' sub-accounts',
      subAccountsModal: {
        label: 'What are sub-accounts?',
        heading: 'Sub-accounts',
        supportiveText:
          'Your mortgage could be a mix of different repayment methods with different interest rates over different mortgage terms. For example, you could have individual balances for additional borrowing or fees. These are known as sub-accounts. Each sub-account has its own individual monthly payment, which we add together to make up your total monthly mortgage payment.',
      },
      monthlyPaymentLabel: 'Current monthly payment',
      nextPaymentLabel: 'Next payment due',
    },
    paymentHelpPanel: {
      heading: 'Worried about making payments?',
      helpNumber: '0808 145 0372',
      period: '.',
      paragraph1: "If you're worried about making future payments, call us on",
      paragraph2:
        "Lines are open Monday to Friday 8am to 8pm, and Saturday 9am to 1pm. We're closed Sundays and bank holidays.",
      paragraph3: 'The sooner you contact us, the sooner we can help.',
    },
  },
  manageTab: {
    switchYourDeal: {
      switchYourDealEligibleText: 'You can change your mortgage deal.',
      switchYourDealNotEligibleText: 'You can change your mortgage deal from ',
    },
    borrowMore: {
      borrowMoreEligibleText: 'You can apply for additional borrowing.',
      borrowMoreNotEligibleText: 'You can apply for additional borrowing from ',
    },
    navigationTiles: {
      switchYourDeal: {
        linkText: 'Switch your deal',
        boxText: '',
        url: routes.ProductTransfer,
        linkTestId: 'navigation-product-transfer',
      },
      borrowMore: {
        linkText: 'Borrow more',
        boxText: '',
        url: routes.BorrowMore,
        linkTestId: 'navigation-borrow-more',
      },
      manageDirectDebit: {
        linkText: 'Manage your direct debit',
        boxText: 'Set up or change your direct debit.',
        url: routes.ManageDirectDebit,
        linkTestId: 'navigation-direct-debit',
      },
    },
  },
  toolsTab: {
    navigationTiles: {
      OverpaymentAllowance: {
        linkText: 'Overpayment allowance',
        boxText: 'Find out how much you can overpay without any charges.',
        url: routes.OverpaymentAllowance,
        linkTestId: 'navigation-overpayment',
      },
      Redemption: {
        linkText: 'Paying off your mortgage',
        boxText:
          'Understand how much paying your mortgage off in full could cost.',
        url: routes.Redemption,
        linkTestId: 'navigation-payingoff',
      },
      RentalYield: {
        linkText: 'Rental yield calculator',
        boxText:
          'Calculate the gross and net rental yields on a rented property.',
        url: routes.RentalYield,
        linkTestId: 'navigation-rental-yield',
      },
      Reminders: {
        linkText: 'Renewal reminders',
        boxText: 'Set up reminders for all your properties renewals.',
        url: routes.ManageReminder,
        linkTestId: 'navigation-reminders',
      },
      EnergyEfficiency: {
        linkText: 'Energy efficiency action plan',
        boxText:
          'Discover ways to improve the energy efficiency of your properties.',
        url: 'https://home-energy-saving-tool.bmsolutions.co.uk/wheredoyoulive',
        linkTestId: 'navigation-energy-efficiency',
        external: true,
      },
    },
  },
  overpaymentsPanel: {
    title: 'Your overpayment allowance',
    supportiveTextPara1:
      'Find out how much you can overpay without incurring early repayment charges.',
    overpaymentLink: {
      text: 'Find out how much you can overpay',
      url: routes.OverpaymentAllowance,
    },
  },
  redemptionPanel: {
    title: 'Paying off your mortgage',
    supportiveText:
      'Find out how much paying your mortgage off in full could cost.',
    link: {
      text: 'Find out the cost',
      url: routes.Redemption,
    },
  },
  rentalYieldPanel: {
    title: 'Rental yield calculator',
    supportiveText:
      'Rental yield helps you figure out how much income you can earn from investing in a rental property.',
    linkLabel: 'Calculate',
  },
  requestsPanel: {
    heading: 'Actions',
    certificateOfInterestLink: {
      text: 'Request a Certificate of Mortgage Interest',
      url: routes.RequestCertificateOfInterest,
    },
    changeOfDirectDebitLink: {
      text: 'Change your direct debit date',
      url: routes.RequestChangeDirectDebit,
    },
    duplicateStatementLink: {
      text: 'Request a duplicate statement',
      url: routes.RequestDuplicateStatement,
    },
  },
  energyEfficiencyPanel: {
    text: "Improve your property's energy efficiency",
    link: {
      text: 'Get your Action Plan',
      url: 'https://home-energy-saving-tool.bmsolutions.co.uk/wheredoyoulive',
    },
  },
  feedbackPanel: {
    text: "We'd love to hear what you think",
    link: {
      text: 'Give us your feedback',
      url: 'https://lloydsbankinggroup.eu.qualtrics.com/jfe/form/SV_07lOersw6HAG5tY',
    },
  },
  whatsNewDialog: {
    versionNumber: 'v1.1',
    cookieName: 'whatsNewVersion',

    title: 'New tools to manage your property',
    icons: ['Home'],
    subheadings: [
      'Overpayments',
      'Paying off your mortgage',
      'Rental yield calculator',
    ],
    paragraphs: [
      'Find out how much you can overpay on your mortgage without incurring any early repayment charges.',
      'Find out how much it would cost to pay off your mortgage in full.',
      "Find out your return on investment. Enter your property value and rental income and we'll tell you your rental yield percentage.",
    ],
    buttonLabel: 'Got it',
  },
};

export default mortgageOverview;
