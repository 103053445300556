import React from 'react';

import { IconChevronRight, Text } from '@constellation/core';
import { Link as RoutingNavLink } from '@interstellar/react-app-routing';

import { StyledLink } from './FullWidthLink.styled';

interface FullWidthLinkProps {
  children?: any;
  external?: boolean;
  to: string;
  'data-testid'?: string;
  onClick?: React.MouseEventHandler;
}

function FullWidthLink({
  children,
  external,
  to,
  'data-testid': testId,
  onClick,
}: FullWidthLinkProps) {
  return (
    <StyledLink
      as={RoutingNavLink}
      to={to}
      target={external ? '_blank' : '_self'}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (onClick) {
          onClick(e);
        }
        if (!external) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }}
      data-testid={testId}
    >
      <Text color="inherit">{children}</Text>
      <IconChevronRight iconSize="md" />
    </StyledLink>
  );
}

export default FullWidthLink;
