import React, { useEffect } from 'react';

import {
  Main,
  Heading,
  Paragraph,
  ListItem,
  List,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { ORDER_DOC_REDEMPTION_STATEMENT } from 'client/services/types/orderDocument';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { RedemptionStatementContent } from './RedemptionStatementPage.config';
import * as routes from '../../manifest';
import OrderDocumentForm from '../components/orderDocumentForm/OrderDocumentForm';

export default function RedemptionStatementPage() {
  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Request a Redemption statement',
      applicationState: 'Pre-Application',
    });
  }, []);

  const navigate = useNavigate();

  const { pageTitle, requestInformation, backButtonLabel, formInstruction } =
    useContent<RedemptionStatementContent>();

  return (
    <Main>
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent({ label: 'button/back' });
          navigate(routes.MortgageOverview);
        }}
      >
        {backButtonLabel}
      </Link>
      <Heading
        as="h1"
        size="s7"
        marginTop="05"
        data-testid="redemption-statement-page-heading"
      >
        {pageTitle}
      </Heading>
      <Paragraph data-testid="opening-section-first-paragraph">
        {requestInformation.openingParagraph}
      </Paragraph>
      <Heading
        data-testid="what-you-need-to-know-section-heading"
        as="h2"
        size="s4"
      >
        {requestInformation.listHeading}
      </Heading>
      <List data-testid="what-you-need-to-know-list">
        {requestInformation.sectionListItems.map((item, index) => (
          <ListItem
            data-testid={`what-you-need-to-know-list-item-${index}`}
            key={item}
          >
            {item}
          </ListItem>
        ))}
      </List>
      <Paragraph data-testid="debit-change-form-instruction">
        {formInstruction}
      </Paragraph>
      <OrderDocumentForm orderDocKey={ORDER_DOC_REDEMPTION_STATEMENT} />
    </Main>
  );
}
