import { AppContent } from '../AppContent';

const successfulRequest: AppContent['successfulRequest'] = {
  backButtonLabel: 'Back',
  pageTitle: "We've got your request",
  fallbackSuccessMessage:
    'Please allow 5 working days for your documents to arrive in the post.',

  successMessageCertificateOfInterest:
    'Please allow 5 working days for the Certificate of Mortgage Interest letter to arrive in the post.',

  successMessageChangeDirectDebit:
    'Please allow 5 working days for the new payment date letter to arrive in the post.',

  successMessageDuplicateStatement:
    'Please allow 5 working days for your duplicate statement to arrive in the post.',

  successMessageOverpaymentAllowance:
    'Please allow 5 working days for the overpayment allowance letter to arrive in the post.',

  successMessageRedemptionStatement1:
    'Please allow 5 working days for the redemption statement to arrive in the post.',

  successMessageRedemptionStatement2:
    "It'll include the total cost and different ways you can make the payment.",

  returnToOverviewLink: 'Return to mortgage overview',
};

export default successfulRequest;
