import React, { useEffect } from 'react';

import {
  Heading,
  List,
  ListItem,
  Main,
  Paragraph,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE } from 'client/services/types/orderDocument';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { DebitChangeRequestContent } from './DebitChangeRequest.config';
import * as routes from '../../manifest';
import OrderDocumentForm from '../components/orderDocumentForm/OrderDocumentForm';

export default function DebitChangeRequestPage() {
  const {
    pageHeading,
    openingSection,
    whatYouNeedToKnow,
    formInstruction,
    backButtonLabel,
  } = useContent<DebitChangeRequestContent>();

  const navigate = useNavigate();
  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Request a change to your direct debit due date',
      applicationState: 'Pre-Application',
    });
  }, []);

  return (
    <Main>
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent({ label: 'button/back' });
          navigate(routes.MortgageOverview);
        }}
      >
        {backButtonLabel}
      </Link>
      <Heading
        as="h1"
        size="s7"
        marginTop="05"
        data-testid="debit-change-header"
      >
        {pageHeading}
      </Heading>
      <Paragraph data-testid="debit-change-opening-section">
        {openingSection.firstParagraph}
      </Paragraph>
      <Heading data-testid="what-you-need-to-know-header" as="h2" size="s4">
        {whatYouNeedToKnow.heading}
      </Heading>
      <List data-testid="what-you-need-to-know-list">
        {whatYouNeedToKnow.listItems.map((item, index) => (
          <ListItem
            data-testid={`what-you-need-to-know-list-item-${index}`}
            key={item}
          >
            {item}
          </ListItem>
        ))}
      </List>
      <Paragraph data-testid="debit-change-form-instruction">
        {formInstruction}
      </Paragraph>
      <OrderDocumentForm orderDocKey={ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE} />
    </Main>
  );
}
