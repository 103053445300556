import { AppContent } from '../AppContent';

const mortgageInterest: AppContent['mortgageInterest'] = {
  pageHeading: 'Request a Certificate of Mortgage Interest',
  backButtonLabel: 'Back',
  openingSection: {
    firstParagraph:
      "If you would like to request a Certificate of Mortgage Interest, complete this form and you'll receive it within 5 working days.",
  },
  whatYouNeedToKnowSection: {
    heading: 'What you need to know',

    listItems: [
      'This form is for UK Tax Certificates only.',
      'You can only request a certificate for the previous tax year.',
      'We can only email you if the email you provide matches the one we already hold on our system.',
      'We can only deliver to the correspondence address we currently hold on our system.',
    ],
  },
  formInstruction: 'Complete the following information:',
};

export default mortgageInterest;
