// Auth
export const SignedOut = '/signed-out';
export const SignIn = '/sign-in';

// General Errors
export const Error = '/error';
export const ServiceUnavailable = '/service-unavailable';

// Make a payment
export const MakePayment = '/make-payment';
export const MakePaymentBankTransfer = '/make-payment/bank-transfer';
export const MakePaymentPhone = '/make-payment/phone';

// Overview and secondary pages
export const MortgageOverview = '/mortgage-overview';
export const Manage = '/mortgage-overview/manage';
export const Tools = '/mortgage-overview/tools';
export const BorrowMore = '/borrow-more';
export const Overpayments = '/overpayments';
export const ProductTransfer = '/product-transfer';
export const RentalYield = '/rental-yield';

// Overpayment Allowance
export const OverpaymentAllowance = '/overpayment-allowance';
export const OverpaymentAllowanceAccountWarning =
  '/overpayment-allowance/unavailable';
export const OverpaymentAllowanceTechnicalError =
  '/overpayment-allowance/error';

// Redemption
export const Redemption = '/redemption';
export const RedemptionAlreadyRequested = '/redemption/already-requested';
export const RedemptionError = '/redemption/error';
export const RedemptionSuccess = '/redemption/success';
export const RedemptionUnavailable = '/redemption/unavailable';

// Statement Requests
export const RequestChangeDirectDebit = '/requests/change-direct-debit';
export const RequestDuplicateStatement = '/requests/duplicate-statement';
export const RequestCertificateOfInterest = '/requests/certificate-of-interest';
export const RequestOverpaymentsAllowance = '/requests/overpayments-allowance';
export const RequestRedemptionStatement = '/requests/redemption-statement';
export const RequestSuccessful = '/requests/success';

// Direct debits - View/manage
export const ManageDirectDebit = '/manage/direct-debit';

// Direct debits - View/manage - help pages
export const ManageDirectDebitFailed =
  '/manage/direct-debit/my-direct-debit-failed';
export const RestartCancelledDirectDebit =
  '/manage/direct-debit/restart-cancelled-direct-debit';
export const ManageDirectDebitChangeAmount =
  '/manage/direct-debit/change-amount';
export const ManageDirectDebitCancelDirectDebit = '/manage/direct-debit/cancel';

// Direct debits - change date
export const ManageDirectDebitChangeDatePreamble =
  '/manage/direct-debit/change-date/before-you-start';
export const ManageDirectDebitChangeDateForm =
  '/manage/direct-debit/change-date';
export const ManageDirectDebitChangeDateSummary =
  '/manage/direct-debit/change-date/summary';
export const ManageDirectDebitChangeDateConfirmation =
  '/manage/direct-debit/change-date/confirmation';
export const ChangeDateWarning = '/manage/direct-debit/change-date/warning';
export const ManageDirectDebitChangeDateError =
  '/manage/direct-debit/change-date/error';
export const ManageDirectDebitChangeDateUnavailable =
  '/manage/direct-debit/change-date/unavailable';

// Direct debits - change bank account
export const ManageDirectDebitBankAccountPreamble =
  '/manage/direct-debit/change-bank-account/before-you-start';
export const ManageDirectDebitChangeBankAccountForm =
  '/manage/direct-debit/change-bank-account';
export const ChangeDateRaisedWarning =
  '/manage/direct-debit/change-date/raised';

// Certificate reminders
export const ManageReminder = '/tools/reminders';
export const AddReminderSummary = '/tools/reminders/summary';
export const AddReminder = '/tools/reminders/add-reminder';
export const AddReminderEmailForm = '/tools/reminders/add-email';
