import React, { useEffect } from 'react';

import {
  Box,
  Heading,
  Hr,
  IconChevronLeft,
  Link,
  Main,
  Notification,
  Paragraph,
  Text,
  Container,
  Grid,
  GridItem,
  Button,
  Spinner,
  IconPound,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import {
  Navigate,
  useLocation,
  useNavigate,
} from '@interstellar/react-app-routing';
import ddLogo from 'assets/common/dd-logo.png';
import { formatCurrency } from 'client/routes/mortgageOverview/utils/formatCurrency';
import formatDate from 'client/routes/mortgageOverview/utils/formatDate';
import { useDirectDebitQuery } from 'client/services/api';
import {
  DirectDebitDetails,
  FuturePaymentDetail,
} from 'client/services/types/directDebits';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import ChangeDateConfirmationContent from './ChangeDateConfirmationPage.config';
import {
  DetailsRow,
  StyledButtonContainer,
  TextIconContainer,
} from './ChangeDateConfirmationPage.styled';
import * as routes from '../../../manifest';
import DirectDebitDetailsPanel from '../../components/DirectDebitDetailsPanel';
import { BreakoutPage } from '../../ManageDirectDebitPage.styled';

export interface DDChangeDateConfirmationData {
  futurePayments: FuturePaymentDetail[];
  newDDDate: number;
}

function ChangeDateConfirmationPage() {
  const {
    heading,
    notificationHeading,
    notificationText1,
    notificationText2,
    summary,
    returnToManageButtonLabel,
  } = useContent<ChangeDateConfirmationContent>();
  const navigate = useNavigate();
  const location = useLocation();

  const { futurePayments, newDDDate } =
    (location.state as DDChangeDateConfirmationData) || {};

  const { data, isError, isLoading } = useDirectDebitQuery();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Direct debits - Change date - Confirmation',
      applicationState: 'Fulfilled',
    });
  }, []);

  if (!futurePayments || !newDDDate) {
    return <Navigate to={routes.ManageDirectDebit} replace />;
  }

  if (isLoading) {
    return (
      <BreakoutPage>
        <Main>
          <Container data-testid="loading-container">
            <Spinner />
          </Container>
        </Main>
      </BreakoutPage>
    );
  }

  if (isError || data.errors.length > 0) {
    return <Navigate to={routes.Error} replace />;
  }

  const { currentDDDetails } = data;
  const updatedDDDetails: DirectDebitDetails = {
    ...currentDDDetails[0],
    paymentDate: newDDDate,
  };

  return (
    <Main>
      <Link
        icon={<IconChevronLeft trim />}
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent({ label: 'button/return-to-manage-top' });
          navigate(routes.Manage);
        }}
      >
        {returnToManageButtonLabel}
      </Link>
      <Hr />

      <Grid>
        <GridItem lg={8}>
          <Heading as="h1">{heading}</Heading>

          <Notification
            heading={notificationHeading}
            sentiment="success"
            marginBottom="04"
          >
            <Paragraph marginBottom="none">
              {notificationText1} {newDDDate?.toString().padStart(2, '0')}{' '}
              {notificationText2}
            </Paragraph>
          </Notification>

          <Box data-testid="direct-debit-summary-box">
            <Heading as="h2">{summary.heading}</Heading>
            <Paragraph>{summary.text}</Paragraph>
            <Container
              width="fluid"
              marginTop="07"
              marginBottom="07"
              padding="none"
            >
              <img src={ddLogo} alt="DD logo" />
            </Container>

            <Text weight="bold">{summary.directDebitDetailsHeading}</Text>
            <DirectDebitDetailsPanel directDebit={updatedDDDetails} />

            <Text weight="bold">{summary.estimatedNextPaymentsLabel}</Text>
            <Box bgColor="neutral2" data-testid="future-payments-box">
              {futurePayments.map((futurePayment, index) => (
                <React.Fragment key={`${futurePayment.paymentDate}-fragment`}>
                  <DetailsRow
                    key={`${futurePayment.paymentDate}-future-payment`}
                  >
                    <TextIconContainer>
                      <IconPound size="s4" color="brand" trim />
                      <Text weight="bold" marginLeft="02">
                        {formatDate(futurePayment.paymentDate, true)}
                      </Text>
                    </TextIconContainer>

                    <Text color="subdued">
                      {formatCurrency(futurePayment.paymentAmount)}
                    </Text>
                  </DetailsRow>
                  {index < futurePayments.length - 1 && (
                    <Hr
                      key={`${futurePayment.paymentDate}-hr`}
                      marginTop="02"
                      marginBottom="02"
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>

          <Hr marginTop="07" marginBottom="07" />

          <StyledButtonContainer>
            <Button
              iconPosition="right"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-manage-bottom',
                });
                navigate(routes.Manage);
              }}
            >
              {returnToManageButtonLabel}
            </Button>
          </StyledButtonContainer>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default ChangeDateConfirmationPage;
