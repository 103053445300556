import React, { useState } from 'react';

import {
  Container,
  Heading,
  IconChevronLeft,
  Link,
  Dropdown,
  DateField,
  Option,
  Spinner,
  Main,
  Hr,
  TextAreaField,
  Button,
  Grid,
  GridItem,
  ButtonPattern,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { Navigate, useNavigate } from '@interstellar/react-app-routing';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import { useGetAllReminderTypesQuery } from 'client/services/api';
import { ReminderType } from 'client/services/types/reminders';
import { isBefore, differenceInDays } from 'date-fns';
import { Field, Form, Formik } from 'formik';

import { AddReminderPageContent } from './AddReminderPage.config';
import { StyledContainer } from './AddReminderPage.styled';
import { reminderValidators } from './reminderValidators';

interface FormValues {
  reminderTypeId: string;
  renewalDate: {
    day: string;
    month: string;
    year?: string;
  };
  note?: string;
}

interface AddReminderOutput {
  reminderTypeId: string;
  renewalDate: {
    day: string;
    month: string;
    year?: string;
  };
  note?: string;
  email?: string;
}

function AddReminderPage() {
  const content = useContent<AddReminderPageContent>();
  const {
    pageHeading,
    backButtonLabel,
    reminderTypeLabel,
    renewalDateLabel,
    renewalSupportiveText,
    noteSupportiveText,
    noteLabel,
    continueButtonLabel,
  } = content;

  const { data, isError, isLoading } = useGetAllReminderTypesQuery();
  const [isReminderYearly, setIsReminderTypeYearly] = useState<boolean>(true);

  const navigate = useNavigate();

  function adjustYearForYearlyReminders(month: string, day: string) {
    const currentYear = new Date().getFullYear();
    const currentDate = new Date();
    let sameYear: boolean;

    const parsedDate = new Date(
      currentYear,
      parseInt(month, 10) - 1,
      parseInt(day, 10),
    );

    if (isBefore(parsedDate, currentDate)) {
      sameYear = false;
    } else if (differenceInDays(parsedDate, currentDate) < 30) {
      sameYear = false;
    } else {
      sameYear = true;
    }
    if (!sameYear) {
      const nextYear = currentYear + 1;
      return {
        month,
        day,
        year: nextYear.toString(),
      };
    }
    return {
      month,
      day,
      year: currentYear.toString(),
    };
  }

  if (isLoading) {
    return (
      <Container data-testid="loading-container">
        <Spinner />
      </Container>
    );
  }
  if (isError) {
    return <Navigate to={routes.Error} />;
  }
  const { reminderTypes } = data;

  const validators = reminderValidators(content);
  const onSubmit = async (values: FormValues) => {
    let newRenewalDate: {
      day: string;
      month: string;
      year?: string;
    };

    if (isReminderYearly) {
      newRenewalDate = adjustYearForYearlyReminders(
        values.renewalDate.month,
        values.renewalDate.day,
      );
    } else {
      newRenewalDate = values.renewalDate;
    }
    const reminder: AddReminderOutput = {
      reminderTypeId: values.reminderTypeId,
      renewalDate: newRenewalDate,
      note: values.note,
    };
    navigate(routes.AddReminderEmailForm, {
      state: reminder,
    });
  };

  return (
    <Main>
      <StyledContainer>
        <Link
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          as="button"
          data-testid="back-button-link"
          onClick={() => {
            navigate(routes.ManageReminder);
          }}
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>
      <Hr marginTop="none" />
      <Heading as="h5" size="s5" data-testid="page-heading">
        {pageHeading}
      </Heading>

      <Grid>
        <GridItem md={8}>
          <Formik
            initialValues={{
              reminderTypeId: '',

              renewalDate: {
                day: '',
                month: '',
                year: '',
              },
              note: '',
            }}
            initialErrors={{
              renewalDate: undefined,
            }}
            onSubmit={onSubmit}
          >
            {({ values, handleBlur, touched, setFieldValue, errors }) => (
              <Form
                noValidate
                role="form"
                data-testid="add-reminder-first-page-form"
              >
                <Field
                  as={Dropdown}
                  name="reminderTypeId"
                  label={reminderTypeLabel}
                  data-testid="add-reminder-first-page-form-reminder-type"
                  validate={validators.reminderType}
                  error={touched.reminderTypeId && errors.reminderTypeId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const selectedId = parseInt(e.target.value, 10);
                    const selectedReminderType = reminderTypes.find(
                      (reminderType) =>
                        reminderType.ReminderTypeId === selectedId,
                    );
                    if (selectedReminderType.ReminderTypeId) {
                      if (selectedReminderType.ValidityPeriod === 1) {
                        setIsReminderTypeYearly(true);
                      } else {
                        setIsReminderTypeYearly(false);
                      }
                      setFieldValue('reminderTypeId', selectedId);
                    }
                  }}
                  marginBottom="05"
                >
                  <Option hidden>Please select</Option>
                  {reminderTypes.map((reminderType: ReminderType) => (
                    <Option
                      key={reminderType.ReminderTypeId}
                      value={reminderType.ReminderTypeId}
                    >
                      {reminderType.ReminderType}
                    </Option>
                  ))}
                </Field>

                {isReminderYearly ? (
                  <Field
                    as={DateField}
                    name="renewalDate"
                    label={renewalDateLabel}
                    size="s2"
                    supportiveText={renewalSupportiveText}
                    variation="dm"
                    day={values.renewalDate.day}
                    month={values.renewalDate.month}
                    validate={validators.renewalDate}
                    error={
                      (touched.renewalDate ||
                        touched['renewalDate-day'] ||
                        touched['renewalDate-month']) &&
                      errors.renewalDate
                    }
                    data-testid="add-reminder-first-page-form-renewal-date-with-out-year"
                    onChange={(value: {
                      day: string;
                      month: string;
                      year?: string;
                    }) => {
                      setFieldValue('renewalDate', value);
                    }}
                    marginBottom="05"
                  />
                ) : (
                  <Field
                    as={DateField}
                    label={renewalDateLabel}
                    supportiveText={renewalSupportiveText}
                    name="renewalDate"
                    size="s2"
                    day={values.renewalDate.day}
                    month={values.renewalDate.month}
                    year={values.renewalDate.year}
                    validate={validators.renewalDateWithYear}
                    error={
                      (touched.renewalDate ||
                        touched['renewalDate-day'] ||
                        touched['renewalDate-month'] ||
                        touched['renewalDate-year']) &&
                      errors.renewalDate
                    }
                    data-testid="add-reminder-first-page-form-renewal-date-with-year"
                    onChange={(value: {
                      day: string;
                      month: string;
                      year?: string;
                    }) => {
                      setFieldValue('renewalDate', value);
                    }}
                    marginBottom="05"
                  />
                )}
                <Field
                  as={TextAreaField}
                  name="note"
                  label={noteLabel}
                  size="s2"
                  supportiveText={noteSupportiveText}
                  validate={validators.note}
                  data-testid="add-reminder-first-page-form-note"
                  error={touched.note && errors.note}
                  onBlur={handleBlur}
                  marginBottom="05"
                />
                <ButtonPattern marginTop="07">
                  <Button type="submit" iconPosition="right">
                    {continueButtonLabel}
                  </Button>
                  <StyledNavLink
                    data-testid="secondary-back-link"
                    iconPosition="left"
                    to={routes.ManageReminder}
                  >
                    {backButtonLabel}
                  </StyledNavLink>
                </ButtonPattern>
              </Form>
            )}
          </Formik>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default AddReminderPage;
