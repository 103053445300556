import React from 'react';

import {
  Box,
  Paragraph,
  Text,
  Hr,
  Heading,
  Strong,
  IconHome,
  Spinner,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { FullWidthLink } from 'client/components/fullWidthLink';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { StyledIconContainer, StyledStampIcon } from './StampBookPanel.styled';
import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function StampBookPanel({ currentNumMortgages, maxNumMortgages, isLoading, isError }) {
  const {
    overviewTab: {
      stampBookPanel: {
        heading,
        supportiveTextPart1,
        mortgageSingular,
        mortgagePlural,
        supportiveTextPart2,
        findOutMore,
      },
    }
  } = useContent<MortgageOverviewPageContent>();

  if (isLoading) {
    return (
      <Box bgColor="neutral2" data-testid="overview-stampbook-panel">
        <Spinner />
      </Box>
    );
  }

  if (isError) {
    return null;
  }

  return (
    <Box bgColor="neutral2" data-testid="overview-stampbook-panel">
      <Heading as="h2" size="s3" marginBottom="04">
        {heading}
      </Heading>

      {/* Only show the mortgage stamps if we are below or equal to the maximum */}
      {currentNumMortgages <= maxNumMortgages && (
        <StyledIconContainer>
          {[...Array(maxNumMortgages)].map((_, i) => (
            <StyledStampIcon
              key={`stampicon-${currentNumMortgages + i}`}
              className={i < currentNumMortgages && 'active'}
            >
              {i < currentNumMortgages ? (
                <IconHome inverse iconSize="lg" data-testid="house-icon" />
              ) : (
                <Text
                  color="subdued"
                  weight="bold"
                  data-testid="available-icon"
                >
                  {i + 1}
                </Text>
              )}
            </StyledStampIcon>
          ))}
        </StyledIconContainer>
      )}

      <Paragraph marginBottom="none" data-testid="supportive-text">
        {supportiveTextPart1}
        <Strong>{currentNumMortgages}</Strong>
        {currentNumMortgages === 1 ? mortgageSingular : mortgagePlural}
        {/* Only show the "out of x mortgages" text if we are below or equal to the maximum */}
        {currentNumMortgages <= maxNumMortgages && (
          <>
            {supportiveTextPart2}
            <Strong>{maxNumMortgages}</Strong>
          </>
        )}
        .
      </Paragraph>

      {/* Only show the link if they are eligible for more borrowing. */}
      {currentNumMortgages < maxNumMortgages && (
        <>
          <Hr marginTop="03" marginBottom="03" />

          <FullWidthLink
            to='https://online.bmmortgages.co.uk/tools/buy-to-let-calculator'
            external
            data-testid="stampbook-btl-calc-link"
            onClick={() =>
              logTealiumButtonClickEvent({
                label: 'button/findouthowmuchyoucouldborrow',
              })
            }
          >
            {findOutMore}
          </FullWidthLink>
        </>
      )}
    </Box>
  );
}

export default StampBookPanel;
