import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig, AxiosError } from 'axios';

import axiosInstance from './axiosInstance';
import {
  AmendDirectDebitParameters,
  AmendDirectDebitResponse,
  CreateDDPadNoteParameters,
  CreateDDPadNoteResponse,
  DirectDebitsResponse,
} from './types/directDebits';
import { MortgageCountResponse } from './types/mortgageCount';
import {
  OrderDocumentParameters,
  OrderDocumentResponse,
} from './types/orderDocument';
import { OverviewParameters, Overview } from './types/overview';
import { Redemption, RedemptionParameters } from './types/redemption';
import { RemindersResponse, ReminderTypesResponse } from './types/reminders';
import { ServiceResponse } from './types/serviceAvailability';

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      // Use a custom axios instance so we can intercept responses.
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Auth',
    'MortgageCount',
    'DirectDebits',
    'Reminders',
    'ReminderTypes',
  ],
  endpoints: (builder) => ({
    overview: builder.mutation<Overview, OverviewParameters | void>({
      query: (args) => {
        if (args) {
          const { accountNumber, dateOfBirth, postcode } = args;
          return {
            url: '/overview',
            method: 'post',
            data: { accountNumber, dateOfBirth, postcode },
          };
        }

        return {
          url: '/overview',
          method: 'post',
        };
      },
    }),
    redemption: builder.mutation<Redemption, RedemptionParameters>({
      query: ({ redemptionDate, requestType }) => ({
        url: '/redemption',
        method: 'post',
        data: { redemptionDate, requestType },
      }),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: '/sign-out',
        method: 'post',
      }),
      invalidatesTags: ['MortgageCount', 'DirectDebits'],
    }),
    orderDocument: builder.mutation<
      OrderDocumentResponse,
      OrderDocumentParameters
    >({
      query: (orderDocumentForm) => ({
        url: '/orderDocument',
        method: 'post',
        data: orderDocumentForm,
      }),
    }),
    serviceAvailability: builder.query<ServiceResponse, void>({
      query: () => ({
        keepUnusedDataFor: 5,
        url: '/applicationAvailability',
        method: 'get',
      }),
    }),
    mortgageCount: builder.query<MortgageCountResponse, void>({
      query: () => ({
        url: '/mortgageCount',
        method: 'get',
      }),
      providesTags: ['MortgageCount'],
    }),
    directDebit: builder.query<DirectDebitsResponse, void>({
      query: () => ({
        url: '/directDebits',
        method: 'get',
      }),
      providesTags: ['DirectDebits'],
    }),
    // Two amend DD calls to invalidate the Cache only after update is confirmed
    // inquiry doesn't make any changes to the customers Direct Debit
    amendDirectDebitInquiry: builder.mutation<
      AmendDirectDebitResponse,
      AmendDirectDebitParameters
    >({
      query: (data) => ({
        url: '/directDebits/amend',
        method: 'post',
        data: {
          APIMode: 'INQ',
          ...data,
        },
      }),
    }),
    amendDirectDebitUpdate: builder.mutation<
      AmendDirectDebitResponse,
      AmendDirectDebitParameters
    >({
      query: (data) => ({
        url: '/directDebits/amend',
        method: 'post',
        data: {
          APIMode: 'UPD',
          ...data,
        },
      }),
      invalidatesTags: ['DirectDebits'],
    }),
    getAllReminders: builder.query<RemindersResponse, void>({
      query: () => ({
        url: '/reminders',
        method: 'get',
      }),
      providesTags: ['Reminders'],
    }),
    getAllReminderTypes: builder.query<ReminderTypesResponse, void>({
      query: () => ({
        url: '/reminders/reminderTypes',
        method: 'get',
      }),
      providesTags: ['ReminderTypes'],
    }),
    createDDPadNote: builder.mutation<
      CreateDDPadNoteResponse,
      CreateDDPadNoteParameters
    >({
      query: (data) => ({
        url: '/createPadNote',
        method: 'post',
        data,
      }),
    }),
  }),
});

export const {
  useOverviewMutation,
  useOrderDocumentMutation,
  useSignOutMutation,
  useRedemptionMutation,
  useServiceAvailabilityQuery,
  useMortgageCountQuery,
  useDirectDebitQuery,
  useAmendDirectDebitInquiryMutation,
  useAmendDirectDebitUpdateMutation,
  useGetAllRemindersQuery,
  useGetAllReminderTypesQuery,
  useCreateDDPadNoteMutation,
} = api;

export default api;
