import addReminder from './addReminder';
import addReminderEmail from './addReminderEmail';
import { appLayout } from './appLayout';
import borrowMore from './borrowMore';
import cancelDirectDebit from './cancelDirectDebit';
import changeAmount from './changeAmount';
import changeBankAccountPreamble from './changeBankAccountPreamble';
import changeDateConfirmation from './changeDateConfirmation';
import changeDateForm from './changeDateForm';
import changeDatePreamble from './changeDatePreamble';
import changeDateRaisedWarning from './changeDateRaisedWarning';
import changeDateWarning from './changeDateWarning';
import changeDateYouPaySummary from './changeDateYouPaySummary';
import debitChangeRequest from './debitChangeRequest';
import directDebitChangeDateError from './directDebitChangeDateError';
import directDebitChangeDateUnavailable from './directDebitChangeDateUnavailable';
import duplicateStatement from './duplicateStatement';
import error from './error';
import makePayment from './makePayment';
import makePaymentBankTransfer from './makePaymentBankTransfer';
import makePaymentPhone from './makePaymentPhone';
import manageDirectDebit from './manageDirectDebit';
import manageDirectDebitFailed from './manageDirectDebitFailed';
import manageReminder from './manageReminder';
import mortgageInterest from './mortgageInterest';
import mortgageOverview from './mortgageOverview';
import notfound from './notFound';
import onceMadePaymentAccordion from './onceMadePaymentAccordion';
import orderDocumentForm from './orderDocumentForm';
import overpayments from './overpayments';
import overpaymentsAllowance from './overpaymentsAllowance';
import overpaymentsAllowanceAccountWarning from './overpaymentsAllowanceAccountWarning';
import overpaymentsAllowanceTechnicalError from './overpaymentsAllowanceTechnicalError';
import overpaymentsRequest from './overpaymentsRequest';
import productTransfer from './productTransfer';
import redemption from './redemption';
import redemptionAlreadyRequested from './redemptionAlreadyRequested';
import redemptionError from './redemptionError';
import redemptionStatement from './redemptionStatement';
import redemptionSuccess from './redemptionSuccess';
import redemptionUnavailable from './redemptionUnavailable';
import rentalYieldCalculator from './rentalYieldCalculator';
import restartCancelledDirectDebit from './restartCancelledDirectDebit';
import serviceUnavailable from './serviceUnavailable';
import sessionManager from './sessionManager';
import signIn from './signIn';
import signOut from './signOut';
import signOutModal from './signOutModal';
import successfulRequest from './successfulRequest';
import versionMismatchDialog from './versionMismatchDialog';
import { AppContent } from '../AppContent';

const content: AppContent = {
  notfound,
  signIn,
  mortgageOverview,
  appLayout,
  signOutModal,
  signOut,
  sessionManager,
  overpayments,
  productTransfer,
  borrowMore,
  error,
  orderDocumentForm,
  overpaymentsRequest,
  overpaymentsAllowance,
  duplicateStatement,
  successfulRequest,
  redemptionStatement,
  serviceUnavailable,
  mortgageInterest,
  debitChangeRequest,
  makePayment,
  makePaymentBankTransfer,
  makePaymentPhone,
  versionMismatchDialog,
  redemption,
  redemptionAlreadyRequested,
  redemptionError,
  redemptionSuccess,
  redemptionUnavailable,
  onceMadePaymentAccordion,
  rentalYieldCalculator,
  overpaymentsAllowanceTechnicalError,
  overpaymentsAllowanceAccountWarning,
  manageDirectDebit,
  changeDateConfirmation,
  manageReminder,
  manageDirectDebitFailed,
  changeDatePreamble,
  changeDateForm,
  changeAmount,
  changeDateYouPaySummary,
  restartCancelledDirectDebit,
  cancelDirectDebit,
  directDebitChangeDateError,
  directDebitChangeDateUnavailable,
  changeDateWarning,
  changeDateRaisedWarning,
  changeBankAccountPreamble,
  addReminder,
  addReminderEmail,
};

export default content;
