import CancelDirectDebitContent from 'client/routes/manageDirectDebit/cancelDirectDebit/CancelDirectDebitPage.config';

const cancelDirectDebit: CancelDirectDebitContent = {
  backButtonLabel: 'Back',
  title: 'Cancel a direct debit',
  callUs: {
    primaryText: 'Call us on ',
    phoneNumber: '0345 300 2627',
    href: 'tel:03453002627',
    secondaryText: ` and we'll make the change over the phone.`
  },
  availability: `We're available Monday to Friday 8am to 8pm and Saturday 9am to 1pm. We're closed on Sundays and bank holidays.`,
  returnToManage: 'Return to Manage',
};

export default cancelDirectDebit;
