import React, { useEffect } from 'react';

import {
  Container,
  Grid,
  GridItem,
  Heading,
  IconAdditionalBorrowing,
  Main,
  Spinner,
} from '@constellation/core';
import {
  CreditCard,
  Financing,
  LightBulb,
  Percent,
  Positive,
  SwitchingArrows,
  Timer,
} from '@constellation/core/icons';
import { useContent } from '@interstellar/react-app-content';
import { useLocation } from '@interstellar/react-app-routing';
import { NavigationTile } from 'client/components/navigationTile';
import useMortgageOverview from 'client/hooks/useMortgageOverview';
import { useMortgageCountQuery } from 'client/services/api';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';
import { format } from 'date-fns';

import { AccountPanel } from './components/accountPanel';
import { FutureFeaturesNotification } from './components/futureFeaturesNotification';
import { LoanToValuePanel } from './components/loanToValuePanel';
import { MortgageInfoPanel } from './components/mortgageInfoPanel';
import { PaymentHelpPanel } from './components/paymentHelpPanel';
import { PropertyPanel } from './components/propertyPanel';
import { RequestsPanel } from './components/requestsPanel';
import StampBookPanel from './components/stampBookPanel/StampBookPanel';
import { WhatsNewDialog } from './components/whatsNewDialog';
import { MortgageOverviewPageContent } from './MortgageOverviewPage.config';
import { BreakoutPage } from './MortgageOverviewPage.styled';
import * as routes from '../manifest';

export default function MortgageOverviewPage() {
  const {
    overviewTab: { welcomeMessage },
    toolsTab: { navigationTiles },
    manageTab,
  } = useContent<MortgageOverviewPageContent>();

  const {
    futureFeaturesNotificationEnabled,
    enableDirectDebits,
    requestFormsEnabled,
    enableReminders,
  } = window.appConfig;

  const { isLoading, isError, overviewData } = useMortgageOverview();

  const { overview } = overviewData || {};

  const {
    firstForename,
    mortgageAccountNumber,
    eligibleToSwitch,
    eligibleToBorrowMore,
    eligibleToSwitchDate,
    eligibleToBorrowMoreDate,
  } = overview || {
    firstForename: '',
    mortgageAccountNumber: '',
    eligibleToSwitch: false,
    eligibleToBorrowMore: false,
    eligibleToSwitchDate: '',
    eligibleToBorrowMoreDate: '',
  };

  const location = useLocation();

  // Log the page view details. This should run once on initial render.
  useEffect(() => {
    const routeToTealiumStepName = {
      [routes.MortgageOverview]: 'Your mortgage overview',
      [routes.Manage]: 'Your mortgage manage page',
      [routes.Tools]: 'Your mortgage tools page',
    };

    if (!overviewData?.overview) {
      return;
    }

    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: routeToTealiumStepName[location.pathname] || '',
      applicationState: 'Pre-Application',
    });
  }, [location.pathname, overviewData]);

  const {
    refetch: refetchMortgageCount,
    data: mortgageCountData,
    isError: mortgageCountIsError,
    isLoading: mortgageCountIsLoading,
  } = useMortgageCountQuery();

  useEffect(() => {
    const awaitRefetchMortgageCount = async () => {
      await refetchMortgageCount();
    };

    if (!mortgageCountData) {
      awaitRefetchMortgageCount();
    }
  }, [mortgageCountData, refetchMortgageCount]);

  let formattedBorrowDate: string;
  let formattedSwitchDate: string;

  if (!eligibleToSwitch && eligibleToSwitchDate) {
    formattedSwitchDate = format(
      new Date(eligibleToSwitchDate),
      'dd MMMM yyyy',
    );
  }
  if (!eligibleToBorrowMore && eligibleToBorrowMoreDate) {
    formattedBorrowDate = format(
      new Date(eligibleToBorrowMoreDate),
      'dd MMMM yyyy',
    );
  }

  if (!overview || isLoading || isError) {
    return (
      <Main>
        <Spinner />
      </Main>
    );
  }

  return (
    <BreakoutPage>
      <WhatsNewDialog />
      <AccountPanel />
      <Main>
        {location.pathname === routes.MortgageOverview && (
          <Grid>
            <GridItem>
              <Heading as="h1" data-testid="overview-welcome">
                {welcomeMessage} {firstForename}
              </Heading>
            </GridItem>
            <GridItem lg={8}>
              {futureFeaturesNotificationEnabled ? (
                <FutureFeaturesNotification
                  mortgageAccountNumber={mortgageAccountNumber}
                />
              ) : null}
              <PropertyPanel />
              <LoanToValuePanel />
              <StampBookPanel
                currentNumMortgages={mortgageCountData?.mortgageCount}
                maxNumMortgages={10}
                isLoading={mortgageCountIsLoading}
                isError={mortgageCountIsError}
              />
              <MortgageInfoPanel />
            </GridItem>
            <GridItem lg={4}>
              <PaymentHelpPanel />
            </GridItem>
          </Grid>
        )}

        {location.pathname === routes.Manage && (
          <Container padding="none" data-testid="MortgageManagePage">
            <Grid>
              <GridItem lg={8}>
                <NavigationTile
                  tileInfo={{
                    ...manageTab.navigationTiles.switchYourDeal,
                    boxText:
                      !eligibleToSwitchDate || eligibleToSwitch
                        ? manageTab.switchYourDeal.switchYourDealEligibleText
                        : `${manageTab.switchYourDeal.switchYourDealNotEligibleText} ${formattedSwitchDate}.`,
                  }}
                  pageTestId="page-navigation-tiles-switch-your-deal"
                  tileIcon={
                    <SwitchingArrows iconSize="lg" size="s4" color="default1" />
                  }
                  onClick={() =>
                    logTealiumButtonClickEvent({
                      label:
                        !eligibleToSwitchDate || eligibleToSwitch
                          ? 'button/switch-your-deal-eligible'
                          : 'button/switch-your-deal-ineligible',
                    })
                  }
                  bgColor="neutral2"
                />
                <NavigationTile
                  tileInfo={{
                    ...manageTab.navigationTiles.borrowMore,
                    boxText:
                      !eligibleToBorrowMoreDate || eligibleToBorrowMore
                        ? manageTab.borrowMore.borrowMoreEligibleText
                        : `${manageTab.borrowMore.borrowMoreNotEligibleText} ${formattedBorrowDate}.`,
                  }}
                  pageTestId="page-navigation-tiles-borrow-more"
                  tileIcon={
                    <Financing iconSize="lg" size="s4" color="default1" />
                  }
                  onClick={() =>
                    logTealiumButtonClickEvent({
                      label:
                        !eligibleToBorrowMoreDate || eligibleToBorrowMore
                          ? 'button/borrow-more-eligible'
                          : 'button/borrow-more-ineligible',
                    })
                  }
                  bgColor="neutral2"
                />
                {enableDirectDebits && (
                  <NavigationTile
                    tileInfo={manageTab.navigationTiles.manageDirectDebit}
                    pageTestId="page-navigation-tiles-direct-debit"
                    tileIcon={
                      <CreditCard iconSize="lg" size="s4" color="default1" />
                    }
                    onClick={() =>
                      logTealiumButtonClickEvent({
                        label: 'button/direct-debit',
                      })
                    }
                    bgColor="neutral2"
                  />
                )}
                {requestFormsEnabled && (
                  <RequestsPanel
                    enableDirectDebitRequestForm={!enableDirectDebits}
                  />
                )}
              </GridItem>
              <GridItem lg={4}>
                <PaymentHelpPanel />
              </GridItem>
            </Grid>
          </Container>
        )}

        {location.pathname === routes.Tools && (
          <Container data-testid="MortgageToolsPage" padding="none">
            <Grid>
              <GridItem lg={8}>
                <NavigationTile
                  tileInfo={navigationTiles.OverpaymentAllowance}
                  pageTestId={navigationTiles.OverpaymentAllowance.linkTestId}
                  tileIcon={
                    <IconAdditionalBorrowing
                      iconSize="lg"
                      size="s4"
                      color="default1"
                    />
                  }
                  onClick={() =>
                    logTealiumButtonClickEvent({
                      label: 'button/overpaymentallowance',
                    })
                  }
                />
                <NavigationTile
                  tileInfo={navigationTiles.Redemption}
                  pageTestId={navigationTiles.Redemption.linkTestId}
                  tileIcon={
                    <Positive iconSize="lg" size="s4" color="default1" />
                  }
                  onClick={() =>
                    logTealiumButtonClickEvent({
                      label: 'button/redemption-find-out-the-cost',
                    })
                  }
                />
                <NavigationTile
                  tileInfo={navigationTiles.RentalYield}
                  pageTestId={navigationTiles.RentalYield.linkTestId}
                  tileIcon={
                    <Percent iconSize="lg" size="s4" color="default1" />
                  }
                  onClick={() =>
                    logTealiumButtonClickEvent({
                      label: 'button/rental-yield-calculate',
                    })
                  }
                />
                {enableReminders && (
                  <NavigationTile
                    tileInfo={navigationTiles.Reminders}
                    pageTestId={navigationTiles.Reminders.linkTestId}
                    tileIcon={
                      <Timer iconSize="lg" size="s4" color="default1" />
                    }
                    onClick={() =>
                      logTealiumButtonClickEvent({
                        label: 'button/certificate-reminders',
                      })
                    }
                  />
                )}
                <NavigationTile
                  tileInfo={navigationTiles.EnergyEfficiency}
                  pageTestId={navigationTiles.EnergyEfficiency.linkTestId}
                  tileIcon={
                    <LightBulb iconSize="lg" size="s4" color="default1" />
                  }
                  onClick={() =>
                    logTealiumButtonClickEvent({
                      label: 'button/getyouractionplan',
                    })
                  }
                />
              </GridItem>
              <GridItem lg={4}>
                <PaymentHelpPanel />
              </GridItem>
            </Grid>
          </Container>
        )}
      </Main>
    </BreakoutPage>
  );
}
