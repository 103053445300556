import React from 'react';

import { useTheme } from 'styled-components';

function IllustrationRestart() {
  const theme: any = useTheme();

  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64387 12.9417L7.2438 14.1906C-0.491518 24.1004 0.312308 39.706 10.1237 47.3653C19.5941 54.7576 31.7945 52.5796 40.6131 42.3129C40.9391 41.9334 41.4872 41.8522 41.9057 42.1042L42.0074 42.1742L44.5745 44.1777L45.2798 31.3027L32.8706 35.0423L35.3208 36.9545C35.7507 37.29 35.8337 37.8981 35.53 38.3337L35.453 38.431C28.62 46.0733 18.7022 47.7739 11.9858 42.7853L11.7116 42.5765L12.9603 40.9764C18.5214 45.3163 26.8955 44.1897 33.0845 37.9842L33.1954 37.8696L30.0765 35.4364C29.46 34.9553 29.6027 34.0037 30.2943 33.7061L30.4081 33.6647L46.08 28.9425C46.7163 28.7508 47.35 29.2108 47.3861 29.8543V29.9697L46.4955 46.2295C46.4528 47.0091 45.592 47.4366 44.9519 47.0398L44.8577 46.974L41.5108 44.3614L41.2799 44.6153C31.9569 54.706 19.2129 56.7823 9.19041 49.2077L8.87483 48.9652C-1.76747 40.6572 -2.7263 24.0253 5.39499 13.2659L5.64387 12.9417ZM51.1255 11.0371C61.7678 19.3443 62.7261 35.9762 54.6047 46.7357L54.3558 47.06L52.7559 45.8111C60.4913 35.901 59.688 20.2957 49.8767 12.637C40.4059 5.24429 28.2062 7.42242 19.3865 17.6895C19.0606 18.069 18.5124 18.1502 18.094 17.8982L17.9923 17.8282L15.4238 15.8236L14.7185 28.6977L27.1288 24.9591L24.6787 23.0471C24.249 22.7116 24.166 22.1035 24.4697 21.6679L24.5467 21.5707C31.38 13.9287 41.2987 12.228 48.014 17.2172L48.2882 17.426L47.0393 19.0259C41.5661 14.7535 33.3656 15.7784 27.2069 21.7304L26.803 22.1308L29.9233 24.5661C30.5397 25.0472 30.3969 25.9988 29.7053 26.2963L29.5916 26.3377L13.9196 31.0591C13.2834 31.2508 12.6497 30.7909 12.6136 30.1473V30.0319L13.5042 13.7728C13.5469 12.9933 14.4076 12.5658 15.0477 12.9626L15.142 13.0284L18.4876 15.64L18.7199 15.387C28.142 5.18979 41.0558 3.17682 51.1255 11.0371Z"
        fill={theme.color_icon_default_1}
      />
    </svg>
  );
}

export default IllustrationRestart;
