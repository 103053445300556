import { AppContent } from '../AppContent';

const changeDateYouPaySummary: AppContent['changeDateYouPaySummary'] = {
    pageHeading: 'Change the date you pay',
    backButtonLabel: 'Back',
    openingSection: {
        boldText: 'Review and confirm your new direct debit details',
        supportiveText: 'By changing your direct debit date:',
        bulletPoints: [
            'There could be a change to your normal monthly payment amount.',
            'Your payment amount includes any regular overpayment you make each month.',
            'If you go ahead with your direct debit date change request, your next two payments might be close together.',
        ],
    },
    newPaymentDateLabel: 'New payment date',
    ofTheMonthLabel: 'of the month',
    estimatedPaymentsLabel: 'Estimated next three payments',
    importantInfo: {
        heading: 'Important info about your payments',
        paragraph1: {
            boldText: 'Your next two payments might be close together',
            supportiveText: `This could mean you'll make two payments from one month's income.`,
        },
        paragraph2: {
            boldText: 'Your next three payments are estimated',
            supportiveText: 'The amount of interest can vary slightly month to month.',
        },
        paragraph3: {
            boldText: 'If your mortgage is on a variable rate',
            supportiveText: 'Your payments will change if the Bank of England (BoE) base rate changes.',
        },
        paragraph4: {
            boldText: `If you're making overpayments`,
            supportiveText: 'They could be affected by your payment date change.',
        },
        paragraph5: {
            boldText: `We'll confirm your new monthly payment amount once you accept your payment date change`,
            supportiveText: `We'll also send you a confirmation letter.`,
        },
    },
    closingSection: {
        boldText: 'If you think you might not be able to afford your next three payments',
        supportiveText1: 'Please call us on ',
        phoneNumber: '0354 850 3705',
        supportiveText2: `, so we can help. Lines are open Monday to Friday 8am to 8pm and Saturday 9am to 4pm. We're closed Sundays and bank holidays.`,
    },
    editPaymentDate: 'Edit payment date',
    confirmButton: 'Confirm',
    backToOverview: 'Back',
};

export default changeDateYouPaySummary;
