import { Location } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';

/**
 * DEPRECATED - journeyPathMap
 * Please do not add to this path map as it will be deprecated when getJourneyStep is removed, see deprecation warning on getJourneyStep.
 */
const journeyPathMap = {
  [routes.SignIn]: '1',
  [routes.MortgageOverview]: '2',
  [routes.SignedOut]: 'undefined',
  [routes.Overpayments]: '3',
  [routes.ProductTransfer]: '3',
  [routes.BorrowMore]: '3',
  [routes.Error]: 'undefined',
  [routes.RequestChangeDirectDebit]: '4',
  [routes.RequestDuplicateStatement]: '4',
  [routes.RequestCertificateOfInterest]: '4',
  [routes.RequestRedemptionStatement]: '4',
  [routes.RequestOverpaymentsAllowance]: '4',
};

/**
 * @deprecated This function should no longer be used. Please replace with an inline step number, if you wish to use an inherited or relative step number use undefined or an offset with "+X" or "-X".
 *
 * @param currentLocation A react-router-dom location object, usually returned from useLocation.
 * @returns A step number from the journeyPathMap
 */
export const getJourneyStep = (currentLocation: Location<any>) => {
  const path = currentLocation.pathname;
  return journeyPathMap[path];
};
