import { AppContent } from '../AppContent';

const addReminderEmail: AppContent['addReminderEmail'] = {
  title: 'Add a reminder',
  backButtonLabel: 'Back',
  emailFieldLabel: 'Which email address should we send the reminder to?',
  emailLabelInfo: `We'll use this address for all reminders you add. We won't use this email for any other purpose.`,
  confirmEmailLabel: 'Confirm your email address',
  continueButtonLabel: 'Continue',
  previousButtonLabel: 'Back',
  validationErrors: {
    emailMismatch: "Your email addresses don't match. Try again",
    email: {
      wrongType:
        "Check the email address where we'll send your reminder and try again",
      onlySpaces:
        "Check the email address where we'll send your reminder and try again",
      empty: "Enter the email address where we'll send your reminder",
      tooLong:
        "An email address can't be longer than 320 characters. Try again",
      badPattern:
        "Check the email address where we'll send your reminder and try again",
    },
    confirmEmail: {
      wrongType:
        "Check the email address where we'll send your reminder and try again",
      onlySpaces:
        "Check the email address where we'll send your reminder and try again",
      empty: "Enter the email address where we'll send your reminder",
      tooLong:
        "An email address can't be longer than 320 characters. Try again",
      badPattern:
        "Check the email address where we'll send your reminder and try again",
    },
  },
};

export default addReminderEmail;
