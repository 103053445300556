import React, { useEffect } from 'react';

import {
  ContentGroup,
  Heading,
  Hr,
  IconChevronLeft,
  Link,
  List,
  ListItem,
  Main,
  Paragraph,
  Spinner,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import useMortgageOverview from 'client/hooks/useMortgageOverview';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';
import { format } from 'date-fns';

import { ProductTransferContent } from './ProductTransferPage.config';
import { MortgageOverview } from '../manifest';

function ProductTransferPage() {
  const {
    backButtonLabel,
    brokerLink,
    brokerTitle,
    contactUsTitle,
    contactUsParagraph1,
    contactUsNumber2,
    contactUsAnythingElse,
    contactUsAnythingElseNumber,
    contactUsNumber1,
    contactUsParagraph2,
    wereHereTitle,
    contactMondayToFriday,

    contactSaturday1,
    contactSaturday2,
    contactSaturday3,
    linesOpeningTime,
    eligibleText,
    eligibleTitle,
    notEligibleText,
    notEligibleTitle,
    para1,
  } = useContent<ProductTransferContent>();
  const navigate = useNavigate();

  const { isLoading, isError, overviewData } = useMortgageOverview();

  const { overview } = overviewData || {};
  const { eligibleToSwitch, eligibleToSwitchDate } = overview || {
    eligibleToSwitch: false,
    eligibleToSwitchDate: '',
  };

  useEffect(() => {
    if (!overviewData?.overview) {
      return;
    }

    const ptEligible =
      eligibleToSwitch || !eligibleToSwitchDate ? 'eligible' : 'ineligible';

    logTealiumPageViewEvent({
      stepNumber: '3',
      stepName: `PT (Refer to broker) - ${ptEligible}`,
      applicationState: 'Referred',
    });
  }, [overviewData, eligibleToSwitch, eligibleToSwitchDate]);

  let formattedSwitchDate: string;
  if (!eligibleToSwitch && eligibleToSwitchDate) {
    formattedSwitchDate = format(
      new Date(eligibleToSwitchDate),
      'dd MMMM yyyy',
    );
  }

  if (!overview || isLoading || isError) {
    return (
      <Main>
        <Spinner />
      </Main>
    );
  }

  return (
    <Main>
      <Link
        as="button"
        icon={<IconChevronLeft trim />}
        iconPosition="left"
        onClick={() => {
          logTealiumButtonClickEvent({ label: 'button/back' });
          navigate(MortgageOverview);
        }}
        data-testid="refer-back-button"
      >
        {backButtonLabel}
      </Link>

      <Hr />

      <ContentGroup marginBottom="none">
        {eligibleToSwitch || !eligibleToSwitchDate ? (
          <>
            <Heading as="h1" size="s5">
              {eligibleTitle}
            </Heading>

            <Paragraph>{eligibleText}</Paragraph>
          </>
        ) : (
          <>
            <Heading as="h1" size="s5">
              {notEligibleTitle}
            </Heading>

            <Paragraph>
              {notEligibleText} {formattedSwitchDate}.
            </Paragraph>
          </>
        )}

        <Paragraph>{para1}</Paragraph>
        <Heading as="h2" marginBottom="04" size="s3">
          {brokerTitle}
        </Heading>
        <Paragraph>
          <Link
            external
            href={brokerLink.url}
            data-testid="refer-find-broker-link"
            onClick={() => {
              logTealiumButtonClickEvent({ label: 'button/searchforonehere' });
            }}
          >
            {brokerLink.text}
          </Link>
        </Paragraph>

        <Hr />

        <Heading as="h2" marginBottom="06" size="s3">
          {contactUsTitle}
        </Heading>
        <List marginBottom="06">
          <ListItem>
            {contactUsParagraph1}{' '}
            <Link href={`tel:${contactUsNumber1.phoneNumber}`}>
              {contactUsNumber1.phoneNumber}
            </Link>
          </ListItem>
          <ListItem>
            {contactUsParagraph2}{' '}
            <Link href={`tel:${contactUsNumber2.phoneNumber}`}>
              {contactUsNumber2.phoneNumber}
            </Link>
            .
          </ListItem>
        </List>

        <Paragraph>
          {contactUsAnythingElse}{' '}
          <Link href={`tel:${contactUsAnythingElseNumber.phoneNumber}`}>
            {contactUsAnythingElseNumber.phoneNumber}
          </Link>
          .
        </Paragraph>

        <Heading as="h2" size="s3">
          {wereHereTitle}
        </Heading>

        <Paragraph marginBottom="none">{contactMondayToFriday}</Paragraph>

        <List marginTop="04" marginBottom="04">
          <ListItem>
            {contactSaturday1.time} <Strong>{contactSaturday1.number}</Strong>
          </ListItem>
          <ListItem>
            {contactSaturday2.time} <Strong>{contactSaturday2.number}</Strong>
          </ListItem>
          <ListItem>
            {contactSaturday3.time} <Strong>{contactSaturday3.number}</Strong>.
          </ListItem>
        </List>

        <Paragraph marginBottom="none">{linesOpeningTime}</Paragraph>
      </ContentGroup>
    </Main>
  );
}

export default ProductTransferPage;
