import { Box, Link } from '@constellation/core';
import BoxProps from '@constellation/core/dist/types/components/Box/box.types';
import styled, { StyledComponent } from 'styled-components';

const getPanelBackgroundColor = (
  theme: { [key: string]: string },
  bgColor: BoxProps['bgColor'],
): string => {
  switch (bgColor) {
    case 'neutral1':
      return theme.color_background_panel_neutral_1;
    case 'neutral2':
      return theme.color_background_panel_neutral_2;
    case 'brand1':
      return theme.color_background_panel_brand_1;
    case 'brand2':
      return theme.color_background_panel_brand_2;
    case 'information':
      return theme.color_background_panel_information;
    case 'warning':
      return theme.color_background_panel_warning;
    case 'critical':
      return theme.color_background_panel_critical;
    case 'success':
      return theme.color_background_panel_success;
    default:
      return theme.color_background_panel_neutral_1;
  }
};

interface FullWidthBoxProps extends BoxProps {
  $showBorder?: boolean;
}

export const FullWidthBox = styled(Box)<FullWidthBoxProps>`
  &&& {
    display: flex;
    background-color: ${({ theme, bgColor }) =>
      getPanelBackgroundColor(theme, bgColor)};
    border-width: 2px;
    border-style: solid;
    border-color: ${({ theme, $showBorder }) =>
      $showBorder ? theme.color_border_brand_1 : 'transparent'};
    border-radius: 8px;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
  }

  &&&:hover {
    svg,
    strong {
      transition-duration: 0.2s;
      transition-property: color;
      transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
      color: #660516;
    }
  }
`;

export const StyledNavigationLink: StyledComponent<typeof Link, any> = styled(
  Link,
)`
  text-decoration: none;
`;

export const IconWithTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing_03};
`;
