import { EmailSchema } from 'client/validation/EmailSchema';

import ReminderEmailPageContent from '../addReminderEmail/AddReminderEmailPage.config';

export function reminderEmailFormValidators(content: ReminderEmailPageContent) {
  const { validationErrors } = content;

  const email = EmailSchema(validationErrors.email, { required: true });
  const confirmEmail = EmailSchema(validationErrors.confirmEmail, {
    required: true,
  });

  return { email, confirmEmail };
}
