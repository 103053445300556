export interface PhoneNumberSchemaErrorMessages {
  wrongType: string;
  empty: string;
  onlySpaces: string;
  badPattern: string;
  tooLong: string;
}

export function PhoneNumberSchema(
  errorMessages: PhoneNumberSchemaErrorMessages,
) {
  return (value: any) => {
    const ONLY_SPACES_REGEX = /^\s+$/;
    const MAX_LENGTH = 64;
    const VALID_TYPE = 'string';

    // allow only digits, spaces, hyphens, parentheses, dots, plus sign, "ext" or "EXT"
    const VALID_PHONE_NUMBER_REGEX = /^(?:[\d\s\-().+]|ext|EXT)*$/;

    if (typeof value !== VALID_TYPE) {
      return errorMessages.wrongType;
    }

    if (!value) {
      return errorMessages.empty;
    }

    if (value.length > MAX_LENGTH) {
      return errorMessages.tooLong;
    }

    if (ONLY_SPACES_REGEX.test(value)) {
      return errorMessages.onlySpaces;
    }

    if (!VALID_PHONE_NUMBER_REGEX.test(value)) {
      return errorMessages.badPattern;
    }

    return undefined;
  };
}
