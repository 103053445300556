import { AppContent } from '../AppContent';

const overpaymentsRequest: AppContent['overpaymentsRequest'] = {
  pageTitle: 'Request confirmation of overpayments allowance',
  backButtonLabel: 'Back',
  requestInformation: {
    openingParagraph:
      "If you would like confirmation of your remaining overpayment allowance for this year, complete this form. You'll receive a letter confirming any remaining allowance within 5 working days.",
    listHeading: 'What you need to know',
    sectionListItems: [
      'We can only email you if the email you provide matches the one we already hold on our system.',
      'We can only deliver to the correspondence address we currently hold on our system.',
      'Your allowance runs against the calendar year - 1st January to 31st December.',
    ],
    nextActionsInfo: 'Complete the following information:',
  },
};

export default overpaymentsRequest;
