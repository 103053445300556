import React from 'react';

import { Box, Heading, Hr } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { FullWidthLink } from 'client/components/fullWidthLink';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

interface RequestPanelProps {
  enableDirectDebitRequestForm?: boolean;
}

function RequestsPanel({
  enableDirectDebitRequestForm = true,
}: RequestPanelProps) {
  const { requestsPanel } = useContent<MortgageOverviewPageContent>();

  return (
    <>
      <Heading size="s2" as="h2" marginBottom="03">
        {requestsPanel.heading}
      </Heading>
      <Box bgColor="neutral2" data-testid="overview-requests-panel">
        <FullWidthLink
          to={requestsPanel.certificateOfInterestLink.url}
          data-testid="overview-certificate-of-interest-link"
          onClick={() =>
            logTealiumButtonClickEvent({
              label: 'button/certificate-of-mortgage-Interest',
            })
          }
        >
          {requestsPanel.certificateOfInterestLink.text}
        </FullWidthLink>

        {enableDirectDebitRequestForm && (
          <>
            <Hr marginTop="04" marginBottom="04" />
            <FullWidthLink
              to={requestsPanel.changeOfDirectDebitLink.url}
              data-testid="overview-change-direct-debit-request-link"
              onClick={() =>
                logTealiumButtonClickEvent({
                  label: 'button/change-of-direct-debit-date',
                })
              }
            >
              {requestsPanel.changeOfDirectDebitLink.text}
            </FullWidthLink>
          </>
        )}

        <Hr marginTop="04" marginBottom="04" />
        <FullWidthLink
          to={requestsPanel.duplicateStatementLink.url}
          data-testid="overview-duplicate-statement-request-link"
          onClick={() =>
            logTealiumButtonClickEvent({
              label: 'button/duplicate-statement',
            })
          }
        >
          {requestsPanel.duplicateStatementLink.text}
        </FullWidthLink>
      </Box>
    </>
  );
}

export default RequestsPanel;
