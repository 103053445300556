import { Link } from '@interstellar/react-app-routing';
import styled from 'styled-components';

export const StyledTabLink = styled(Link)<{ selected: boolean }>`
  text-align: center;
  text-decoration: none;
  display: flex;
  flex: 1;
  padding: ${({ theme }) => theme.spacing_03};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing_01};
  margin-right: ${({ theme }) => theme.spacing_01};
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.color_action_background_primary_default
      : theme.color_action_foreground_primary_default};
  border-color: ${({ theme }) => theme.color_border_brand_1};
  border-style: solid;
  border-width: ${({ theme }) => theme.button_border_focus_width};
  transition-duration: ${({ theme }) => theme.transition_duration_default};
  transition-property: color, background-color, border-color;
  transition-timing-function: ${({ theme }) =>
    theme.transition_easing_standard_css};

  &&&&&:hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.color_background_panel_brand_2 : 'white'};
    border-color: ${({ selected, theme }) =>
      selected ? 'transparent' : theme.color_background_panel_brand_2};

    span {
      color: ${({ selected }) => (selected ? 'white' : '#660516')};
    }
  }
`;

export const StyledTabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
