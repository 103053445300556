import {
  PersonNameSchema,
  AddressLineSchema,
  PostcodeSchema,
  PhoneNumberSchema,
  Iso8601DateSchema,
} from 'client/validation';
import { EmailSchema } from 'client/validation/EmailSchema';

import { OrderDocumentFormContent } from './OrderDocumentForm.config';

export function orderDocumentFormValidators(content: OrderDocumentFormContent) {
  const { validationErrors } = content;

  const title = PersonNameSchema(validationErrors.title, { maxLength: 15 });
  const firstName = PersonNameSchema(validationErrors.firstName);
  const lastName = PersonNameSchema(validationErrors.lastName);
  const propertyAddress = AddressLineSchema(validationErrors.propertyAddress);
  const postcode = PostcodeSchema(validationErrors.postcode);
  const telephoneNumber = PhoneNumberSchema(validationErrors.telephoneNumber);
  const email = EmailSchema(validationErrors.email);

  const sendAnnually = (value: any) =>
    !value ? validationErrors.sendAnnually.pleaseSelectAnOption : undefined;

  const statementType = (value: any) =>
    !value ? validationErrors.statementType.pleaseSelectAnOption : undefined;

  const redemptionDate = (value: any) => {
    const redemptionDay = value?.day?.toString()?.padStart(2, '0') ?? '';
    const redemptionMonth = value?.month?.toString()?.padStart(2, '0') ?? '';
    const redemptionYear = value?.year?.toString() ?? '';

    const redemptionDateIso = `${redemptionYear}-${redemptionMonth}-${redemptionDay}`;

    const validator = Iso8601DateSchema(validationErrors.redemptionDate, {
      mustBe: 'inFuture',
    });

    return validator(redemptionDateIso);
  };

  const newPaymentDate = (value: any) => {
    if (!value) {
      return validationErrors.newPaymentDate.pleaseConfirm;
    }
    const date = parseInt(value, 10);

    if (date < 1 || date > 28) {
      return validationErrors.newPaymentDate.pleaseEnterValidValue;
    }
    return undefined;
  };

  const confirmationCheckbox = (value: any) =>
    !value ? validationErrors.confirmationCheckbox.pleaseConfirm : undefined;

  return {
    title,
    firstName,
    lastName,
    email,
    propertyAddress,
    postcode,
    telephoneNumber,
    sendAnnually,
    statementType,
    redemptionDate,
    newPaymentDate,
    confirmationCheckbox,
  };
}
