import React, { useEffect } from 'react';

import {
  Main,
  IconChevronLeft,
  Hr,
  Grid,
  GridItem,
  Heading,
  Button,
  ButtonPattern,
  Container,
  Notification,
  Paragraph,
  Text,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import ChangeDateWarningContent from './ChangeDateWarningPage.config';
import { DDIndicatorStatus } from '../utils/ddErrorWarningHandler';

function ChangeDateWarningPage() {
  const navigate = useNavigate();

  const {
    backButtonLabel,
    title,
    accountInArrears,
    ddCancelled,
    ddDeleted,
    ddUnpaid,
    multipleDds,
    mixedPaymentTypes,
    generalMessage,
    customerNotNamedOnBankAcc,
    returnToManage,
  } = useContent<ChangeDateWarningContent>();

  interface WarningContent {
    warningName: string;
    heading: string;
    sentiment: 'warning' | 'critical' | 'information' | 'success';
    content: React.ReactElement;
  }

  const getWarningNotification = (
    code: DDIndicatorStatus,
  ): WarningContent => {
    switch (code) {
      case 'CMUA':
        return {
          warningName: 'Account in arrears',
          heading: accountInArrears.heading,
          sentiment: 'critical',
          content: (
            <Paragraph>
              <Text>{accountInArrears.para1}</Text>
              <Link href={accountInArrears.telHref}>
                {accountInArrears.telNo}
              </Link>
              <Text>{accountInArrears.para2}</Text>
            </Paragraph>
          ),
        };
      case 'CDCA':
        return {
          warningName: 'Cancelled',
          heading: ddCancelled.heading,
          sentiment: 'critical',
          content: (
            <Paragraph>
              <Text>{ddCancelled.para1}</Text>
              <Link href={ddCancelled.telHref}>{ddCancelled.telNo}</Link>
              <Text>{ddCancelled.para2}</Text>
            </Paragraph>
          ),
        };
      case 'CDDE':
        return {
          warningName: 'Deleted',
          heading: ddDeleted.heading,
          sentiment: 'critical',
          content: (
            <Paragraph>
              <Text>{ddDeleted.para1}</Text>
              <Link href={ddDeleted.telHref}>{ddDeleted.telNo}</Link>
              <Text>{ddDeleted.para2}</Text>
            </Paragraph>
          ),
        };
      case 'CDUP':
        return {
          warningName: 'Unpaid',
          heading: ddUnpaid.heading,
          sentiment: 'critical',
          content: (
            <>
              <Paragraph>{ddUnpaid.para1}</Paragraph>
              <Paragraph>
                <Text>{ddUnpaid.para2}</Text>
                <Link href={ddUnpaid.telHref}>{ddUnpaid.telNo}</Link>
                {ddUnpaid.fullStop}
              </Paragraph>
              <Paragraph>{ddUnpaid.para3}</Paragraph>
            </>
          ),
        };
      case 'WSDD':
        return {
          warningName: 'Multiple direct debits',
          heading: multipleDds.heading,
          sentiment: 'critical',
          content: (
            <Paragraph>
              <Text>{multipleDds.para1}</Text>
              <Link href={multipleDds.telHref}>{multipleDds.telNo}</Link>
              <Text>{multipleDds.para2}</Text>
            </Paragraph>
          ),
        };
      case 'WMIX':
        return {
          warningName: 'Mixed payment types',
          heading: mixedPaymentTypes.heading,
          sentiment: 'critical',
          content: (
            <Paragraph>
              <Text>{mixedPaymentTypes.para1}</Text>
              <Link href={mixedPaymentTypes.telHref}>
                {mixedPaymentTypes.telNo}
              </Link>
              <Text>{mixedPaymentTypes.para2}</Text>
            </Paragraph>
          ),
        };
      case 'E0015':
        return {
          warningName: 'Customer not named on bank account',
          heading: customerNotNamedOnBankAcc.heading,
          sentiment: 'critical',
          content: (
            <>
              <Paragraph>{customerNotNamedOnBankAcc.para1}</Paragraph>
              <Paragraph>
                <Text>{customerNotNamedOnBankAcc.para2}</Text>
                <Link href={customerNotNamedOnBankAcc.telHref}>
                  {customerNotNamedOnBankAcc.telNo}
                </Link>
                <Text>{customerNotNamedOnBankAcc.para3}</Text>
              </Paragraph>
            </>
          ),
        };
      default:
        return {
          warningName: 'General warning',
          heading: generalMessage.heading,
          sentiment: 'critical',
          content: (
            <Paragraph>
              <Text>{generalMessage.para1}</Text>
              <Link href={generalMessage.telHref}>{generalMessage.telNo}</Link>
              <Text>{generalMessage.para2}</Text>
            </Paragraph>
          ),
        };
    }
  };

  const location = useLocation();

  const warningContent: WarningContent = getWarningNotification(
    location?.state?.errorType,
  );

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: undefined,
      stepName: `Direct debits - Change date warning - ${warningContent.warningName}`,
      applicationState: 'Declined',
    });

    if (!location.state) navigate(routes.ManageDirectDebitChangeDateError);
  }, [location.state, navigate, warningContent]);

  return (
    <Main>
      <Container padding="none" marginBottom="05">
        <StyledNavLink
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.Manage}
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
          }}
        >
          {backButtonLabel}
        </StyledNavLink>
      </Container>
      <Hr marginTop="none" marginBottom="none" />
      <Grid>
        <GridItem md={8}>
          <Heading as="h1" size="s5" marginTop="04" role="heading">
            {title}
          </Heading>
          {warningContent && (
            <Notification
              heading={warningContent.heading}
              headingLevel={2}
              sentiment={warningContent.sentiment}
            >
              {warningContent.content}
            </Notification>
          )}
          <ButtonPattern>
            <Button
              data-testid="secondary-back-link"
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-manage',
                });
                navigate(routes.Manage);
              }}
            >
              {returnToManage}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default ChangeDateWarningPage;
