export interface PostcodeSchemaErrorMessages {
  wrongType: string;
  empty: string;
  badPattern: string;
}

// validate a UK postcode
export function PostcodeSchema(errorMessages: PostcodeSchemaErrorMessages) {
  return (value: any) => {
    const VALID_TYPE = 'string';
    const VALID_POSTCODE_REGEX = /^[A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}$/;

    if (typeof value !== VALID_TYPE) {
      return errorMessages.wrongType;
    }

    if (!value) {
      return errorMessages.empty;
    }

    if (!VALID_POSTCODE_REGEX.test(value)) {
      return errorMessages.badPattern;
    }

    return undefined;
  };
}
