import React, { ReactElement } from 'react';

import { Main, Grid, GridItem, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { NotFoundContent } from './NotFoundPage.config';
import {
  StyledParagraph,
  StyledLink,
  CenteredText,
} from './NotFoundPage.styled';

function NotFoundPage(): ReactElement {
  const { heading, subHeading, footer, footerLink, subFooter } =
    useContent<NotFoundContent>();

  return (
    <Main>
      <Grid>
        <GridItem xs={12}>
          <CenteredText>
            <Heading as="h4">{heading}</Heading>
          </CenteredText>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem xs={12}>
          <StyledParagraph>{subHeading}</StyledParagraph>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem xs={12}>
          <StyledParagraph>
            {footer} <StyledLink href="/">{footerLink}</StyledLink>
            {subFooter}
          </StyledParagraph>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default NotFoundPage;
