import { AppContent } from '../AppContent';

const orderDocumentForm: AppContent['orderDocumentForm'] = {
  titleFieldLabel: 'Title',
  firstNameFieldLabel: 'First name',
  lastNameFieldLabel: 'Last name',
  emailFieldLabel: 'Email address',
  propertyAddressFieldLabel: 'Property address',
  propertyAddressFieldSupportiveText:
    'This is the address of the property you want documents for. (First line of address)',
  postcodeFieldLabel: 'Postcode',
  telephoneNumberFieldLabel: 'Telephone number',
  statementTypeFieldLabel: 'Which statement type do you want?',
  statementTypeAnnualLabel: 'Annual',
  statementTypeInterimLabel: 'Interim',
  sendAnnuallyFieldLabel:
    'Would you like this to be sent annually after each UK Tax year?',
  newPaymentDateFieldLabel:
    'What would you like your new mortgage payment date to be?',
  redemptionDateFieldLabel: 'Date of redemption',
  confirmationCheckboxField: {
    certificateOfInterest:
      'I confirm that I am named on this mortgage account and would like you to send a certificate of interest to the correspondence address for the mortgaged property.',
    changeDirectDebit:
      'I confirm that I am named on this mortgage account and give you permission to change the direct debit date as instructed in this form.',
    duplicateStatement:
      'I can confirm that I am named on this account and I am requesting you to send this information to my property address.',
    overpaymentAllowance:
      'I confirm that I am named on this account and would like you to send this information to the correspondence address for the mortgaged property.',
    redemptionStatement:
      'I can confirm that I am named on this account and I am requesting you to send this information to my correspondence address.',
  },
  submitButtonLabel: 'Submit',

  validationErrors: {
    summaryTitle: "Something's not quite right",
    title: {
      wrongType: 'Please enter a valid title',
      onlySpaces: 'Please enter a valid title',
      empty: 'Please confirm your title',
      tooLong: "Title can't be longer than 15 characters",
      badPattern: 'Title may not contain special characters or numbers',
    },
    firstName: {
      wrongType: 'Please enter a valid first name',
      onlySpaces: 'Please enter a valid first name',
      empty: 'Please confirm your first name',
      tooLong: "First name can't be longer than 64 characters",
      badPattern: 'First name may not contain special characters or numbers',
    },
    lastName: {
      wrongType: 'Please enter a valid last name',
      onlySpaces: 'Please enter a valid last name',
      empty: 'Please confirm your last name',
      tooLong: "Last name can't be longer than 64 characters",
      badPattern: 'Last name may not contain special characters or numbers',
    },
    email: {
      wrongType: 'Please enter a valid email address',
      onlySpaces: 'Please enter a valid email address',
      empty: 'Please confirm your email address',
      tooLong: "Email address can't be longer than 320 characters",
      badPattern: 'Please enter a valid email address',
    },
    propertyAddress: {
      wrongType: 'Please enter a valid UK postcode',
      onlySpaces: 'Please enter a valid property address',
      empty: "Please confirm the first line of the property's address",
      tooLong: "Property address can't be longer than 255 characters",
      badPattern: 'Property address may not contain: < > $ ; \\ " * % ! ? + =',
    },
    postcode: {
      wrongType: 'Please enter a valid UK postcode',
      badPattern: 'Please enter a valid UK postcode',
      empty: 'Please confirm the postcode',
    },
    telephoneNumber: {
      wrongType: 'Please enter a valid telephone number',
      onlySpaces: 'Please enter a valid telephone number',
      badPattern: 'Please enter a valid telephone number',
      empty: 'Please confirm your telephone number',
      tooLong: "Telephone number can't be longer than 64 characters",
    },
    sendAnnually: {
      pleaseSelectAnOption: 'Please confirm annual statement preference',
    },
    statementType: {
      pleaseSelectAnOption:
        "Please confirm which statement you'd like to be sent",
    },
    redemptionDate: {
      wrongType: 'Please enter a valid redemption date',
      badPattern: 'Please enter a valid redemption date',
      invalidDate: 'Please enter a valid redemption date',
      empty: 'Please confirm the redemption date',
      dateOutOfRange: 'The redemption date must be in the future',
    },
    newPaymentDate: {
      pleaseConfirm: 'Please confirm the new payment date',
      pleaseEnterValidValue:
        'Please confirm a new monthly payment date between the 1st and 28th',
    },
    confirmationCheckbox: {
      pleaseConfirm: 'Please confirm',
    },
  },
};

export default orderDocumentForm;
