import React, { useEffect } from 'react';

import {
  Box,
  Grid,
  GridItem,
  Heading,
  Hr,
  Link,
  Main,
  Paragraph,
  Notification,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import ChangeDateUnavailableContent, {
  AvailabilityMessage,
} from './ChangeDateUnavailablePage.config';

export const changeDateAvailabilityMessage = (
  applicationHours: AvailabilityMessage,
): React.ReactElement => (
  <GridItem md={4}>
    <Box>
      <Heading size="s4">{applicationHours.title}</Heading>
      <Paragraph>{applicationHours.mainText}</Paragraph>
      <Paragraph>{applicationHours.monToSatHours}</Paragraph>
      <Paragraph marginBottom="none">{applicationHours.sunHours}</Paragraph>
    </Box>
  </GridItem>
);

function ChangeDateUnavailablePage() {
  const navigate = useNavigate();
  const { title, notification, applicationHours, backLinkLabel } =
    useContent<ChangeDateUnavailableContent>();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: undefined,
      stepName: 'Direct debits - Change date - Unavailable',
      applicationState: 'Declined',
      actionNarrative: "We can't change your direct debit date right now",
    });
  }, []);

  return (
    <Main>
      <Heading as="h1" size="s7">
        {title}
      </Heading>

      <Grid>
        <GridItem md={8}>
          <Hr marginTop="none" />

          <Notification
            heading={notification.title}
            headingLevel={2}
            sentiment="critical"
          >
            <Paragraph marginBottom="none">{notification.para}</Paragraph>
          </Notification>
        </GridItem>

        {changeDateAvailabilityMessage(applicationHours)}
      </Grid>

      <Link
        as="button"
        iconPosition="left"
        data-testid="navigate-back-button"
        onClick={() => {
          navigate(-1);
          logTealiumButtonClickEvent({ label: 'button/back' });
        }}
      >
        {backLinkLabel}
      </Link>
    </Main>
  );
}

export default ChangeDateUnavailablePage;
