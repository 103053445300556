import { AppContent } from '../AppContent';

const changeDatePreamble: AppContent['changeDatePreamble'] = {
  heading: 'Change the date you pay',
  intro: 'Before you start, here are a few things you should know...',
  listItems: [
    'Choose a date between 1st and 28th of the month for your payment.',
    'Your payment date must be the same every month.',
    'Interest is calculated daily for most accounts, so the best day to pay is 1st of the month.',
  ],
  backButtonLabel: 'Back',
  continueButtonLabel: 'Continue',
  secondaryBackLabel: 'Back',
};

export default changeDatePreamble;
