import { NavigateFunction } from "@interstellar/react-app-routing";
import * as routes from 'client/routes/manifest';
import { AmendDirectDebitResponse, DirectDebitsResponse, ServiceError, ServiceWarning } from "client/services/types/directDebits";

// DD Indicators which we hold a specific scenario for
export type DDIndicatorStatus = 'CDCA' | 'CDDE' | 'CDRA' | 'CDUP' | 'CMUA' | 'E0015' | 'WAMD' | 'WCMT' | 'WCOM' | 'WMIX' | 'WRFC' | 'WSDD' | 'W0001' | 'E0028';

interface DispatchDDIndicatorProps {
    code: DDIndicatorStatus;
    isError: boolean;
    navigate?: NavigateFunction;
}

function dispatchDDIndicator({
    code,
    isError,
    navigate,
}: DispatchDDIndicatorProps): DDIndicatorStatus {
    const isInBatchMode = ['E0028', 'W0001'].includes(code as string)

    if (isInBatchMode) {
        if (navigate) navigate(routes.ManageDirectDebitChangeDateUnavailable);
        return code;
    }

    if (isError) {
        if (navigate) navigate(routes.ManageDirectDebitChangeDateError);
        return code;
    }

    if (code === 'CDRA') {
        if (navigate) navigate(routes.ChangeDateRaisedWarning);
        return code;
    }

    if (navigate) navigate(routes.ChangeDateWarning, { state: { errorType: code } });
    return code;
}

interface CheckDDWarningsParams {
    respData: AmendDirectDebitResponse | DirectDebitsResponse;
    navigate?: NavigateFunction;
}

const checkWarnings = ({ respData, navigate, }: CheckDDWarningsParams) => {
    let end: string;
    for (let i = 0; i < respData.warnings.length; i += 1) {
        const warning: ServiceWarning = respData.warnings[i];
        const reasonCode: DDIndicatorStatus = warning.warningCode as DDIndicatorStatus;

        end = dispatchDDIndicator({
            code: reasonCode,
            isError: false,
            navigate,
        });
        if (end) break;
    }

    return end
}

interface CheckErrorsParams {
    respData: AmendDirectDebitResponse | DirectDebitsResponse;
    navigate?: NavigateFunction;
}

const checkErrors = ({ respData, navigate, }: CheckErrorsParams) => {
    let end: string;
    for (let i = 0; i < respData.errors.length; i += 1) {
        const err: ServiceError = respData.errors[i];
        const reasonCode: DDIndicatorStatus = err.reasonCode as DDIndicatorStatus;
        const isError = (err.reasonCode !== 'E0015') // E0015 correlates to scenario W9

        end = dispatchDDIndicator({
            code: reasonCode,
            isError,
            navigate,
        });
        if (end) break;
    }
    return end;
}

interface CheckDDErrorsParams {
    respData: AmendDirectDebitResponse | DirectDebitsResponse;
    navigate?: NavigateFunction;
}

export const checkDDErrors = ({ respData, navigate, }: CheckDDErrorsParams): string | boolean => {

    if (respData?.errors && respData.errors.length > 0) {
        return checkErrors({
            respData,
            navigate,
        })
    }

    // Some warnings have defined copy, others are generic, but this is handled on the warning page
    if (respData?.DDSelfServInd && respData?.DDSelfServInd !== '') {
        return dispatchDDIndicator({
            code: respData.DDSelfServInd as DDIndicatorStatus,
            isError: false,
            navigate,
        });
    }

    // Some warnings exist in the warnings array and not DDSelfServInd
    if (respData?.warnings && respData.warnings.length > 0) {
        return checkWarnings({
            respData,
            navigate,
        });
    }

    return false;
}