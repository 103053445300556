import { AppContent } from '../AppContent';

const borrowMore: AppContent['borrowMore'] = {
  backButtonLabel: 'Back',
  title: 'Need to borrow more?',
  para1:
    'Speak to your mortgage broker if you want to borrow more on your mortgage.',
  para2:
    'Any additional borrowing cannot take your total loan to value (LTV) over 80%.',
  para3:
    "If you're in the first six months of your mortgage, you can't apply for any extra borrowing.",
  para4Title: 'Not got a mortgage broker?',
  link: {
    text: 'Search for a broker',
    url: 'https://www.unbiased.co.uk/',
  },
};

export default borrowMore;
