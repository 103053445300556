import { Box } from '@constellation/core';
import styled from 'styled-components';

export const BreakoutPage = styled.div`
  background-color: rgb(241, 241, 241);
  margin-top -${({ theme }) => theme.spacing_05};
  padding-top: ${({ theme }) => theme.spacing_05};
  flex-grow: 1;
  margin-bottom: -${({ theme }) => theme.spacing_06};
  padding-bottom: ${({ theme }) => theme.spacing_03};
`;

export const Panel = styled(Box)`
  background-color: white !important;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextIconContainer = styled.div`
  display: flex;
  align-items: center;`;