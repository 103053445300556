import { AppContent } from '../AppContent';

const redemptionUnavailable: AppContent['redemptionUnavailable'] = {
  heading: "We can't show your amount owing figure right now.",
  requestPaperStatementParagraph: 'Request a paper statement',
  orText: ' or ',
  callUsText: 'Call us on ',
  telLinkNumber: '0345 300 2627',
  callUsSuffix: '.',
  availabilityText:
    "We're open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed on Sundays and bank holidays.",
  returnLinkLabel: 'Return to mortgage overview',
};

export default redemptionUnavailable;
