import { AppContent } from '../AppContent';

const changeDateWarning: AppContent['changeDateWarning'] = {
  backButtonLabel: 'Back',
  title: 'Change the date you pay',
  accountInArrears: {
    heading: `This mortgage is in arrears. You'll need to talk to us, so we can help you make your changes.`,
    para1: 'Please call us on ',
    telNo: '0808 145 0372',
    telHref: 'tel:08081450372',
    para2: `. Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.`,
  },
  ddCancelled: {
    heading: `This mortgage doesn't have an active direct debit. You'll need to talk to us, so we can help you set things back up.`,
    para1: 'Please call us on ',
    telNo: '0345 300 2627',
    telHref: 'tel:03453002627',
    para2: `. Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.`,
  },
  ddDeleted: {
    heading: `This mortgage doesn't have an active direct debit. You'll need to talk to us, so we can help you set things back up.`,
    para1: 'Please call us on ',
    telNo: '0345 300 2627',
    telHref: 'tel:03453002627',
    para2: `. Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.`,
  },
  ddUnpaid: {
    heading: `This direct debit is unpaid, so we can't make your changes.`,
    para1: `We'll try and take it again 10 working days after your normal payment date. You could try making your changes after this date.`,
    para2: `If you think you might not be able to make your payment, please call us on `,
    telNo: '0345 300 2627',
    telHref: 'tel:03453002627',
    fullStop: '.',
    para3: `Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.`,
  },
  multipleDds: {
    heading: `This mortgage has more than one direct debit set up. You'll need to talk to us to help you change your payment date.`,
    para1: 'Please call us on ',
    telNo: '0345 300 2627',
    telHref: 'tel:03453002627',
    para2: `. Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.`,
  },
  mixedPaymentTypes: {
    heading: `This mortgage has more than one payment type set up. You'll need to talk to us to help you change your payment date. `,
    para1: 'Please call us on ',
    telNo: '0345 300 2627',
    telHref: 'tel:03453002627',
    para2: `. Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.`,
  },
  generalMessage: {
    heading: `This direct debit can't be changed online right now. You'll need to talk to us, so we can help you with your changes.`,
    para1: 'Please call us on ',
    telNo: '0345 300 2627',
    telHref: 'tel:03453002627',
    para2: `. Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.`,
  },
  customerNotNamedOnBankAcc: {
    heading: 'The direct debit date can only be changed by the bank account holder shown in the direct debit details',
    para1: 'Please ask the account holder to change the direct debit date or update the bank account to one in your name.',
    telNo: '0345 300 2627',
    telHref: 'tel:03453002627',
    para2: `If you aren't named on the mortgage or bank account, call us on `,
    para3: ` where our colleagues will help you make changes. Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.`,
  },
  returnToManage: 'Return to Manage',
};

export default changeDateWarning;
