export interface AddressLineSchemaErrorMessages {
  wrongType: string;
  empty: string;
  onlySpaces: string;
  badPattern: string;
  tooLong: string;
}

// validates Address lines
//   disallows potential injection characters like < > " ; $ \
//   also rejects special characters not used in addresses * % ! ? + =
export function AddressLineSchema(
  errorMessages: AddressLineSchemaErrorMessages,
) {
  return (value: any) => {
    const ONLY_SPACES_REGEX = /^\s+$/;
    const VALID_ADDRESS_REGEX = /^[^<>$;\\"*%!?+=]*$/;
    const MAX_LENGTH = 255;
    const VALID_TYPE = 'string';

    if (typeof value !== VALID_TYPE) {
      return errorMessages.wrongType;
    }

    if (!value) {
      return errorMessages.empty;
    }

    if (value.length > MAX_LENGTH) {
      return errorMessages.tooLong;
    }

    if (ONLY_SPACES_REGEX.test(value)) {
      return errorMessages.onlySpaces;
    }

    if (!VALID_ADDRESS_REGEX.test(value)) {
      return errorMessages.badPattern;
    }

    return undefined;
  };
}
