import React from 'react';

import { useTheme } from 'styled-components';

function IllustrationCancel() {
    const theme: any = useTheme();
    return (
        <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Large X-shaped cross icon</title>
            <path
                d="M1 9.005L43 51.005"
                stroke={theme.color_icon_default_1}
                strokeWidth="2"
            />
            <path
                d="M43 9.005L1 51.005"
                stroke={theme.color_icon_default_1}
                strokeWidth="2"
            />
        </svg>
    );
}

export default IllustrationCancel;