/* eslint-disable import/prefer-default-export */
import { Paragraph, Link } from '@constellation/core';
import styled from 'styled-components';

export const StyledParagraph = styled<typeof Paragraph>(Paragraph)`
  text-align: center !important;
`;

export const StyledLink = styled<typeof Link>(Link)`
  margin-right: 5px;
`;

export const CenteredText = styled.div`
  text-align: center;
`;
