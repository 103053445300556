import React, { useEffect } from 'react';

import {
  Grid,
  GridItem,
  Heading,
  Hr,
  Link,
  Main,
  Paragraph,
  Text,
  Notification,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import ChangeDateErrorContent from './ChangeDateErrorPage.config';
import { changeDateAvailabilityMessage } from '../unavailable/ChangeDateUnavailablePage';

function ChangeDateErrorPage() {
  const navigate = useNavigate();
  const { title, notification, applicationHours, backLinkLabel } =
    useContent<ChangeDateErrorContent>();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: undefined,
      stepName: 'Direct debits - Change date - Error',
      applicationState: 'Declined',
      actionNarrative: 'Something went wrong',
    });
  }, []);

  const helplineHref = `tel:${notification.helpline.replace(/\s/g, '')}`;

  return (
    <Main>
      <Heading as="h1" size="s7">
        {title}
      </Heading>

      <Grid>
        <GridItem md={8}>
          <Hr marginTop="none" />
          <Notification
            heading={notification.title}
            headingLevel={2}
            sentiment="critical"
          >
            <Paragraph>{notification.para1}</Paragraph>
            <Paragraph marginBottom="none">
              <Text>{notification.para2a}</Text>
              <Link
                onClick={() => {
                  logTealiumButtonClickEvent({ label: 'button/helpline' });
                }}
                href={helplineHref}
              >
                {notification.helpline}
              </Link>
              <Text>{notification.para2b}</Text>
            </Paragraph>
          </Notification>
        </GridItem>

        {changeDateAvailabilityMessage(applicationHours)}
      </Grid>

      <Link
        as="button"
        iconPosition="left"
        data-testid="navigate-back-button"
        onClick={() => {
          navigate(-1);
          logTealiumButtonClickEvent({ label: 'button/back' });
        }}
      >
        {backLinkLabel}
      </Link>
    </Main>
  );
}

export default ChangeDateErrorPage;
