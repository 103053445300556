import React, { ReactElement } from 'react';

import { Paragraph, Strong } from '@constellation/core';
import BoxProps from '@constellation/core/dist/types/components/Box/box.types';
import { ChevronRight } from '@constellation/core/icons';
import { Link as ReactRouterLink } from '@interstellar/react-app-routing';

import { NavigationTileContent } from './NavigationTile.config';
import {
  FullWidthBox,
  IconWithTextContainer,
  StyledNavigationLink,
  TextContainer,
} from './NavigationTile.styled';

type Props = {
  tileInfo: NavigationTileContent;
  pageTestId: string;
  tileIcon: ReactElement;
  onClick?: React.MouseEventHandler;
  bgColor?: BoxProps['bgColor'];
  showBorder?: boolean;
};

function NavigationTile({
  tileInfo,
  pageTestId,
  tileIcon,
  onClick,
  bgColor = 'neutral2',
  showBorder = false,
}: Props) {
  const { linkText, url, linkTestId, external, boxText } = tileInfo;
  return (
    <StyledNavigationLink
      as={ReactRouterLink}
      to={url}
      data-testid={`${linkTestId}-label`}
      target={external ? '_blank' : '_self'}
      onClick={onClick}
    >
      <FullWidthBox
        key={`${linkText}`}
        data-testid={`${pageTestId}`}
        marginBottom="05"
        bgColor={bgColor}
        $showBorder={showBorder}
      >
        <IconWithTextContainer>
          {tileIcon}
          <TextContainer>
            <Strong color="brand">{linkText}</Strong>
            <Paragraph
              size="s1"
              marginBottom="none"
              data-testid={`${linkTestId}-text`}
            >
              {boxText}
            </Paragraph>
          </TextContainer>
        </IconWithTextContainer>
        <ChevronRight color="default1" iconSize="md" />
      </FullWidthBox>
    </StyledNavigationLink>
  );
}

export default NavigationTile;
