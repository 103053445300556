import ChangeAmountContent from 'client/routes/manageDirectDebit/changeAmount/ChangeAmountPage.config';

const changeAmount: ChangeAmountContent = {
  backButtonLabel: 'Back',
  title: 'Change the amount you pay',
  pageBody: [
    'Your direct debit will automatically adjust if your mortgage payment changes. For example, if the Bank of England (BoE) base rate goes up or down.',
    `You don't need to do anything. We'll write to you to confirm the changes.`,
    `You won't be able to change your agreed monthly direct debit, but you have a few options to manage your payments.`,
  ],
  makeOverpayment: {
    text: 'To make an overpayment, call ',
    phoneNumber: '0345 300 2627',
    href: 'tel:03453002627',
  },
  ifYouAreStruggling: {
    text: 'If you are struggling with your mortgage payments, call ',
    phoneNumber: '0808 145 0372',
    phoneHref: 'tel:08081450372',
    secondaryText: ' or visit our ',
    secondaryLinkText: 'Worried about future payments?',
    secondaryLinkHref: 'https://www.bmmortgages.co.uk/knowledge-base/worried-about-future-payments',
    tertiaryText: ' page.'
  },
  availability: `We're available Monday to Friday 8am to 8pm and Saturday 9am to 1pm. We're closed on Sundays and bank holidays.`,
  returnToManage: 'Return to Manage',
  fullStop: '.',
};

export default changeAmount;
