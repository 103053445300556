export interface PersonNameSchemaErrorMessages {
  wrongType: string;
  empty: string;
  onlySpaces: string;
  badPattern: string;
  tooLong: string;
}

interface PersonNameSchemaOptions {
  maxLength?: number;
}

// validates the name of a person
//  must not be all spaces
//  must be at least 1 character
//  must be 64 characters or less
//  regex will accept
//    - any character from any language
//    - characters intended to be combined with others (accents, diacritics)
export function PersonNameSchema(
  errorMessages: PersonNameSchemaErrorMessages,
  options?: PersonNameSchemaOptions,
) {
  return (value: any) => {
    const ONLY_SPACES_REGEX = /^\s+$/;
    const VALID_NAME_REGEX = /^[\p{L}\p{M}'\-. ]+$/u;
    const MAX_LENGTH = options?.maxLength ?? 64;
    const VALID_TYPE = 'string';

    if (typeof value !== VALID_TYPE) {
      return errorMessages.wrongType;
    }

    if (!value) {
      return errorMessages.empty;
    }

    if (value.length > MAX_LENGTH) {
      return errorMessages.tooLong;
    }

    if (ONLY_SPACES_REGEX.test(value)) {
      return errorMessages.onlySpaces;
    }

    if (!VALID_NAME_REGEX.test(value)) {
      return errorMessages.badPattern;
    }

    return undefined;
  };
}
