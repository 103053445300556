import React, { useEffect } from 'react';

import {
  Main,
  Heading,
  Link,
  Paragraph,
  ButtonPattern,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate, useLocation } from '@interstellar/react-app-routing';
import {
  ORDER_DOC_CERTIFICATE_OF_INTEREST,
  ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE,
  ORDER_DOC_DUPLICATE_STATEMENT,
  ORDER_DOC_OVERPAYMENT_ALLOWANCE,
  ORDER_DOC_REDEMPTION_STATEMENT,
  OrderDocKey,
} from 'client/services/types/orderDocument';
import { logTealiumPageViewEvent } from 'client/tealium';

import { SuccessfulRequestContent } from './SuccessfulRequestPage.config';
import * as routes from '../../manifest';

export default function SuccessfulRequestPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const orderDocKey = (state as { orderDocKey: OrderDocKey })?.orderDocKey;

  const {
    pageTitle,
    fallbackSuccessMessage,
    returnToOverviewLink,
    backButtonLabel,
    successMessageCertificateOfInterest,
    successMessageChangeDirectDebit,
    successMessageDuplicateStatement,
    successMessageOverpaymentAllowance,
    successMessageRedemptionStatement1,
    successMessageRedemptionStatement2,
  } = useContent<SuccessfulRequestContent>();

  const keyToSuccessMessage = {
    [ORDER_DOC_CERTIFICATE_OF_INTEREST]: [successMessageCertificateOfInterest],
    [ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE]: [successMessageChangeDirectDebit],
    [ORDER_DOC_DUPLICATE_STATEMENT]: [successMessageDuplicateStatement],
    [ORDER_DOC_OVERPAYMENT_ALLOWANCE]: [successMessageOverpaymentAllowance],
    [ORDER_DOC_REDEMPTION_STATEMENT]: [
      successMessageRedemptionStatement1,
      successMessageRedemptionStatement2,
    ],
  };

  const successLines = keyToSuccessMessage[orderDocKey] ?? [
    fallbackSuccessMessage,
  ];

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '2',
      stepName: `Request form: successful ${orderDocKey ?? 'form-unknown'}`,
      applicationState: 'Pre-Application',
    });
  }, [orderDocKey]);

  return (
    <Main>
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => navigate(routes.MortgageOverview)}
      >
        {backButtonLabel}
      </Link>{' '}
      <Heading
        as="h1"
        size="s7"
        data-testid="overpayments-allowance-page-heading"
        marginTop="03"
      >
        {pageTitle}
      </Heading>
      {successLines.map((line) => (
        <Paragraph key={line}>{line}</Paragraph>
      ))}
      <ButtonPattern>
        <Link
          iconPosition="left"
          as="button"
          onClick={() => {
            navigate(routes.MortgageOverview);
          }}
        >
          {returnToOverviewLink}
        </Link>
      </ButtonPattern>
    </Main>
  );
}
