import React, { useEffect } from 'react';

import {
  ContentGroup,
  Heading,
  Hr,
  Link,
  Main,
  Paragraph,
} from '@constellation/core';
import { ChevronLeft } from '@constellation/core/icons';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { BorrowMoreContent } from './BorrowMorePage.config';
import * as routes from '../manifest';

function BorrowMorePage() {
  const navigate = useNavigate();
  const { backButtonLabel, title, para1, para2, para3, para4Title, link } =
    useContent<BorrowMoreContent>();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Borrow More',
      applicationState: 'Referred',
    });
  }, []);

  return (
    <Main>
      <Link
        as="button"
        icon={<ChevronLeft />}
        iconPosition="left"
        onClick={() => {
          logTealiumButtonClickEvent({ label: 'button/back' });
          navigate(routes.MortgageOverview);
        }}
        data-testid="borrow-more-back-button"
      >
        {backButtonLabel}
      </Link>

      <Hr />

      <ContentGroup>
        <Heading as="h1" size="s5">
          {title}
        </Heading>
        <Paragraph>{para1}</Paragraph>
        <Paragraph>{para2}</Paragraph>
        <Paragraph>{para3}</Paragraph>
        <Heading as="h2" marginBottom="04" size="s3">
          {para4Title}
        </Heading>
        <Paragraph>
          <Link
            external
            href={link.url}
            data-testid="borrow-more-find-broker-link"
            onClick={() => {
              logTealiumButtonClickEvent({ label: 'button/searchforonehere' });
            }}
          >
            {link.text}
          </Link>
        </Paragraph>
      </ContentGroup>
    </Main>
  );
}

export default BorrowMorePage;
