import { AppContent } from '../AppContent';

const changeDateConfirmation: AppContent['changeDateConfirmation'] = {
  heading: 'Change the date you pay',
  notificationHeading: 'Direct debit date updated',
  notificationText1: "We'll take your payments on",
  notificationText2:
    "of every month. You'll receive a confirmation letter in the post.",
  summary: {
    heading: 'Direct debit summary',
    text: 'There is no need to change your monthly payment amount. Your payments will include any regular overpayments you currently have set up.',
    directDebitDetailsHeading: 'Direct debit details',
    estimatedNextPaymentsLabel: 'Estimated next three payments',
  },
  returnToManageButtonLabel: 'Return to Manage',
};

export default changeDateConfirmation;
