import React, { useEffect, useState } from 'react';

import {
  Main,
  IconChevronLeft,
  Hr,
  Grid,
  GridItem,
  Box,
  Paragraph,
  Link,
  Button,
  Option,
  Dropdown,
  ButtonPattern,
  Heading,
  Dialog,
  Spinner,
  Container,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { Navigate, useNavigate } from '@interstellar/react-app-routing';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import {
  useAmendDirectDebitInquiryMutation,
  useDirectDebitQuery,
} from 'client/services/api';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';
import getOrdinal from 'client/utils/getOrdinal';
import { Field, Form, Formik } from 'formik';

import ChangeDateFormContent from './ChangeDateFormPage.config';
import { checkDDErrors } from './utils/ddErrorWarningHandler';

export interface FormValues {
  paymentDate: string;
}

function ChangeDateFormPage() {
  const { data, isLoading, isError } = useDirectDebitQuery();
  const [
    amendDirectDebitInquiry,
    { isLoading: isLoadingAmendDD, isError: isErrorAmendDD },
  ] = useAmendDirectDebitInquiryMutation();
  const navigate = useNavigate();
  const {
    backButtonLabel,
    title,
    currentPaymentDateLabel,
    currentPaymentDateSuffix,
    paymentDateField,
    beforeChooseDateDialog,
    currentPaymentDateOptionSuffix,
    secondaryBackLinkLabel: backToOverviewLabel,
    continueLabel,
  } = useContent<ChangeDateFormContent>();
  const [beforeChooseDateDialogOpen, setBeforeChooseDateDialogOpen] =
    useState(false);

  useEffect(() => {
    logTealiumPageViewEvent({
      stepName: 'Direct debits - Change date - Details',
      stepNumber: '2',
      applicationState: 'Application',
    });
  }, []);

  const onSubmit = async ({ paymentDate }: FormValues) => {
    const directDebitPaymentDay = parseInt(paymentDate, 10);

    const response = await amendDirectDebitInquiry({
      directDebitPaymentDay,
      amendType: 1,
    });

    if ('error' in response) {
      navigate(routes.Error);
      return;
    }

    const hasErrors = checkDDErrors({ respData: response.data, navigate });
    if (hasErrors) return;

    navigate(routes.ManageDirectDebitChangeDateSummary, {
      state: {
        newDDDate: directDebitPaymentDay,
        futurePayments: response?.data?.futurePaymentDetails,
      },
    });
  };

  const validateForm = ({ paymentDate }: FormValues) => {
    const errors: Partial<{
      paymentDate: string;
    }> = {};

    if (paymentDate === '0') {
      errors.paymentDate = paymentDateField.errors.required;
    }

    return errors;
  };

  if (isLoading || isLoadingAmendDD) {
    return (
      <Main>
        <Container data-testid="loading-container">
          <Spinner />
        </Container>
      </Main>
    );
  }

  if (isError || isErrorAmendDD) {
    return <Navigate to={routes.Error} />;
  }

  // Safe to assume its always the first element as we only get into this journey if we have one direct debit.
  const currentPaymentDate = data?.currentDDDetails[0].paymentDate;

  return (
    <Main>
      <Dialog
        title={beforeChooseDateDialog.title}
        open={beforeChooseDateDialogOpen}
        onClose={() => {
          logTealiumButtonClickEvent({ label: 'button/close-dialog' });
          setBeforeChooseDateDialogOpen(false);
        }}
      >
        {beforeChooseDateDialog.paragraphs.map((paragraph, index) => (
          <Paragraph
            key={paragraph}
            marginBottom={
              index === beforeChooseDateDialog.paragraphs.length - 1
                ? 'none'
                : undefined
            }
          >
            {paragraph}
          </Paragraph>
        ))}
      </Dialog>
      <Container padding="none" marginBottom="05">
        <StyledNavLink
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.ManageDirectDebitChangeDatePreamble}
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back-top' });
          }}
        >
          {backButtonLabel}
        </StyledNavLink>
      </Container>
      <Hr marginTop="none" />
      <Grid>
        <GridItem md={8}>
          <Heading as="h1" size="s5">
            {title}
          </Heading>
          <Box>
            <Heading as="h2" size="s3" marginBottom="02">
              {currentPaymentDateLabel}
            </Heading>
            <Paragraph marginBottom="none">{`${currentPaymentDate}${getOrdinal(currentPaymentDate)} ${currentPaymentDateSuffix}`}</Paragraph>
          </Box>

          <Hr />
          <Formik
            initialValues={{
              paymentDate: '0',
            }}
            initialErrors={{
              redemptionDate: undefined,
            }}
            onSubmit={onSubmit}
            validate={validateForm}
          >
            {({ touched, errors, handleChange }) => (
              <Form>
                <Field
                  as={Dropdown}
                  name="paymentDate"
                  label={paymentDateField.label}
                  error={touched.paymentDate && errors.paymentDate}
                  data-testid="payment-date-dropdown"
                  variation="Spaced-Auto"
                  marginBottom="05"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    logTealiumButtonClickEvent({
                      label: `button/payment-date-${e.target.value}`,
                    });
                    handleChange(e);
                  }}
                  supportiveText={
                    <Link
                      as="button"
                      type="button"
                      data-testid="before-you-choose-date-link"
                      onClick={() => {
                        logTealiumButtonClickEvent({
                          label: 'button/before-you-choose-a-date',
                        });
                        setBeforeChooseDateDialogOpen(true);
                      }}
                    >
                      {paymentDateField.supportiveText}
                    </Link>
                  }
                >
                  <Option hidden>{paymentDateField.placeholder}</Option>
                  {[...Array(28)].map((_, idx) => {
                    const value = idx + 1;
                    const key = `payment-date-${value}`;
                    const label = `${value} ${value === currentPaymentDate ? currentPaymentDateOptionSuffix : ''}`;

                    return (
                      <Option
                        id={`payment-date-${idx + 1}`}
                        data-testid={`payment-date-${idx + 1}`}
                        disabled={idx === currentPaymentDate - 1}
                        name="paymentDate"
                        key={key}
                        value={value}
                      >
                        {label}
                      </Option>
                    );
                  })}
                </Field>

                <ButtonPattern>
                  <Button
                    type="submit"
                    iconPosition="right"
                    onClick={() => {
                      logTealiumButtonClickEvent({ label: 'button/continue' });
                    }}
                  >
                    {continueLabel}
                  </Button>
                  <StyledNavLink
                    data-testid="secondary-back-link"
                    iconPosition="left"
                    to={routes.ManageDirectDebitChangeDatePreamble}
                    onClick={() => {
                      logTealiumButtonClickEvent({
                        label: 'button/back-bottom',
                      });
                    }}
                  >
                    {backToOverviewLabel}
                  </StyledNavLink>
                </ButtonPattern>
              </Form>
            )}
          </Formik>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default ChangeDateFormPage;
