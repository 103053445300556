import React, { ReactElement } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { Routes, Route } from '@interstellar/react-app-routing';
import ProtectedRoute from 'client/components/protectedRoute/ProtectedRoute';

import BorrowMorePage from './borrowMore/BorrowMorePage';
import ErrorPage from './errorPage/ErrorPage';
import MakePaymentBankTransferPage from './makePayment/bankTransfer/MakePaymentBankTransferPage';
import MakePaymentPage from './makePayment/MakePaymentPage';
import MakePaymentPhonePage from './makePayment/phone/MakePaymentPhonePage';
import CancelDirectDebitPage from './manageDirectDebit/cancelDirectDebit/CancelDirectDebitPage';
import ChangeAmountPage from './manageDirectDebit/changeAmount/ChangeAmountPage';
import ChangeBankAccountPreamblePage from './manageDirectDebit/changeBankAccount/changeBankAccountPreamble/ChangeBankAccountPreamblePage';
import ChangeDateConfirmationPage from './manageDirectDebit/changeDate/changeDateConfirmation/ChangeDateConfirmationPage';
import ChangeDateFormPage from './manageDirectDebit/changeDate/ChangeDateFormPage';
import ChangeDatePreamblePage from './manageDirectDebit/changeDate/changeDatePreamble/ChangeDatePreamblePage';
import ChangeDateRaisedWarningPage from './manageDirectDebit/changeDate/changeDateRaisedWarning/ChangeDateRaisedWarningPage';
import ChangeDateWarningPage from './manageDirectDebit/changeDate/changeDateWarning/ChangeDateWarningPage';
import ChangeDateYouPaySummary from './manageDirectDebit/changeDate/changeDateYouPaySummary/ChangeDateYouPaySummary';
import ChangeDateErrorPage from './manageDirectDebit/changeDate/error/ChangeDateErrorPage';
import ChangeDateUnavailablePage from './manageDirectDebit/changeDate/unavailable/ChangeDateUnavailablePage';
import ManageDirectDebitFailedPage from './manageDirectDebit/directDebitFailed/ManageDirectDebitFailedPage';
import ManageDirectDebitPage from './manageDirectDebit/ManageDirectDebitPage';
import RestartCancelledDirectDebit from './manageDirectDebit/restartCancelledDirectDebit/RestartCancelledDirectDebit';
import AddReminderPage from './manageReminder/addReminder/AddReminderPage';
import ReminderEmailPage from './manageReminder/addReminderEmail/AddReminderEmailPage';
import ManageReminderPage from './manageReminder/ManageReminderPage';
import * as routes from './manifest';
import MortgageOverviewPage from './mortgageOverview/MortgageOverviewPage';
import NotFoundPage from './notFound/NotFoundPage';
import OverpaymentsPage from './overpayments/OverpaymentsPage';
import OverpaymentsAllowanceAccountWarningPage from './overpaymentsAllowance/errorPages/accountWarning/OverpaymentsAllowanceAccountWarningPage';
import OverpaymentsAllowanceTechnicalErrorPage from './overpaymentsAllowance/errorPages/technicalError/OverpaymentsAllowanceTechnicalErrorPage';
import OverpaymentAllowancePage from './overpaymentsAllowance/OverpaymentsAllowancePage';
import ProductTransferPage from './productTransfer/ProductTransferPage';
import RedemptionAlreadyRequestedPage from './redemption/alreadyRequested/RedemptionAlreadyRequestedPage';
import RedemptionErrorPage from './redemption/error/RedemptionErrorPage';
import RedemptionPage from './redemption/RedemptionPage';
import RedemptionSuccessPage from './redemption/success/RedemptionSuccessPage';
import RedemptionUnavailablePage from './redemption/unavailable/RedemptionUnavailablePage';
import RentalYieldCalculatorPage from './rentalYieldCalculator/RentalYieldCalculatorPage';
import DebitChangeRequestPage from './requests/debitDateChange/DebitChangeRequestPage';
import DuplicateStatementPage from './requests/duplicateStatement/DuplicateStatementPage';
import MortgageInterestPage from './requests/mortgageInterest/MortgageInterestPage';
import OverpaymentsRequestPage from './requests/overpayments/OverpaymentsRequestPage';
import RedemptionStatementPage from './requests/redemptionStatement/RedemptionStatementPage';
import SuccessfulRequestPage from './requests/successfulRequest/SuccessfulRequestPage';
import ServiceUnavailablePage from './serviceUnavailable/ServiceUnavailablePage';
import SignInPage from './signIn/SignInPage';
import SignOutPage from './signOut/SignOutPage';
import useAppConfig from '../components/appConfig/useAppConfig';
import content from '../content';
import { getThemeFromUrl } from '../utils/getThemeFromUrl';

function App(): ReactElement {
  const brandConfig = useAppConfig().BRAND_NAME;
  const brand = getThemeFromUrl() || brandConfig;

  const {
    requestFormsEnabled,
    enableDirectDebits,
    enableDirectDebitsChangePaymentDate,
    enableDirectDebitsChangeBankAccount,
    enableReminders,
  } = window.appConfig;

  return (
    <Routes>
      <Route
        path={routes.SignIn}
        element={
          <ContentProvider value={content({ brand }).signIn}>
            <SignInPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.SignedOut}
        element={
          <ContentProvider value={content({ brand }).signOut}>
            <SignOutPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.MortgageOverview}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).mortgageOverview,
                ...content({ brand }).signOutModal,
              }}
            >
              <MortgageOverviewPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.Manage}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).mortgageOverview,
                ...content({ brand }).signOutModal,
              }}
            >
              <MortgageOverviewPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.Tools}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).mortgageOverview,
                ...content({ brand }).signOutModal,
              }}
            >
              <MortgageOverviewPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RentalYield}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).rentalYieldCalculator}>
              <RentalYieldCalculatorPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.ProductTransfer}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).productTransfer}>
              <ProductTransferPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.BorrowMore}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).borrowMore}>
              <BorrowMorePage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.Overpayments}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).overpayments}>
              <OverpaymentsPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.OverpaymentAllowance}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).overpaymentsAllowance}>
              <OverpaymentAllowancePage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      {requestFormsEnabled && (
        <Route
          path={routes.RequestOverpaymentsAllowance}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).overpaymentsRequest,
                  ...content({ brand }).orderDocumentForm,
                }}
              >
                <OverpaymentsRequestPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.RequestRedemptionStatement}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).redemptionStatement,
                  ...content({ brand }).orderDocumentForm,
                }}
              >
                <RedemptionStatementPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.RequestDuplicateStatement}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).duplicateStatement,
                  ...content({ brand }).orderDocumentForm,
                }}
              >
                <DuplicateStatementPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.RequestChangeDirectDebit}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).debitChangeRequest,
                  ...content({ brand }).orderDocumentForm,
                }}
              >
                <DebitChangeRequestPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.RequestCertificateOfInterest}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={{
                  ...content({ brand }).mortgageInterest,
                  ...content({ brand }).orderDocumentForm,
                }}
              >
                <MortgageInterestPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {requestFormsEnabled && (
        <Route
          path={routes.RequestSuccessful}
          element={
            <ProtectedRoute>
              <ContentProvider value={content({ brand }).successfulRequest}>
                <SuccessfulRequestPage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      <Route
        path={routes.MakePayment}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).makePayment}>
              <MakePaymentPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.MakePaymentBankTransfer}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).makePaymentBankTransfer,
                ...content({ brand }).onceMadePaymentAccordion,
              }}
            >
              <MakePaymentBankTransferPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.MakePaymentPhone}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).makePaymentPhone,
                ...content({ brand }).onceMadePaymentAccordion,
              }}
            >
              <MakePaymentPhonePage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.Redemption}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={{
                ...content({ brand }).redemption,
                ...content({ brand }).onceMadePaymentAccordion,
              }}
            >
              <RedemptionPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RedemptionError}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).redemptionError}>
              <RedemptionErrorPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RedemptionAlreadyRequested}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={content({ brand }).redemptionAlreadyRequested}
            >
              <RedemptionAlreadyRequestedPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RedemptionUnavailable}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).redemptionUnavailable}>
              <RedemptionUnavailablePage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.RedemptionSuccess}
        element={
          <ProtectedRoute>
            <ContentProvider value={content({ brand }).redemptionSuccess}>
              <RedemptionSuccessPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.OverpaymentAllowanceTechnicalError}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={content({ brand }).overpaymentsAllowanceTechnicalError}
            >
              <OverpaymentsAllowanceTechnicalErrorPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      <Route
        path={routes.OverpaymentAllowanceAccountWarning}
        element={
          <ProtectedRoute>
            <ContentProvider
              value={content({ brand }).overpaymentsAllowanceAccountWarning}
            >
              <OverpaymentsAllowanceAccountWarningPage />
            </ContentProvider>
          </ProtectedRoute>
        }
      />

      {enableDirectDebits && (
        <>
          <Route
            path={routes.ManageDirectDebit}
            element={
              <ProtectedRoute>
                <ContentProvider value={content({ brand }).manageDirectDebit}>
                  <ManageDirectDebitPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ManageDirectDebitFailed}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={content({ brand }).manageDirectDebitFailed}
                >
                  <ManageDirectDebitFailedPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.RestartCancelledDirectDebit}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={content({ brand }).restartCancelledDirectDebit}
                >
                  <RestartCancelledDirectDebit />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ManageDirectDebitChangeAmount}
            element={
              <ProtectedRoute>
                <ContentProvider value={content({ brand }).changeAmount}>
                  <ChangeAmountPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ManageDirectDebitCancelDirectDebit}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={{
                    ...content({ brand }).cancelDirectDebit,
                  }}
                >
                  <CancelDirectDebitPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />
        </>
      )}

      {enableDirectDebits && enableDirectDebitsChangePaymentDate && (
        <>
          <Route
            path={routes.ManageDirectDebitChangeDatePreamble}
            element={
              <ProtectedRoute>
                <ContentProvider value={content({ brand }).changeDatePreamble}>
                  <ChangeDatePreamblePage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ManageDirectDebitChangeDateForm}
            element={
              <ProtectedRoute>
                <ContentProvider value={content({ brand }).changeDateForm}>
                  <ChangeDateFormPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ManageDirectDebitChangeDateSummary}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={{
                    ...content({ brand }).changeDateYouPaySummary,
                  }}
                >
                  <ChangeDateYouPaySummary />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ManageDirectDebitChangeDateConfirmation}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={{
                    ...content({ brand }).changeDateConfirmation,
                    ...content({ brand }).manageDirectDebit,
                  }}
                >
                  <ChangeDateConfirmationPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ChangeDateWarning}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={{
                    ...content({ brand }).changeDateWarning,
                  }}
                >
                  <ChangeDateWarningPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ManageDirectDebitChangeDateError}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={content({ brand }).directDebitChangeDateError}
                >
                  <ChangeDateErrorPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ManageDirectDebitChangeDateUnavailable}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={content({ brand }).directDebitChangeDateUnavailable}
                >
                  <ChangeDateUnavailablePage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.ChangeDateRaisedWarning}
            element={
              <ProtectedRoute>
                <ContentProvider
                  value={content({ brand }).changeDateRaisedWarning}
                >
                  <ChangeDateRaisedWarningPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />
        </>
      )}

      {enableDirectDebits && enableDirectDebitsChangeBankAccount && (
        <Route
          path={routes.ManageDirectDebitBankAccountPreamble}
          element={
            <ProtectedRoute>
              <ContentProvider
                value={content({ brand }).changeBankAccountPreamble}
              >
                <ChangeBankAccountPreamblePage />
              </ContentProvider>
            </ProtectedRoute>
          }
        />
      )}

      {enableReminders && (
        <>
          <Route
            path={routes.ManageReminder}
            element={
              <ProtectedRoute>
                <ContentProvider value={content({ brand }).manageReminder}>
                  <ManageReminderPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.AddReminder}
            element={
              <ProtectedRoute>
                <ContentProvider value={content({ brand }).addReminder}>
                  <AddReminderPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.AddReminderEmailForm}
            element={
              <ProtectedRoute>
                <ContentProvider value={content({ brand }).addReminderEmail}>
                  <ReminderEmailPage />
                </ContentProvider>
              </ProtectedRoute>
            }
          />
        </>
      )}

      <Route
        path={routes.Error}
        element={
          <ContentProvider value={content({ brand }).error}>
            <ErrorPage />
          </ContentProvider>
        }
      />

      <Route
        path={routes.ServiceUnavailable}
        element={
          <ContentProvider value={content({ brand }).serviceUnavailable}>
            <ServiceUnavailablePage />
          </ContentProvider>
        }
      />

      <Route
        path="*"
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <NotFoundPage />
          </ContentProvider>
        }
      />
    </Routes>
  );
}

export default App;
