import React, { useEffect } from 'react';

import {
  Main,
  Heading,
  Paragraph,
  ListItem,
  List,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import OrderDocumentForm from 'client/routes/requests/components/orderDocumentForm/OrderDocumentForm';
import { ORDER_DOC_OVERPAYMENT_ALLOWANCE } from 'client/services/types/orderDocument';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { OverpaymentsRequestContent } from './OverpaymentsRequestPage.config';
import * as routes from '../../manifest';

export default function OverpaymentsRequestPage() {
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Request confirmation of overpayments allowance',
      applicationState: 'Pre-Application',
    });
  }, []);

  const { pageTitle, requestInformation, backButtonLabel } =
    useContent<OverpaymentsRequestContent>();

  return (
    <Main>
      <Link
        iconPosition="left"
        as="button"
        data-testid="back-button-link"
        onClick={() => {
          logTealiumButtonClickEvent({ label: 'button/back' });
          navigate(routes.MortgageOverview);
        }}
      >
        {backButtonLabel}
      </Link>
      <Heading
        as="h1"
        size="s7"
        marginTop="05"
        data-testid="overpayments-allowance-page-heading"
      >
        {pageTitle}
      </Heading>
      <Paragraph data-testid="opening-section-first-paragraph">
        {requestInformation.openingParagraph}
      </Paragraph>
      <Heading
        data-testid="what-you-need-to-know-section-heading"
        as="h2"
        size="s4"
      >
        {requestInformation.listHeading}
      </Heading>
      <List data-testid="what-you-need-to-know-list">
        {requestInformation.sectionListItems.map((item, index) => (
          <ListItem
            data-testid={`what-you-need-to-know-list-item-${index}`}
            key={item}
          >
            {item}
          </ListItem>
        ))}
      </List>
      <Paragraph data-testid="form-instruction">
        {requestInformation.nextActionsInfo}
      </Paragraph>
      <OrderDocumentForm orderDocKey={ORDER_DOC_OVERPAYMENT_ALLOWANCE} />
    </Main>
  );
}
