import { AppContent } from '../AppContent';

const changeDateRaisedWarning: AppContent['changeDateRaisedWarning'] = {
  backButtonLabel: 'Back',
  title: 'Change the date you pay',
  heading: `This direct debit is in the process of being taken.`,
  pleaseComeBack: `Please come back once your payment has been taken and you'll be able to change your payment date.`,
  altCallUs: 'Alternatively, call us on ',
  telNo: '0345 300 2627',
  telNoHref: 'tel:03453002627',
  linesAreOpen: '. Lines are open Monday to Friday 8am to 8pm and Saturday 9am to 1pm.',
  wereClosed: `We're closed Sundays and bank holidays.`,
  accordions: {
    mightMissPayment: {
      heading: 'Think you might need to miss a payment?',
      pleaseCallUsOn: 'Please call us on ',
      wereAvailable: `. We're available Monday to Friday 8am to 8pm and Saturday 9am to 1pm. We're closed on Sundays and bank holidays.`,
      telNo: '0345 300 2627',
      telNoHref: 'tel:03453002627',
    },
    alreadyMissedPayment: {
      heading: 'Already missed a payment?',
      callUsOn: 'Call us on ',
      telNo: '0808 145 0372',
      telNoHref: 'tel:08081450372',
      linesAreOpen: `. Lines are open Monday to Friday, 8am to 8pm and Saturday 8am to 1pm. We're closed on Sundays and bank holidays.`,
    },
    troublePayingMortgage: {
      heading: 'Having trouble paying your mortgage?',
      pleaseDontIgnore: `Please don't ignore the problem.`,
      wereHereToHelpLink: `We're here to help`,
      wereHereToHelpHref: 'https://www.bmmortgages.co.uk/knowledge-base/worried-about-future-payments',
      fullStop: '.',
    },
  },
  returnToManage: 'Return to Manage',
};

export default changeDateRaisedWarning;
