import styled from 'styled-components';

export const BreakoutPage = styled.div`
  background-color: ${({ theme }) => theme.color_background_neutral_2};
  margin-top -${({ theme }) => theme.spacing_05};
  padding-top: ${({ theme }) => theme.spacing_05};
  flex-grow: 1;
  margin-bottom: -${({ theme }) => theme.spacing_06};
  padding-bottom: ${({ theme }) => theme.spacing_03};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;
