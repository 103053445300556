import React from 'react';

import {
  Button,
  Container,
  Heading,
  Hr,
  IconChevronLeft,
  Link,
  Main,
  Spinner,
} from '@constellation/core';
import { Plus } from '@constellation/core/icons';
import { useContent } from '@interstellar/react-app-content';
import { Navigate, useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import { useGetAllRemindersQuery } from 'client/services/api';
import { logTealiumButtonClickEvent } from 'client/tealium';

import ManageReminderPageContent from './ManageReminderPage.config';
import { BreakoutPage, StyledContainer } from './ManageReminderPage.styled';

function ManageReminderPage() {
  const {
    title,
    backButtonLabel,
    addReminderButtonLabel,
    addYourFirstReminderButtonLabel,
  } = useContent<ManageReminderPageContent>();
  const navigate = useNavigate();

  const { data, isError, isLoading } = useGetAllRemindersQuery();

  if (isLoading) {
    return (
      <BreakoutPage>
        <Main>
          <Container data-testid="loading-container">
            <Spinner />
          </Container>
        </Main>
      </BreakoutPage>
    );
  }

  if (isError) {
    return <Navigate to={routes.Error} replace />;
  }

  const { reminders } = data;

  return (
    <BreakoutPage>
      <Main>
        <StyledContainer>
          <Link
            icon={<IconChevronLeft trim />}
            iconPosition="left"
            as="button"
            data-testid="back-button-link"
            onClick={() => {
              logTealiumButtonClickEvent({ label: 'button/back' });
              navigate(routes.MortgageOverview);
            }}
          >
            {backButtonLabel}
          </Link>
        </StyledContainer>
        <Hr marginTop="none" />
        <Heading as="h1" size="s5" data-testid="page-heading">
          {title}
        </Heading>
          <Container marginBottom="05" padding="none">
            <Button
              icon={<Plus trim />}
              iconPosition="left"
              variation="secondary"
              onClick={()=>{
                navigate(routes.AddReminder)
              }}
              data-testid="add-reminder-button"
            >
              {reminders.length > 0 ? addReminderButtonLabel : addYourFirstReminderButtonLabel}
            </Button>
          </Container>        
      </Main>
    </BreakoutPage>
  );
}

export default ManageReminderPage;
