import React from "react";

import { Button, ButtonPattern, Container, Grid, GridItem, Heading, Hr, IconChevronLeft, Link, List, ListItem, Main, Paragraph, Text } from "@constellation/core";
import { useContent } from "@interstellar/react-app-content";
import { useNavigate } from "@interstellar/react-app-routing";
import { StyledNavLink } from "client/components/styledNavLink/StyledNavLink";
import { logTealiumButtonClickEvent } from "client/tealium";

import ChangeBankAccountPreambleContent from "./ChangeBankAccountPreamblePage.config";
import * as routes from '../../../manifest'

function ChangeBankAccountPreamblePage() {
  const content = useContent<ChangeBankAccountPreambleContent>();
  const {
    heading,
    beforeYouStartHeading,
    listItems,
    contactUsPara1,
    contactNumber,
    contactUsPara2,
    continueLabel,
    backLabel
  } = content
  const navigate = useNavigate();

  return (
    <Main>
      <Container marginBottom="05" padding="none">
        <StyledNavLink
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.ManageDirectDebit}
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back-top' });
          }}
        >
          {backLabel}
        </StyledNavLink>
      </Container>
      <Hr marginTop="none" />

      <Grid>
        <GridItem lg={8}>
          <Heading as="h1" size='s5'>{heading}</Heading>
          <Paragraph weight="bold" size="s3">{beforeYouStartHeading}</Paragraph>
          <List>
            {listItems.map((item) => (
              <ListItem key={item}>
                {item}
              </ListItem>
            ))}
          </List>

          <Paragraph>
            <Text>{contactUsPara1}</Text>
            <Link href={`tel:${contactNumber.trim()}`}>{contactNumber}</Link>
            <Text>{contactUsPara2}</Text>
          </Paragraph>

          <ButtonPattern>
            <Button
              iconPosition="right"
              onClick={() => navigate(routes.ManageDirectDebitChangeBankAccountForm)}>
              {continueLabel}</Button>

            <StyledNavLink
              data-testid="secondary-back-link"
              iconPosition="left"
              to={routes.ManageDirectDebit}
            >
              {backLabel}
            </StyledNavLink>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  )
}

export default ChangeBankAccountPreamblePage;
