import { AppContent } from '../AppContent';

const manageDirectDebitFailed: AppContent['manageDirectDebitFailed'] = {
  backButtonLabel: 'Back',
  title: 'My direct debit failed or will fail',
  para1:
    "We'll try to take your payment again 14 calendar days after the first direct debit has failed. For example, if your direct debit was due on 01 of the month, the second attempt will be made on 15 of the month, unless this is a bank holiday, in which case it'll be the next working day.",
  missedPaymentsCta: {
    title: 'Missed a mortgage payment?',
    secondaryText: 'Get help online now',
    url: 'https://www.bmmortgages.co.uk/knowledge-base/worried-about-future-payments',
  },
  para2heading: 'Important:',
  para2a:
    "In some cases, your payment might be made in the next month. For example, if your payment date was 21 August, we'd try to take it again on 04 September. ",
  para2b: 'This could impact your credit file.',
  para3a:
    "If you'd like to make a payment another way or you've already made a payment to catch up, please call us on ",
  helpline: '0345 300 2627',
  para3b: ' to stop us trying to take the direct debit again.',
  para4:
    "We're available Monday to Friday 8am to 8pm and Saturday 9am to 1pm. We're closed on Sundays and bank holidays.",
  returnToManageLabel: 'Return to Manage',
};

export default manageDirectDebitFailed;
