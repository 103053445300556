import React, { useEffect } from 'react';

import {
  Heading,
  List,
  ListItem,
  Main,
  Paragraph,
  Link,
  ContentGroup,
  Strong,
  Hr,
  Container,
  SummaryBox,
  Text,
  Button,
  Spinner,
  ButtonPattern,
  IconChevronLeft,
  Grid,
  GridItem,
  IconCalendar,
  IconInfo as Info,
  IconPound,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import {
  Navigate,
  useLocation,
  useNavigate,
} from '@interstellar/react-app-routing';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import { formatCurrency } from 'client/routes/mortgageOverview/utils/formatCurrency';
import formatDate from 'client/routes/mortgageOverview/utils/formatDate';
import { useAmendDirectDebitUpdateMutation } from 'client/services/api';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { ChangeDateYouPaySummaryContent } from './ChangeDateYouPaySummary.config';
import {
  DetailsRow,
  TextIconContainer,
} from './ChangeDateYouPaySummary.styled';
import * as routes from '../../../manifest';
import { DDChangeDateConfirmationData } from '../changeDateConfirmation/ChangeDateConfirmationPage';
import { FormValues } from '../ChangeDateFormPage';
import { checkDDErrors } from '../utils/ddErrorWarningHandler';

function ChangeDateYouPaySummary() {
  const [
    amendDirectDebitUpdate,
    { isLoading: isLoadingAmendDD, isError: isErrorAmendDD },
  ] = useAmendDirectDebitUpdateMutation();
  const {
    pageHeading,
    backButtonLabel,
    openingSection,
    estimatedPaymentsLabel,
    importantInfo,
    closingSection,
    editPaymentDate,
    confirmButton,
    backToOverview,
    newPaymentDateLabel,
    ofTheMonthLabel,
  } = useContent<ChangeDateYouPaySummaryContent>();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '3',
      stepName: 'Direct debit - Change date - Review',
      applicationState: 'Pre-Application',
    });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const { futurePayments, newDDDate } =
    (location.state as DDChangeDateConfirmationData) || {};

  if (!futurePayments || !newDDDate) {
    return <Navigate to={routes.ManageDirectDebitChangeDatePreamble} replace />;
  }

  const onSubmit = async ({ paymentDate }: FormValues) => {
    const directDebitPaymentDay = parseInt(paymentDate, 10);

    const response = await amendDirectDebitUpdate({
      directDebitPaymentDay,
      amendType: 1,
    });

    if ('error' in response || !response.data) {
      navigate(routes.Error);
      return;
    }

    const hasErrors = checkDDErrors({ respData: response?.data, navigate });
    if (hasErrors) return;

    const handoverState: DDChangeDateConfirmationData = {
      newDDDate: directDebitPaymentDay,
      futurePayments,
    };

    navigate(routes.ManageDirectDebitChangeDateConfirmation, {
      state: handoverState,
    });
  };

  if (isLoadingAmendDD) {
    return (
      <Main>
        <Container data-testid="loading-container">
          <Spinner />
        </Container>
      </Main>
    );
  }

  if (
    isErrorAmendDD ||
    !location.state ||
    Number.isNaN(location?.state?.newDDDate)
  ) {
    return <Navigate to={routes.Error} />;
  }

  return (
    <Main>
      <StyledNavLink
        icon={<IconChevronLeft trim />}
        iconPosition="left"
        data-testid="back-button-link"
        to={routes.ManageDirectDebitChangeDateForm}
        onClick={() => {
          logTealiumButtonClickEvent({ label: 'button/back-top' });
        }}
      >
        {backButtonLabel}
      </StyledNavLink>
      <Hr />

      <Grid>
        <GridItem md={8}>
          <ContentGroup marginBottom="none">
            <Heading as="h1" size="s5">
              {pageHeading}
            </Heading>
            <Paragraph>
              <Strong>{openingSection.boldText}</Strong>
            </Paragraph>
            <Paragraph>{openingSection.supportiveText}</Paragraph>
            <List marginBottom="05">
              {openingSection.bulletPoints?.map((bullet) => (
                <ListItem key={bullet}>{bullet}</ListItem>
              ))}
            </List>
          </ContentGroup>
          <DetailsRow>
            <TextIconContainer>
              <IconCalendar iconSize="md" color="brand" />
              <Text weight="bold">{newPaymentDateLabel}</Text>
            </TextIconContainer>

            <Text color="subdued">
              {newDDDate?.toString().padStart(2, '0')} {ofTheMonthLabel}
            </Text>
          </DetailsRow>
          <Paragraph marginTop="05">{estimatedPaymentsLabel}</Paragraph>
          <List>
            {futurePayments &&
              futurePayments.map((item, index) => (
                <React.Fragment key={`${item.paymentDate}-fragment`}>
                  <DetailsRow key={`${item.paymentDate}-future-payment`}>
                    <TextIconContainer>
                      <IconPound iconSize="md" color="brand" />
                      <Text weight="bold">
                        {formatDate(futurePayments[index].paymentDate, true)}
                      </Text>
                    </TextIconContainer>

                    <Text color="subdued">
                      {formatCurrency(futurePayments[index].paymentAmount)}
                    </Text>
                  </DetailsRow>
                  {index < futurePayments.length - 1 && (
                    <Hr
                      key={`${item.paymentDate}-hr`}
                      marginTop="02"
                      marginBottom="02"
                    />
                  )}
                </React.Fragment>
              ))}
          </List>
          <SummaryBox marginTop="05">
            <Info iconSize="lg" color="information" />
            <Paragraph marginTop="04">
              <Strong>{importantInfo.heading}</Strong>
            </Paragraph>
            <Paragraph marginBottom="none">
              <Strong>{importantInfo.paragraph1.boldText}</Strong>
            </Paragraph>
            <Paragraph marginTop="none">
              {importantInfo.paragraph1.supportiveText}
            </Paragraph>
            <Paragraph marginBottom="none">
              <Strong>{importantInfo.paragraph2.boldText}</Strong>
            </Paragraph>
            <Paragraph marginTop="none">
              {importantInfo.paragraph2.supportiveText}
            </Paragraph>
            <Paragraph marginBottom="none">
              <Strong>{importantInfo.paragraph3.boldText}</Strong>
            </Paragraph>
            <Paragraph marginTop="none">
              {importantInfo.paragraph3.supportiveText}
            </Paragraph>
            <Paragraph marginBottom="none">
              <Strong>{importantInfo.paragraph4.boldText}</Strong>
            </Paragraph>
            <Paragraph marginTop="none">
              {importantInfo.paragraph4.supportiveText}
            </Paragraph>
            <Paragraph marginBottom="none">
              <Strong>{importantInfo.paragraph5.boldText}</Strong>
            </Paragraph>
            <Paragraph marginTop="none">
              {importantInfo.paragraph5.supportiveText}
            </Paragraph>
          </SummaryBox>

          <Paragraph marginBottom="none">
            <Strong>{closingSection.boldText}</Strong>
          </Paragraph>
          <Paragraph marginTop="none">
            {closingSection.supportiveText1}
            <Link
              href={`tel:${closingSection.phoneNumber}`}
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/helpline' });
              }}
            >
              {closingSection.phoneNumber}
            </Link>
            {closingSection.supportiveText2}
          </Paragraph>
          <ButtonPattern>
            <StyledNavLink
              iconPosition="left"
              style={{ textAlign: 'center' }}
              to={routes.ManageDirectDebitChangeDateForm}
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/edit-details' });
              }}
            >
              {editPaymentDate}
            </StyledNavLink>
          </ButtonPattern>
          <ButtonPattern marginTop="04">
            <Button
              type="submit"
              iconPosition="right"
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/confirm' });
                onSubmit({ paymentDate: location.state.newDDDate });
              }}
            >
              {confirmButton}
            </Button>
            <StyledNavLink
              data-testid="secondary-back-link"
              iconPosition="left"
              to={routes.ManageDirectDebitChangeDateForm}
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/back-bottom' });
              }}
            >
              {backToOverview}
            </StyledNavLink>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default ChangeDateYouPaySummary;
