import React from 'react';

import { Paragraph, Text } from '@constellation/core';
import { ChevronRight } from '@constellation/core/icons';

import {
  StyledLink,
  CardContainer,
  StyledIconContainer,
} from './GreenLinkIconCard.styled';
import { Margin } from '../types/margin';

interface LinkIconCardProps {
  to: string;
  actionText: string;
  supportingText: string;
  icon: React.ReactElement;
  'data-testid'?: string;
  marginBottom?: Margin;
  onClick?: React.MouseEventHandler;
}

function GreenLinkIconCard({
  to,
  actionText,
  supportingText,
  icon,
  marginBottom = 'none',
  'data-testid': testId,
  onClick,
}: LinkIconCardProps) {
  return (
    <StyledLink
      to={to}
      data-testid={testId}
      $marginBottom={marginBottom}
      onClick={onClick}
    >
      <CardContainer>
        <span>
          <Text inverse size="s3" weight="bold">
            {actionText} <ChevronRight trim />
          </Text>
          <Paragraph inverse size="s2" marginBottom="none">
            {supportingText}
          </Paragraph>
        </span>
        <StyledIconContainer>{icon}</StyledIconContainer>
      </CardContainer>
    </StyledLink>
  );
}

export default GreenLinkIconCard;
