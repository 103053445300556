import React, { useEffect } from 'react';

import {
  Heading,
  Main,
  Paragraph,
  Text,
  Link,
  Strong,
  IconWarning,
  Grid,
  GridItem,
  Hr,
  IconChevronLeft,
  ButtonPattern,
  Button,
  Container,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { NavigationTile } from 'client/components/navigationTile';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import IllustrationCardError from './components/IllustrationCardError';
import ManageDirectDebitFailedContent from './ManageDirectDebitFailedPage.config';

function ManageDirectDebitFailedPage() {
  const {
    backButtonLabel,
    title,
    para1,
    missedPaymentsCta,
    para2heading,
    para2a,
    para2b,
    para3a,
    helpline,
    para3b,
    para4,
    returnToManageLabel,
  } = useContent<ManageDirectDebitFailedContent>();
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'My direct debit has failed or will fail',
      applicationState: 'Pre-Application',
    });
  }, []);

  const helplineNumber = helpline.replace(/\s/g, '');

  return (
    <Main>
      <Container marginBottom="05" padding="none">
        <StyledNavLink
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.ManageDirectDebit}
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
          }}
        >
          {backButtonLabel}
        </StyledNavLink>
      </Container>
      <Hr marginTop="none" />
      <Grid>
        <GridItem md={8}>
          <IllustrationCardError />

          <Heading as="h1" size="s5" marginTop="03">
            {title}
          </Heading>
          <Paragraph>{para1}</Paragraph>
          <NavigationTile
            tileInfo={{
              external: true,
              linkText: missedPaymentsCta.title,
              boxText: missedPaymentsCta.secondaryText,
              url: missedPaymentsCta.url,
              linkTestId: 'missed-payments-cta',
            }}
            tileIcon={<IconWarning iconSize="lg" color="brand" />}
            pageTestId="missed-payments-cta"
            bgColor="critical"
            showBorder
            onClick={() => {
              logTealiumButtonClickEvent({
                label: 'button/missed-payments-cta',
              });
            }}
          />

          <Heading as="h2" size="s2" marginBottom="none">
            {para2heading}
          </Heading>
          <Paragraph>
            <Text>{para2a}</Text>
            <Strong>{para2b}</Strong>
          </Paragraph>
          <Paragraph>
            <Text>{para3a}</Text>
            <Link
              href={`tel:${helplineNumber}`}
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/helpline' });
              }}
            >
              {helpline}
            </Link>
            <Text>{para3b}</Text>
          </Paragraph>
          <Paragraph>{para4}</Paragraph>
          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-manage',
                });
                navigate(routes.Manage);
              }}
            >
              {returnToManageLabel}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default ManageDirectDebitFailedPage;
