import { AppContent } from '../AppContent';

const productTransfer: AppContent['productTransfer'] = {
  backButtonLabel: 'Back',
  eligibleTitle: "It's time to switch your deal",
  eligibleText: 'You can change your mortgage deal.',
  notEligibleTitle: "It's nearly time to switch your deal",
  notEligibleText: 'You can change your mortgage deal from ',
  titleIntro:
    "You'll need to speak to a mortgage broker if you're keeping your property as a buy to let.",
  para1:
    "You'll need to speak to a mortgage broker if you're keeping your property as a buy to let.",
  brokerTitle: 'Not got a mortgage broker?',
  brokerLink: {
    text: 'Search for a broker',
    url: 'https://www.unbiased.co.uk/',
  },
  contactUsTitle: "You'll need to talk to us if you have:",
  contactUsParagraph1:
    "a residential mortgage or you're planning to move yourself or a family member into the rented property in the next two years. Call us on",
  contactUsNumber1: {
    phoneNumber: '0345 608 0263',
  },
  contactUsParagraph2:
    'two years or less left to pay on your mortgage. Call us on',
  contactUsNumber2: {
    phoneNumber: '0800 056 2980',
  },
  contactUsAnythingElse: 'For anything else to do with your mortgage, call',
  contactUsAnythingElseNumber: {
    phoneNumber: '0345 300 2627',
  },
  wereHereTitle: 'Opening times',
  contactMondayToFriday:
    "We're available Monday to Friday 8am to 8pm. On Saturday, it's:",
  contactSaturday1: {
    number: '0345 608 0263',
    time: '8am to 4pm for ',
  },
  contactSaturday2: {
    number: '0800 056 2980',
    time: '8am to 5pm for ',
  },
  contactSaturday3: {
    number: '0345 300 2627',
    time: '8am to 1pm for',
  },
  linesOpeningTime: "We're closed on Sundays and bank holidays.",
};

export default productTransfer;
