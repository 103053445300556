import { isBefore, isValid } from 'date-fns';

import { AddReminderPageContent } from './AddReminderPage.config';

export function reminderValidators(content: AddReminderPageContent) {
  const { validationErrors } = content;

  const reminderType = (value: string): string =>
    !value ? validationErrors.reminderType.required : undefined;

  const renewalDate = (value: { day?: string; month?: string }): string => {
    const inputDay = value?.day ?? '';
    const inputMonth = value?.month?.padStart(2, '0') ?? '';

    const monthNames = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    if (!inputDay) {
      return validationErrors.renewalDate.invalidDate;
    }
    if (!inputMonth) {
      return validationErrors.renewalDate.invalidMonth;
    }
    const parsedDay = parseInt(inputDay, 10);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-indexed

    const monthIndex = monthNames.findIndex(
      (month) => month.toLowerCase() === inputMonth.toLowerCase(),
    );

    if (monthIndex === -1) {
      return validationErrors.renewalDate.invalidMonth;
    }
    // Use next year if the selected month is before the current month
    const year = monthIndex < currentMonth ? currentYear + 1 : currentYear;
    const date = new Date(year, monthIndex, parsedDay);

    if (!isValid(date)) {
      return validationErrors.renewalDate.invalidDate;
    }
    if (date.getDate() !== parsedDay) {
      return validationErrors.renewalDate.invalidDate;
    }
    if (date.getMonth() !== monthIndex) {
      return validationErrors.renewalDate.invalidMonth;
    }
    if (date.getMonth() === monthIndex && date.getDate() !== parsedDay) {
      return validationErrors.renewalDate.invalidDate;
    }
    return undefined;
  };

  const note = (inputNote?: string): string => {
    if (inputNote && inputNote.length > 320) {
      return validationErrors.note.tooLong;
    }
    return undefined;
  };

  const renewalDateWithYear = (value: {
    day?: string;
    month?: string;
    year?: string;
  }): string => {
    const inputDay = value?.day ?? '';
    const inputMonth = value?.month?.padStart(2, '0') ?? '';
    const inputYear = value?.year ?? '';

    if (!inputDay) {
      return validationErrors.renewalDate.invalidDate;
    }
    if (!inputMonth) {
      return validationErrors.renewalDate.invalidMonth;
    }
   
    const parsedDay = parseInt(inputDay, 10);
    const parsedYear = parseInt(inputYear, 10);
    const monthNames = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    const monthIndex = monthNames.indexOf(inputMonth);
    if (monthIndex === -1) {
      return validationErrors.renewalDate.invalidMonth;
    }

    const date = new Date(parsedYear, monthIndex, parsedDay);

    if (!inputYear) {
      return validationErrors.renewalDate.invalidYear;
    }
    
    if (!isValid(date)) {
      return validationErrors.renewalDate.invalidDate;
    }
    if (date.getDate() !== parsedDay) {
      return validationErrors.renewalDate.invalidDate;
    }
    if (date.getMonth() !== monthIndex) {
      return validationErrors.renewalDate.invalidMonth;
    }
    
    if (date.getFullYear() !== parsedYear) {
      return validationErrors.renewalDate.invalidYear;
    }

    const currentDate = new Date();
    if (isBefore(date, currentDate)) {
      return validationErrors.renewalDate.invalidYear;
    }
    return undefined;
  };

  return {
    reminderType,
    renewalDate,
    renewalDateWithYear,
    note,
  };
}
