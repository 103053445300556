import { AppContent } from '../AppContent';

const debitChangeRequest: AppContent['debitChangeRequest'] = {
  pageHeading: 'Request a change to your direct debit due date',
  backButtonLabel: 'Back',
  openingSection: {
    firstParagraph:
      "If you would like to change the date you pay your mortgage, complete this form. You'll receive confirmation of your new payment date within 5 working days by post.",
  },
  whatYouNeedToKnow: {
    heading: 'What you need to know',
    listItems: [
      'Your chosen payment date needs to be between the 1st and 28th of the month.',
      'If we have already requested your payment at the time of completing this form, the change will take place from the following month.',
      'Direct debit payments are requested 5 days before the due date. For example, if you pay your mortgage on the 20th of the month, we would request the funds on the 15th.',
      'Depending on your current and new payment dates, you could make 2 payments in a short period of time.',
      'Your monthly payment amount may rise or fall after you change the date we collect the money from you.',
      'We can only email you if the email you provide matches the one we already hold on our system.',
      'We can only deliver to the correspondence address we currently hold on our system.',
    ],
  },
  formInstruction: 'Complete the following information:',
};

export default debitChangeRequest;
