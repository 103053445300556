import { AppContent } from '../AppContent';

const directDebitChangeDateError: AppContent['directDebitChangeDateError'] = {
  title: 'Service unavailable',
  notification: {
    title: 'Something went wrong',
    para1:
      "We can't change your direct debit date online right now. Please try again later.",
    para2a: 'Or you can call us on ',
    helpline: '0808 145 0372',
    para2b:
      " to change it by phone. Lines are open Monday to Friday 8am to 8pm and Saturday 8am to 1pm. We're closed Sundays and bank holidays.",
  },
  applicationHours: {
    title: 'Application hours',
    mainText:
      'So we can provide you with the best possible service, this online application is only available during these times:',
    monToSatHours: 'Monday to Saturday: 6am to 10pm',
    sunHours: 'Sunday: 6am to 9pm',
  },
  backLinkLabel: 'Back',
};

export default directDebitChangeDateError;
