import React, { Fragment, useEffect } from 'react';

import {
  Container,
  Grid,
  GridItem,
  Heading,
  Hr,
  IconChevronLeft,
  Main,
  Spinner,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import {
  Navigate,
  NavigateFunction,
  useNavigate,
} from '@interstellar/react-app-routing';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/query';
import { FullWidthLink } from 'client/components/fullWidthLink';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import {
  useCreateDDPadNoteMutation,
  useDirectDebitQuery,
} from 'client/services/api';
import {
  CreateDDPadNoteParameters,
  DirectDebitsResponse,
} from 'client/services/types/directDebits';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import {
  checkDDErrors,
  DDIndicatorStatus,
} from './changeDate/utils/ddErrorWarningHandler';
import DirectDebitDetailsPanel from './components/DirectDebitDetailsPanel';
import ManageDirectDebitContent from './ManageDirectDebitPage.config';
import { BreakoutPage, Panel } from './ManageDirectDebitPage.styled';

interface OnClickHandlerParams {
  event: React.MouseEvent<Element>;
  response: DirectDebitsResponse;
  navigate: NavigateFunction;
  createPadNote: MutationTrigger<
    MutationDefinition<CreateDDPadNoteParameters, any, any, any>
  >;
}

export const onClickHandler = async ({
  event,
  response,
  navigate,
  createPadNote,
}: OnClickHandlerParams) => {
  event.preventDefault();

  const responseCode = checkDDErrors({
    respData: response,
  });

  if (!responseCode) {
    navigate(routes.ManageDirectDebitChangeDatePreamble);
    return;
  }

  await createPadNote({
    DDSelfServInd: responseCode as DDIndicatorStatus,
    requestType: 'DDTYP1',
  } as CreateDDPadNoteParameters);

  const warningPage =
    responseCode === 'CDRA'
      ? routes.ChangeDateRaisedWarning
      : routes.ChangeDateWarning;

  navigate(warningPage, {
    state: {
      errorType: responseCode,
    },
  });
};

function ManageDirectDebitPage() {
  const {
    title,
    backButtonLabel,
    noDirectDebitText,
    directDebitDetailsTitle,
    directDebitDetailsSubAccountTitle,
    changeDirectDebitTitle,
    changeDirectDebitDateLinkText,
    manageDirectDebitTitle,
    directDebitFailedLinkText,
    restartCancelledDirectDebitLinkText,
    changeTheAmountYouPayLinkText,
    changeTheAccountYouPayFromLinkText,
    cancelDirectDebitLinkText,
  } = useContent<ManageDirectDebitContent>();
  const { data, isError, isLoading } = useDirectDebitQuery();
  const [createDDPadNote, { isError: isErrorDDPadNote }] =
    useCreateDDPadNoteMutation();
  const navigate = useNavigate();

  const {
    enableDirectDebitsChangePaymentDate,
    enableDirectDebitsChangeBankAccount,
  } = window.appConfig;

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'View direct debit',
      applicationState: 'Pre-Application',
    });
  }, []);

  if (isLoading) {
    return (
      <BreakoutPage>
        <Main>
          <Container data-testid="loading-container">
            <Spinner />
          </Container>
        </Main>
      </BreakoutPage>
    );
  }

  if (isError || data?.errors.length > 0) {
    return <Navigate to={routes.Error} replace />;
  }

  if (isErrorDDPadNote) {
    return (
      <Navigate to={routes.ManageDirectDebitChangeDateUnavailable} replace />
    );
  }

  const { currentDDDetails } = data;
  const hasNoDirectDebit = currentDDDetails.length < 1;
  const hasSingleDirectDebit = currentDDDetails.length === 1;
  const hasMultipleDirectDebits = currentDDDetails.length > 1;
  const hasDirectDebits = currentDDDetails.length >= 1;

  const showChangeDirectDebitPanel =
    enableDirectDebitsChangePaymentDate || enableDirectDebitsChangeBankAccount;

  return (
    <BreakoutPage>
      <Main>
        <Container padding="none" marginBottom="05">
          <StyledNavLink
            icon={<IconChevronLeft trim />}
            iconPosition="left"
            data-testid="back-button-link"
            to={routes.Manage}
            onClick={() => {
              logTealiumButtonClickEvent({ label: 'button/back' });
            }}
          >
            {backButtonLabel}
          </StyledNavLink>
        </Container>
        <Hr marginTop="none" />

        <Grid>
          <GridItem md={8}>
            <Heading as="h1" size="s5" data-testid="page-heading">
              {title}
            </Heading>

            {hasNoDirectDebit && (
              <Panel data-testid="no-direct-debit-panel">
                <Text>{noDirectDebitText}</Text>
              </Panel>
            )}

            {hasSingleDirectDebit && (
              <>
                <Heading
                  as="h2"
                  size="s2"
                  marginBottom="02"
                  data-testid="dd-panel-title"
                >
                  {directDebitDetailsTitle}
                </Heading>
                <DirectDebitDetailsPanel directDebit={currentDDDetails[0]} />
              </>
            )}

            {hasMultipleDirectDebits &&
              currentDDDetails.map((directDebit) => (
                <Fragment key={`sub-account-${directDebit.subAccountNumber}`}>
                  <Heading
                    as="h2"
                    size="s2"
                    marginBottom="02"
                    data-testid={`dd-panel-sub-account-${directDebit.subAccountNumber}-title`}
                  >
                    {`${directDebitDetailsSubAccountTitle} ${directDebit.subAccountNumber}`}
                  </Heading>
                  <DirectDebitDetailsPanel directDebit={directDebit} />
                </Fragment>
              ))}

            {hasDirectDebits && (
              <>
                {showChangeDirectDebitPanel && (
                  <>
                    <Heading
                      as="h2"
                      size="s2"
                      marginBottom="02"
                      data-testid="dd-panel-title"
                    >
                      {changeDirectDebitTitle}
                    </Heading>
                    <Panel>
                      {enableDirectDebitsChangePaymentDate && (
                        <FullWidthLink
                          to={routes.ManageDirectDebitChangeDatePreamble} // This is overriden
                          onClick={(event) => {
                            logTealiumButtonClickEvent({
                              label: 'button/change-the-date-you-pay',
                            });
                            onClickHandler({
                              event,
                              response: data,
                              navigate,
                              createPadNote: createDDPadNote,
                            });
                          }}
                        >
                          {changeDirectDebitDateLinkText}
                        </FullWidthLink>
                      )}

                      {enableDirectDebitsChangePaymentDate &&
                        enableDirectDebitsChangeBankAccount && (
                          <Hr marginTop="04" marginBottom="04" />
                        )}

                      {enableDirectDebitsChangeBankAccount && (
                        <FullWidthLink
                          to={routes.ManageDirectDebitBankAccountPreamble}
                        >
                          {changeTheAccountYouPayFromLinkText}
                        </FullWidthLink>
                      )}
                    </Panel>
                  </>
                )}

                <Heading
                  as="h2"
                  size="s2"
                  marginBottom="02"
                  data-testid="dd-panel-title"
                >
                  {manageDirectDebitTitle}
                </Heading>
                <Panel>
                  <FullWidthLink
                    to={routes.ManageDirectDebitFailed}
                    onClick={() => {
                      logTealiumButtonClickEvent({
                        label: 'button/my-direct-debit-has-failed-or-will-fail',
                      });
                    }}
                  >
                    {directDebitFailedLinkText}
                  </FullWidthLink>
                  <Hr marginTop="04" marginBottom="04" />
                  <FullWidthLink
                    to={routes.RestartCancelledDirectDebit}
                    onClick={() => {
                      logTealiumButtonClickEvent({
                        label: 'button/restart-a-cancelled-direct-debit',
                      });
                    }}
                  >
                    {restartCancelledDirectDebitLinkText}
                  </FullWidthLink>
                  <Hr marginTop="04" marginBottom="04" />
                  <FullWidthLink
                    to={routes.ManageDirectDebitChangeAmount}
                    onClick={() => {
                      logTealiumButtonClickEvent({
                        label: 'button/change-the-amount-you-pay',
                      });
                    }}
                  >
                    {changeTheAmountYouPayLinkText}
                  </FullWidthLink>
                  <Hr marginTop="04" marginBottom="04" />
                  <FullWidthLink
                    to={routes.ManageDirectDebitCancelDirectDebit}
                    onClick={() => {
                      logTealiumButtonClickEvent({
                        label: 'button/cancel-a-direct-debit',
                      });
                    }}
                  >
                    {cancelDirectDebitLinkText}
                  </FullWidthLink>
                </Panel>
              </>
            )}
          </GridItem>
        </Grid>
      </Main>
    </BreakoutPage>
  );
}

export default ManageDirectDebitPage;
