import styled from "styled-components";

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextIconContainer = styled.div`
  display: flex;
  align-items: center;`;