import React from 'react';

import {
  Container,
  Heading,
  Hr,
  IconChevronLeft,
  Link,
  Main,
  Button,
  ButtonPattern,
  Grid,
  GridItem,
  TextField,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import { logTealiumButtonClickEvent } from 'client/tealium';
import { Field, Form, Formik } from 'formik';

import AddReminderEmailContent from './AddReminderEmailPage.config';
import { reminderEmailFormValidators } from '../components/ReminderEmailFormValidators';
import { StyledContainer } from '../ManageReminderPage.styled';

interface FormValues {
  email: string;
  confirmEmail: string;
}

function AddReminderEmailPage() {
  const content = useContent<AddReminderEmailContent>();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    backButtonLabel,
    title,
    emailFieldLabel,
    emailLabelInfo,
    continueButtonLabel,
    confirmEmailLabel,
    previousButtonLabel,
    validationErrors: { emailMismatch },
  } = content;

  const validators = reminderEmailFormValidators(content);
  const validateForm = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (values.email !== values.confirmEmail) {
      errors.confirmEmail = emailMismatch;
    }
    return errors;
  };
  const onSubmit = async (values: FormValues) => {
    navigate(routes.AddReminderSummary, {
      state: {
        ...values,
        ...location.state
      }
    })
  };
  return (
    <Main>
      <StyledContainer>
        <Link
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          as="button"
          data-testid="back-button-link"
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
            navigate(routes.AddReminder, {
              state: {
                ...location.state
              }
            });
          }}
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>
      <Hr marginTop="none" />
      <Heading as="h1" size="s5" data-testid="page-heading">
        {title}
      </Heading>
      <Container marginBottom="05" padding="none">
        <Grid>
          <GridItem md={8}>
            <Formik
              initialValues={{
                email: location.state?.email || "",
                confirmEmail: location.state?.confirmEmail || "",
              }}
              initialErrors={{}}
              onSubmit={onSubmit}
              validate={validateForm}
            >
              {({ values, touched, errors, handleBlur }) => (
                <Form noValidate role="form" data-testid="Reminder-Email-form">
                  <Field
                    as={TextField}
                    name="email"
                    value={values.email}
                    label={emailFieldLabel}
                    supportiveText={emailLabelInfo}
                    validate={validators.email}
                    error={touched.email && errors.email}
                    data-testid="reminder-input-email"
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    marginBottom="06"
                  />
                  <Field
                    as={TextField}
                    name="confirmEmail"
                    label={confirmEmailLabel}
                    value={values.confirmEmail}
                    validate={validators.confirmEmail}
                    error={touched.confirmEmail && errors.confirmEmail}
                    data-testid="reminder-input-confirmEmail"
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    marginBottom="04"
                  />
                  <ButtonPattern marginTop="05">
                    <Button type="submit" iconPosition="right">
                      {continueButtonLabel}
                    </Button>
                    <Link
                      data-testid="secondary-back-link"
                      iconPosition="left"
                      onClick={() => {
                        navigate(routes.AddReminder, {
                          state: {
                            ...location.state
                          }
                        });
                      }}
                    >
                      {previousButtonLabel}
                    </Link>
                  </ButtonPattern>
                </Form>
              )}
            </Formik>
          </GridItem>
        </Grid>
      </Container>
    </Main>
  );
}

export default AddReminderEmailPage;
