import ApiError from 'server/errors/ApiError';

export const ORDER_DOC_CERTIFICATE_OF_INTEREST = 'certificate-of-interest';
export const ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE = 'change-direct-debit-date';
export const ORDER_DOC_DUPLICATE_STATEMENT = 'duplicate-statement';
export const ORDER_DOC_OVERPAYMENT_ALLOWANCE = 'overpayment-allowance';
export const ORDER_DOC_REDEMPTION_STATEMENT = 'redemption-statement';

export type OrderDocKey =
  | typeof ORDER_DOC_CERTIFICATE_OF_INTEREST
  | typeof ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE
  | typeof ORDER_DOC_DUPLICATE_STATEMENT
  | typeof ORDER_DOC_OVERPAYMENT_ALLOWANCE
  | typeof ORDER_DOC_REDEMPTION_STATEMENT;

export type OrderDocumentResponse = { caseCreated: true } | ApiError;

export interface OrderDocumentParameters {
  key: OrderDocKey;
  title: string;
  firstName: string;
  lastName: string;
  email?: string;
  propertyAddress: string;
  postcode: string;
  telephoneNumber: string;
  sendAnnually?: boolean;
  statementType?: string;
  newPaymentDate?: number;
  redemptionDate?: string;
}
