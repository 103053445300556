import { AppContent } from '../AppContent';

const directDebitChangeDateUnavailable: AppContent['directDebitChangeDateUnavailable'] =
  {
    title: 'Service unavailable',
    notification: {
      title: "We can't change your direct debit date right now",
      para: "You'll need to complete this during our application hours.",
    },
    applicationHours: {
      title: 'Application hours',
      mainText:
        'So we can provide you with the best possible service, this online application is only available during these times:',
      monToSatHours: 'Monday to Saturday: 6am to 9pm',
      sunHours: 'Sunday: 6am to 9pm',
    },
    backLinkLabel: 'Back',
  };

export default directDebitChangeDateUnavailable;
