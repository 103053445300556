import React, { useEffect } from 'react';

import {
  Main,
  IconChevronLeft,
  Hr,
  Grid,
  GridItem,
  Heading,
  Button,
  ButtonPattern,
  Container,
  Notification,
  Paragraph,
  Text,
  Link,
  Accordion,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import ChangeDateRaisedWarningContent from './ChangeDateRaisedWarningPage.config';

function ChangeDateRaisedWarningPage() {
  const navigate = useNavigate();

  const {
    backButtonLabel,
    title,
    heading,
    pleaseComeBack,
    altCallUs,
    telNo,
    telNoHref,
    linesAreOpen,
    wereClosed,
    accordions,
    returnToManage,
  } = useContent<ChangeDateRaisedWarningContent>();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: undefined,
      stepName: `Direct debits - Change date warning - raised`,
      applicationState: 'Declined',
    });
  }, []);

  return (
    <Main>
      <Container padding="none" marginBottom="05">
        <StyledNavLink
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.ManageDirectDebit}
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
          }}
        >
          {backButtonLabel}
        </StyledNavLink>
      </Container>
      <Hr marginTop="none" marginBottom="none" />
      <Grid>
        <GridItem md={8}>
          <Heading as="h1" size="s5" marginTop="04" role="heading">
            {title}
          </Heading>
          <Notification
            heading={heading}
            headingLevel={2}
            sentiment='warning'
          >
            <Paragraph>{pleaseComeBack}</Paragraph>
            <Paragraph>
              <Text>{altCallUs}</Text>
              <Link href={telNoHref}>{telNo}</Link>
              <Text>{linesAreOpen}</Text>
            </Paragraph>
            <Paragraph>{wereClosed}</Paragraph>
          </Notification>
          <Accordion label={accordions.mightMissPayment.heading} variation='inpage1'>
            <Paragraph>
              <Text>{accordions.mightMissPayment.pleaseCallUsOn}</Text>
              <Link href={accordions.mightMissPayment.telNoHref}>{accordions.mightMissPayment.telNo}</Link>
              <Text>{accordions.mightMissPayment.wereAvailable}</Text>
            </Paragraph>
          </Accordion>
          <Accordion label={accordions.alreadyMissedPayment.heading} variation='inpage1'>
            <Paragraph>
              <Text>{accordions.alreadyMissedPayment.callUsOn}</Text>
              <Link href={accordions.alreadyMissedPayment.telNoHref}>{accordions.alreadyMissedPayment.telNo}</Link>
              <Text>{accordions.alreadyMissedPayment.linesAreOpen}</Text>
            </Paragraph>
          </Accordion>
          <Accordion label={accordions.troublePayingMortgage.heading} variation='inpage1'>
            <Paragraph>{accordions.troublePayingMortgage.pleaseDontIgnore}</Paragraph>
            <Paragraph>
              <Link href={accordions.troublePayingMortgage.wereHereToHelpHref} external>{accordions.troublePayingMortgage.wereHereToHelpLink}</Link>
              <Text>{accordions.troublePayingMortgage.fullStop}</Text>
            </Paragraph>
          </Accordion>
          <ButtonPattern>
            <Button
              data-testid="secondary-back-link"
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-manage',
                });
                navigate(routes.Manage);
              }}
            >
              {returnToManage}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default ChangeDateRaisedWarningPage;
