import React, { useState } from 'react';

import {
  Button,
  ButtonPattern,
  Container,
  Dialog,
  IconChevronRight,
  Text,
  IconHome,
  IconPerson,
  Link,
  IconSwitchingArrows,
  ContentGroup,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation, useNavigate } from '@interstellar/react-app-routing';
import useAppConfig from 'client/components/appConfig/useAppConfig';
import { GreenLinkIconCard } from 'client/components/greenLinkIconCard';
import { SignOutModalContent } from 'client/content/SignOutModal.config';
import * as routes from 'client/routes/manifest';
import { useSignOutMutation } from 'client/services/api';
import { Overview } from 'client/services/types/overview';
import { logout } from 'client/slices/auth';
import { clear } from 'client/slices/overview';
import { useAppDispatch, useAppSelector } from 'client/store';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { BreakoutContainer, StyledContainer } from './AccountPanel.styled';
import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';
import { NavigationTabs } from '../navigationTabs';

function AccountPanel() {
  const appConfig = useAppConfig();
  const state = useAppSelector<Overview>((appState) => appState.overview);
  const dispatch = useAppDispatch();
  const { overviewTab: { notifications }, signOut } = useContent<
    MortgageOverviewPageContent & SignOutModalContent
  >();
  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [signout] = useSignOutMutation();
  const location = useLocation();

  const { overview } = state;
  const { mortgageAccountNumber, eligibleToSwitch } = overview || {
    mortgageAccountNumber: 0,
    eligibleToSwitch: false,
  };

  return (
    <>
      {signOutDialogOpen && (
        <Dialog
          open={signOutDialogOpen}
          title={signOut.confirmModal.title}
          onClose={() => {
            logTealiumButtonClickEvent({ label: 'button/close-modal' });
            setSignOutDialogOpen(false);
          }}
        >
          <ButtonPattern marginBottom="none">
            <Button
              variation="primary"
              data-testid="sign-out-dialog-confirm"
              icon={<IconChevronRight trim />}
              iconPosition="right"
              onClick={() => {
                signout();
                dispatch(clear());
                dispatch(logout());
                document.cookie = `${appConfig?.cookieName ?? '__Secure_BM_DC_ST'}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${window.location.hostname}; Path=/; SameSite=Strict; Secure`;

                logTealiumButtonClickEvent({ label: 'button/signout' });
                navigate(routes.SignedOut, {
                  state: { previousPath: location.pathname },
                });
              }}
            >
              {signOut.confirmModal.confirmButtonLabel}
            </Button>
            <Button
              variation="secondary"
              data-testid="sign-out-dialog-cancel"
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/cancel-modal' });
                setSignOutDialogOpen(false);
              }}
            >
              {signOut.confirmModal.cancelButtonLabel}
            </Button>
          </ButtonPattern>
        </Dialog>
      )}
      <BreakoutContainer>
        <Container>
          <StyledContainer>
            <Text>
              <IconHome color="inherit" />
              <Text data-testid="account-panel-mortgage-account-number">
                {mortgageAccountNumber}
              </Text>
            </Text>
            <Link
              icon={<IconPerson trim />}
              iconPosition="left"
              as="button"
              data-testid="sign-out-button-link"
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/open-modal' });
                setSignOutDialogOpen(true);
              }}
            >
              {signOut.buttonLabel}
            </Link>
          </StyledContainer>

          <ContentGroup marginTop='04' marginBottom='04'>
            <NavigationTabs />
          </ContentGroup>

          {eligibleToSwitch && location.pathname === routes.MortgageOverview && (
            <GreenLinkIconCard
              to={routes.ProductTransfer}
              icon={<IconSwitchingArrows inverse />}
              actionText={notifications.switchNotification.heading}
              supportingText={notifications.switchNotification.supportiveText}
              data-testid="notification-switch-deal-link"
              marginBottom="none"
              onClick={() =>
                logTealiumButtonClickEvent({
                  label: 'button/itstimetoswitchyourdeal',
                })
              }
            />
          )}
        </Container>
      </BreakoutContainer>
    </>
  );
}

export default AccountPanel;
