import React, { useEffect } from 'react';

import {
  Main,
  Notification,
  Paragraph,
  Link,
  Grid,
  GridItem,
  ButtonPattern,
  Button,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { OverpaymentsAllowanceAccountWarningContent } from './OverpaymentsAllowanceAccountWarningPage.config';
import * as routes from '../../../manifest';

function OverpaymentsAllowanceAccountWarningPage(): React.JSX.Element {
  const {
    notificationHeading,
    notificationSupportText1,
    notificationSupportText2,
    requestPaperStatementParagraph,
    orText,
    contactNumber,
    returnToMortgageOverviewLabel,
  } = useContent<OverpaymentsAllowanceAccountWarningContent>();
  const { requestFormsEnabled } = window.appConfig;

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '2',
      stepName: 'Overpayment allowance - Unable to show figures',
      applicationState: 'Pre-Application',
    });
  }, []);

  const navigate = useNavigate();

  return (
    <Main>
      <Grid>
        <GridItem xs={12} lg={8}>
          <Notification
            sentiment="warning"
            headingLevel={2}
            heading={notificationHeading}
          >
            <Paragraph>
              {requestFormsEnabled ? (
                <>
                  <Link
                    onClick={() => {
                      logTealiumButtonClickEvent({
                        label: 'link/request-paper-statement',
                      });
                      navigate(routes.RequestOverpaymentsAllowance);
                    }}
                    data-testid="request-paper-statement-link"
                  >
                    {requestPaperStatementParagraph}
                  </Link>
                  <Text>{orText}</Text>
                  <Text> {notificationSupportText1.toLowerCase()} </Text>
                </>
              ) : (
                <Text>{notificationSupportText1} </Text>
              )}
              <Link
                onClick={() =>
                  logTealiumButtonClickEvent({ label: 'link/helpline' })
                }
                href={`tel: ${contactNumber}`}
              >
                {contactNumber}
              </Link>
              .
            </Paragraph>
            <Paragraph marginBottom="none">
              {notificationSupportText2}
            </Paragraph>
          </Notification>

          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-mortgage-overview',
                });
                navigate(routes.MortgageOverview);
              }}
            >
              {returnToMortgageOverviewLabel}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default OverpaymentsAllowanceAccountWarningPage;
