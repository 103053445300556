import { format } from 'date-fns';

const formatDate = (date: string, longMonth: boolean = false): string => {
  try {
    const parsedDate = new Date(date);
    return longMonth
      ? format(parsedDate, 'dd MMMM yyyy')
      : format(parsedDate, 'dd/MM/yy');
  } catch (error) {
    return '';
  }
};

export default formatDate;
