import { AppContent } from '../AppContent';

const changeBankAccountPreamble: AppContent['changeBankAccountPreamble'] = {
  heading: 'Change direct debit bank account',
  beforeYouStartHeading:
    'Before you start, here are a few things you should know...',
  listItems: [
    "Payments can only be made from a current account (you can't use a business or savings account).",
    "If it's a joint account, you confirm both account holders agree to the direct debit changes.",
    'You must be named on the mortgage and bank account that the payment is taken from.',
  ],
  contactUsPara1:
    "If you aren't named on the mortgage or bank account, call us on ",
  contactNumber: '0345 300 2627 ',
  contactUsPara2:
    "where our colleagues will help you make changes. Lines are open Monday to Friday 8am to 8pm and Saturday 9am to 4pm. We're closed Sundays and bank holidays.",
  continueLabel: 'Continue',
  backLabel: 'Back',
};

export default changeBankAccountPreamble;
