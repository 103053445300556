import React, { useEffect } from 'react';

import {
  Button,
  ButtonPattern,
  Container,
  Grid,
  GridItem,
  Heading,
  Hr,
  IconChevronLeft,
  List,
  ListItem,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import ChangeDatePreambleContent from './ChangeDatePreamblePage.config';

function ChangeDatePreamblePage() {
  const navigate = useNavigate();
  const content = useContent<ChangeDatePreambleContent>();
  const {
    backButtonLabel,
    heading,
    intro,
    listItems,
    continueButtonLabel,
    secondaryBackLabel,
  } = content;

  useEffect(() => {
    logTealiumPageViewEvent({
      stepName: 'Direct debits - Change date - Before you start',
      stepNumber: '1',
      applicationState: 'Application',
    });
  });

  return (
    <Main>
      <Container marginBottom="05" padding="none">
        <StyledNavLink
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.ManageDirectDebit}
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back-top' });
          }}
        >
          {backButtonLabel}
        </StyledNavLink>
      </Container>
      <Hr marginTop="none" />
      <Grid>
        <GridItem md={8}>
          <Heading as="h1" size="s5">
            {heading}
          </Heading>
          <Paragraph weight="bold" size='s3'>{intro}</Paragraph>
          <List>
            {listItems.map((item) => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </List>
          <ButtonPattern>
            <Button
              iconPosition="right"
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/continue' });
                navigate(routes.ManageDirectDebitChangeDateForm);
              }}
            >
              {continueButtonLabel}
            </Button>
            <StyledNavLink
              data-testid="secondary-back-link"
              iconPosition="left"
              to={routes.ManageDirectDebit}
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/back-bottom' });
              }}
            >
              {secondaryBackLabel}
            </StyledNavLink>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default ChangeDatePreamblePage;
