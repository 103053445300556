import { AppContent } from '../AppContent';

const manageDirectDebit: AppContent['manageDirectDebit'] = {
  title: 'Manage your direct debit',
  backButtonLabel: 'Back',
  directDebitDetailsTitle: 'Direct debit details',
  directDebitDetailsSubAccountTitle: 'Sub-account',
  noDirectDebitText: "You're not paying your mortgage by direct debit.",
  changeDirectDebitTitle: 'Change your direct debit',
  changeDirectDebitDateLinkText: 'Change the date you pay',
  manageDirectDebitTitle: 'Manage your direct debit',
  directDebitFailedLinkText: 'My direct debit has failed or will fail',
  restartCancelledDirectDebitLinkText: 'Restart a cancelled direct debit',
  directDebitDetailsPanel: {
    currentDDLabel: 'Current direct debit',
    paymentDateLabel: 'Payment date',
    paymentDateSuffix: 'of the month',
    accountNameLabel: 'Account name',
    accountNumberLabel: 'Account number',
    sortCodeLabel: 'Sort code',
  },
  changeTheAmountYouPayLinkText: 'Change the amount you pay',
  changeTheAccountYouPayFromLinkText: 'Change the account you pay from',
  cancelDirectDebitLinkText: 'Cancel a direct debit',
};

export default manageDirectDebit;
