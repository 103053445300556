import ChangeDateFormContent from 'client/routes/manageDirectDebit/changeDate/ChangeDateFormPage.config';

const changeDateForm: ChangeDateFormContent = {
  backButtonLabel: 'Back',
  title: 'Change the date you pay',
  currentPaymentDateLabel: 'Current payment date',
  currentPaymentDateSuffix: 'of the month',
  paymentDateField: {
    label: 'New payment date',
    supportiveText: 'Before you choose a date',
    placeholder: 'Please select',
    errors: {
      required: 'Please confirm the new payment date',
    },
  },
  beforeChooseDateDialog: {
    title: 'Before you choose a date',
    paragraphs: [
      'The best day to pay is 1st of the month because we charge the least interest.',
      'Interest is calculated daily for most accounts and your payment date must be the same every month.',
    ],
  },
  currentPaymentDateOptionSuffix: '(Current payment date)',
  secondaryBackLinkLabel: 'Back',
  continueLabel: 'Continue',
};

export default changeDateForm;
