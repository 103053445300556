import React from 'react';

import { Text, Link } from '@constellation/core';
import { OnlineBanking } from '@constellation/core/icons';
import { useContent } from '@interstellar/react-app-content';
import { logTealiumButtonClickEvent } from 'client/tealium';

import {
  NotificationBoxItem,
  NotificationBox,
} from './FutureFeaturesNotification.styled';
import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

interface FutureFeatureNotificationProps {
  mortgageAccountNumber?: string;
}

export default function FutureFeaturesNotification({
  mortgageAccountNumber,
}: FutureFeatureNotificationProps) {
  const {
    feedbackPanel,
    overviewTab: { notifications: {
      futureFeaturesNotification: { line1, line2, linkText: feedbackLinkText },
    } },
  } = useContent<MortgageOverviewPageContent>();

  const feedbackUrl = feedbackPanel.link.url;

  const url = mortgageAccountNumber
    ? `${feedbackUrl}?id=${mortgageAccountNumber}`
    : feedbackUrl;

  return (
    <NotificationBox data-testid="future-feature-notification">
      <NotificationBoxItem>
        <OnlineBanking color="information" size="s4" iconSize="lg" />
      </NotificationBoxItem>
      <NotificationBoxItem>
        <Text weight="bold" size="s3" as="p">
          {line1}
        </Text>
        <Text as="p">{line2}</Text>
        <Text as="p">
          <Link
            href={url}
            external
            data-testid="future-feedback-link"
            onClick={() =>
              logTealiumButtonClickEvent({ label: 'button/giveusyourfeedback' })
            }
          >
            {feedbackLinkText}
          </Link>
        </Text>
      </NotificationBoxItem>
    </NotificationBox>
  );
}
