import React from 'react';

import {
  Box,
  Button,
  Checkbox,
  CurrencyField,
  DateField,
  Dropdown,
  Option,
  Selector,
  SelectorItem,
  Spinner,
  TextField,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { ErrorSummaryBuilder } from 'client/components/errorSummaryBuilder';
import { useOrderDocumentMutation } from 'client/services/api';
import {
  ORDER_DOC_CERTIFICATE_OF_INTEREST,
  ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE,
  ORDER_DOC_DUPLICATE_STATEMENT,
  ORDER_DOC_OVERPAYMENT_ALLOWANCE,
  ORDER_DOC_REDEMPTION_STATEMENT,
  OrderDocKey,
} from 'client/services/types/orderDocument';
import {
  logTealiumBlurEvent,
  logTealiumButtonClickEvent,
} from 'client/tealium';
import { lastDayOfMonth, isSameDay, addMonths } from 'date-fns';
import { Field, Form, Formik } from 'formik';

import { OrderDocumentFormContent } from './OrderDocumentForm.config';
import { orderDocumentFormValidators } from './orderDocumentFormValidators';
import * as routes from '../../../manifest';

interface FormValues {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  propertyAddress: string;
  postcode: string;
  telephoneNumber: string;
  statementType: string;
  sendAnnually: string;
  newPaymentDate: string;
  redemptionDate: {
    day: string;
    month: string;
    year: string;
  };
  confirmationCheckbox: boolean;
}

interface OrderDocumentFormProps {
  orderDocKey: OrderDocKey;
}

function getDefaultRedemptionDate() {
  const today = new Date();
  const lastDayCurrentMonth = lastDayOfMonth(new Date());
  const lastDayNextMonth = lastDayOfMonth(addMonths(lastDayCurrentMonth, 1));

  // default redemption date is last day in the current month
  // if today is the last day in the current month
  // we'll use the last day of the next month instead
  const defaultDate = isSameDay(today, lastDayCurrentMonth)
    ? lastDayNextMonth
    : lastDayCurrentMonth;

  return {
    day: defaultDate.getDate().toString(),
    month: (defaultDate.getUTCMonth() + 1).toString(), // month is zero indexed so add 1
    year: defaultDate.getUTCFullYear().toString(),
  };
}

export default function OrderDocumentForm({
  orderDocKey,
}: OrderDocumentFormProps) {
  let focusTimerStart: number;

  const [orderDocument, { isLoading: isOrderDocumentLoading }] =
    useOrderDocumentMutation();

  const navigate = useNavigate();
  const content = useContent<OrderDocumentFormContent>();

  const {
    titleFieldLabel,
    firstNameFieldLabel,
    lastNameFieldLabel,
    emailFieldLabel,
    propertyAddressFieldLabel,
    propertyAddressFieldSupportiveText,
    postcodeFieldLabel,
    telephoneNumberFieldLabel,
    confirmationCheckboxField,
    statementTypeFieldLabel,
    statementTypeAnnualLabel,
    statementTypeInterimLabel,
    sendAnnuallyFieldLabel,
    newPaymentDateFieldLabel,
    redemptionDateFieldLabel,
    submitButtonLabel,
    validationErrors: { summaryTitle },
  } = content;

  const keyToConfirmationLabel: Record<OrderDocKey, string> = {
    [ORDER_DOC_CERTIFICATE_OF_INTEREST]:
      confirmationCheckboxField.certificateOfInterest,
    [ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE]:
      confirmationCheckboxField.changeDirectDebit,
    [ORDER_DOC_DUPLICATE_STATEMENT]:
      confirmationCheckboxField.duplicateStatement,
    [ORDER_DOC_OVERPAYMENT_ALLOWANCE]:
      confirmationCheckboxField.overpaymentAllowance,
    [ORDER_DOC_REDEMPTION_STATEMENT]:
      confirmationCheckboxField.redemptionStatement,
  };

  const confirmationCheckboxFieldLabel = keyToConfirmationLabel[orderDocKey];

  const validators = orderDocumentFormValidators(content);

  const defaultRedemptionDate = getDefaultRedemptionDate();

  const startFocusTimer = () => {
    focusTimerStart = Date.now();
  };

  const endFocusTimer = (fieldName: string) => {
    const dwell = Date.now() - focusTimerStart;
    logTealiumBlurEvent({ fieldName, dwell });
  };

  const onSubmit = async (values: FormValues) => {
    const redemptionDay = values.redemptionDate.day.toString().padStart(2, '0');
    const redemptionMonth = values.redemptionDate.month
      .toString()
      .padStart(2, '0');
    const redemptionYear = values.redemptionDate.year.toString();

    const redemptionDate = `${redemptionYear}-${redemptionMonth}-${redemptionDay}`;

    const newPaymentDate = parseInt(values.newPaymentDate, 10);

    const sendAnnually = values.sendAnnually === 'true';

    const response = await orderDocument({
      key: orderDocKey,
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      propertyAddress: values.propertyAddress,
      postcode: values.postcode,
      telephoneNumber: values.telephoneNumber,
      ...(values.email ? { email: values.email } : {}),

      // spread an object containing the desired field if the orderDocKey is a
      // match, if it's not a match an empty object is spread so no field will
      // be added to the object
      ...(orderDocKey === ORDER_DOC_CERTIFICATE_OF_INTEREST
        ? { sendAnnually }
        : {}),

      ...(orderDocKey === ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE
        ? { newPaymentDate }
        : {}),

      ...(orderDocKey === ORDER_DOC_REDEMPTION_STATEMENT
        ? { redemptionDate }
        : {}),

      ...(orderDocKey === ORDER_DOC_DUPLICATE_STATEMENT
        ? { statementType: values.statementType }
        : {}),
    });

    if (!response || !response?.data) {
      navigate(routes.Error);
      return;
    }

    navigate(routes.RequestSuccessful, { state: { orderDocKey } });
  };

  return (
    <Formik
      initialValues={{
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        mortgageAccountNumber: '',
        propertyAddress: '',
        postcode: '',
        telephoneNumber: '',
        statementType: '',
        sendAnnually: '',
        redemptionDate: {
          day: defaultRedemptionDate.day,
          month: defaultRedemptionDate.month,
          year: defaultRedemptionDate.year,
        },
        newPaymentDate: '',
        confirmationCheckbox: false,
      }}
      initialErrors={{
        redemptionDate: undefined,
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        submitCount,
        errors,
        isValid,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form noValidate role="form" data-testid={`${orderDocKey}-form`}>
          <ErrorSummaryBuilder
            errors={errors}
            isVisible={submitCount > 0 && !isValid}
            errorTitle={summaryTitle}
          />
          <Box bgColor="neutral2" padding="none" data-testid="request-form">
            <Field
              as={TextField}
              name="title"
              label={titleFieldLabel}
              value={values.title}
              validate={validators.title}
              error={touched.title && errors.title}
              data-testid="request-form-title"
              onFocus={startFocusTimer}
              onBlur={(e) => {
                endFocusTimer('statement-request-title');
                handleBlur(e);
              }}
              marginBottom="04"
            />
            <Field
              as={TextField}
              name="firstName"
              label={firstNameFieldLabel}
              value={values.firstName}
              validate={validators.firstName}
              error={touched.firstName && errors.firstName}
              data-testid="request-form-first-name"
              onFocus={startFocusTimer}
              onBlur={(e) => {
                endFocusTimer('statement-request-firstname');
                handleBlur(e);
              }}
              marginBottom="04"
            />
            <Field
              as={TextField}
              name="lastName"
              label={lastNameFieldLabel}
              value={values.lastName}
              validate={validators.lastName}
              error={touched.lastName && errors.lastName}
              data-testid="request-form-last-name"
              onFocus={startFocusTimer}
              onBlur={(e) => {
                endFocusTimer('statement-request-lastname');
                handleBlur(e);
              }}
              marginBottom="04"
            />
            <Field
              optional
              as={TextField}
              name="email"
              label={emailFieldLabel}
              value={values.email}
              validate={validators.email}
              error={touched.email && errors.email}
              data-testid="request-form-email"
              onFocus={startFocusTimer}
              onBlur={(e) => {
                endFocusTimer('statement-request-emailID');
                handleBlur(e);
              }}
              marginBottom="04"
            />
            <Field
              as={TextField}
              name="propertyAddress"
              label={propertyAddressFieldLabel}
              supportiveText={propertyAddressFieldSupportiveText}
              value={values.propertyAddress}
              validate={validators.propertyAddress}
              error={touched.propertyAddress && errors.propertyAddress}
              data-testid="request-form-address"
              onFocus={startFocusTimer}
              onBlur={(e) => {
                endFocusTimer('statement-request-propertyAddress');
                handleBlur(e);
              }}
              marginBottom="04"
            />
            <Field
              as={TextField}
              name="postcode"
              label={postcodeFieldLabel}
              value={values.postcode}
              validate={validators.postcode}
              error={touched.postcode && errors.postcode}
              data-testid="request-form-postcode"
              onFocus={startFocusTimer}
              onBlur={(e) => {
                endFocusTimer('statement-request-postCode');
                handleBlur(e);
              }}
              marginBottom="04"
            />
            <Field
              as={TextField}
              name="telephoneNumber"
              label={telephoneNumberFieldLabel}
              value={values.telephoneNumber}
              validate={validators.telephoneNumber}
              error={touched.telephoneNumber && errors.telephoneNumber}
              data-testid="request-form-telephone-number"
              onFocus={startFocusTimer}
              onBlur={(e) => {
                endFocusTimer('statement-request-telephoneNumber');
                handleBlur(e);
              }}
              marginBottom="05"
            />

            {orderDocKey === ORDER_DOC_REDEMPTION_STATEMENT && (
              <Field
                as={DateField}
                name="redemptionDate"
                label={redemptionDateFieldLabel}
                day={values.redemptionDate.day}
                month={values.redemptionDate.month}
                year={values.redemptionDate.year}
                startYear={defaultRedemptionDate.year}
                nextNoOfYears={1}
                validate={validators.redemptionDate}
                error={
                  (touched['redemptionDate-day'] ||
                    touched['redemptionDate-month'] ||
                    touched['redemptionDate-year']) &&
                  errors.redemptionDate
                }
                data-testid="request-form-chosen-date-field"
                onChange={(value) => {
                  setFieldValue('redemptionDate', value);
                }}
                onFocus={startFocusTimer}
                onBlur={(e) => {
                  endFocusTimer('statement-request-date-if-not-month-end');
                  handleBlur(e);
                }}
                marginBottom="05"
              />
            )}

            {orderDocKey === ORDER_DOC_CHANGE_DIRECT_DEBIT_DATE && (
              <Field
                integer
                as={CurrencyField}
                name="newPaymentDate"
                label={newPaymentDateFieldLabel}
                showCurrencySymbol={false}
                value={values.newPaymentDate}
                validate={validators.newPaymentDate}
                error={touched.newPaymentDate && errors.newPaymentDate}
                data-testid="debit-change-new-date-field"
                onFocus={startFocusTimer}
                onBlur={(e) => {
                  endFocusTimer('statement-request-new-payment-date');
                  handleBlur(e);
                }}
                marginBottom="05"
              />
            )}

            {orderDocKey === ORDER_DOC_CERTIFICATE_OF_INTEREST && (
              <Field
                as={Dropdown}
                name="sendAnnually"
                label={sendAnnuallyFieldLabel}
                validate={validators.sendAnnually}
                error={touched.sendAnnually && errors.sendAnnually}
                data-testid="request-form-send-annually"
                onClick={(e) => {
                  const { value } = e.target;
                  logTealiumButtonClickEvent({
                    label: `button/send-annually-${value}`,
                  });
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  const displayText = value === 'true' ? 'yes' : 'no';
                  logTealiumBlurEvent({
                    fieldName: `statement-request-annual-${displayText}`,
                  });
                }}
                marginBottom="05"
              >
                <Option hidden>Please select</Option>
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Field>
            )}

            {orderDocKey === ORDER_DOC_DUPLICATE_STATEMENT && (
              <Field
                as={Selector}
                name="statementType"
                label={statementTypeFieldLabel}
                validate={validators.statementType}
                error={touched.statementType && errors.statementType}
                data-testid="request-form-statement-type"
                variation="Spaced-Auto"
                marginBottom="05"
              >
                <SelectorItem
                  id="statement-type-annual"
                  data-testid="request-form-statement-type-annual"
                  name="statementType"
                  value="Annual"
                  onClick={() => {
                    logTealiumButtonClickEvent({
                      label: 'button/statement-type-annual',
                    });

                  }}
                  onBlur={(e) => {
                    logTealiumBlurEvent({
                      fieldName: 'statement-request-statement-type-annual',
                    });
                  }}
                >
                  {statementTypeAnnualLabel}
                </SelectorItem>
                <SelectorItem
                  id="statement-type-interim"
                  data-testid="request-form-statement-type-interim"
                  name="statementType"
                  value="Interim"
                  onClick={() => {
                    logTealiumButtonClickEvent({
                      label: 'button/statement-type-interim',
                    });

                  }}
                  onBlur={(e) => {
                    logTealiumBlurEvent({
                      fieldName: 'statement-request-statement-type-interim',
                    });
                  }}
                >
                  {statementTypeInterimLabel}
                </SelectorItem>
              </Field>
            )}

            <Field
              as={Checkbox}
              name="confirmationCheckbox"
              label={confirmationCheckboxFieldLabel}
              value={values.confirmationCheckbox}
              validate={validators.confirmationCheckbox}
              error={
                touched.confirmationCheckbox && errors.confirmationCheckbox
              }
              data-testid="request-form-confirmation-checkbox"
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/confirm' });
                logTealiumBlurEvent({
                  fieldName: 'statement-request-i-confirm',
                });
              }}
              marginBottom="05"
            />

            {isOrderDocumentLoading || isSubmitting ? (
              <Spinner />
            ) : (
              <Button
                type="submit"
                data-testid="request-form-submit-button"
                onClick={() => {
                  logTealiumButtonClickEvent({ label: 'button/submit' });
                }}
              >
                {submitButtonLabel}
              </Button>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
}
