import { Link } from '@interstellar/react-app-routing';
import styled from 'styled-components';

import { Margin } from '../types/margin';

interface StyledLinkProps {
  $marginBottom: Margin;
}

export const StyledLink = styled(Link) <StyledLinkProps>`
  text-decoration: none;
  display: block;
  margin: 1rem 0;
  margin-bottom: ${({ theme, $marginBottom }) => {
    const spacingString = `spacing_${$marginBottom}`;
    return $marginBottom === 'none' ? 0 : theme[spacingString];
  }};
`;

export const CardContainer = styled.span`
  border: 3px solid ${({ theme }) => theme.color_source_secondary_1};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing_03};
  padding-left: ${({ theme }) => theme.spacing_05};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIconContainer = styled.div`
  span {
    min-width: 3rem;
    min-height: 4rem;
    justify-content: flex-end !important;
  }
`;
