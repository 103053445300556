import React, { useEffect } from 'react';

import {
  Button,
  ButtonPattern,
  Container,
  Grid,
  GridItem,
  Heading,
  Hr,
  IconChevronLeft,
  Link,
  Main,
  Paragraph,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import IllustrationRestart from './components/IllustrationRestart';
import RestartCancelledDirectDebitContent from './RestartCancelledDirectDebit.config';

function RestartCancelledDirectDebit() {
  const navigate = useNavigate();

  const {
    backButtonLabel,
    heading,
    para1a,
    helpline,
    para1b,
    para2,
    secondaryBackButtonLabel,
  } = useContent<RestartCancelledDirectDebitContent>();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Restart a cancelled direct debit',
      applicationState: 'Referred',
    });
  }, []);

  const helplineHref = `tel:${helpline.replace(/\s/g, '')}`;

  return (
    <Main>
      <Container padding="none" marginBottom="05">
        <StyledNavLink
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.ManageDirectDebit}
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
          }}
        >
          {backButtonLabel}
        </StyledNavLink>
      </Container>
      <Hr marginTop="none" />
      <Grid>
        <GridItem md={8}>
          <IllustrationRestart />
          <Heading as="h1" size="s5" marginTop="04">
            {heading}
          </Heading>
          <Paragraph>
            <Text>{para1a}</Text>
            <Link
              href={helplineHref}
              data-testid="helpline-link"
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/helpline' });
              }}
            >
              {helpline}
            </Link>
            <Text>{para1b}</Text>
          </Paragraph>
          <Paragraph>{para2}</Paragraph>
          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-manage',
                });
                navigate(routes.Manage);
              }}
              data-testid="back-to-manage-button"
            >
              {secondaryBackButtonLabel}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default RestartCancelledDirectDebit;
