import { AppContent } from '../AppContent';

const manageReminder: AppContent['manageReminder'] = {
  title: 'Reminders',
  backButtonLabel: 'Back to overview',
  addYourFirstReminderButtonLabel: 'Add your first reminder',
  addReminderButtonLabel: 'Add reminder',
};

export default manageReminder;
