import React, { useEffect } from 'react';

import {
  Main,
  IconChevronLeft,
  Hr,
  Grid,
  GridItem,
  Paragraph,
  Heading,
  Link,
  Button,
  ButtonPattern,
  Text,
  Container,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { StyledNavLink } from 'client/components/styledNavLink/StyledNavLink';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import CancelDirectDebitContent from './CancelDirectDebitPage.config';
import IllustrationCancel from './components/IllustrationCancel';

export interface FormValues {
  paymentDate: string;
}

function CancelDirectDebitPage() {
  const navigate = useNavigate();
  const { backButtonLabel, title, callUs, availability, returnToManage } =
    useContent<CancelDirectDebitContent>();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Cancel a direct debit',
      applicationState: 'Referred',
    });
  }, []);

  return (
    <Main>
      <Container padding="none" marginBottom="05">
        <StyledNavLink
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.ManageDirectDebit}
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
          }}
        >
          {backButtonLabel}
        </StyledNavLink>
      </Container>
      <Hr marginTop="none" />
      <Grid>
        <GridItem md={8}>
          <IllustrationCancel />
          <Heading as="h1" size="s5" marginTop="04" role="heading">
            {title}
          </Heading>
          <Paragraph>
            <Text>{callUs.primaryText}</Text>
            <Link
              href={callUs.href}
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/helpline' });
              }}
            >
              {callUs.phoneNumber}
            </Link>
            <Text>{callUs.secondaryText}</Text>
          </Paragraph>
          <Paragraph>{availability}</Paragraph>
          <ButtonPattern>
            <Button
              data-testid="secondary-back-link"
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-manage',
                });
                navigate(routes.Manage);
              }}
            >
              {returnToManage}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default CancelDirectDebitPage;
