import React from 'react';

import { Box, Strong, Heading, Paragraph, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function PaymentHelpPanel() {
  const { overviewTab: { paymentHelpPanel } } = useContent<MortgageOverviewPageContent>();

  return (
    <Box bgColor="neutral2" data-testid="overview-payment-help-panel">
      <Heading as="h2" size="s4">
        {paymentHelpPanel.heading}
      </Heading>
      <Paragraph>
        <Text>{paymentHelpPanel.paragraph1} {' '}</Text>
        <Strong>{paymentHelpPanel.helpNumber}</Strong>
        <Text>{paymentHelpPanel.period}</Text>
      </Paragraph>
      <Paragraph>{paymentHelpPanel.paragraph2}</Paragraph>
      <Paragraph marginBottom='none'>{paymentHelpPanel.paragraph3}</Paragraph>
    </Box>
  );
}

export default PaymentHelpPanel;
