import React from 'react';

import { IconPound, Hr, IconCalendar, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { formatCurrency } from 'client/routes/mortgageOverview/utils/formatCurrency';
import { DirectDebitDetails } from 'client/services/types/directDebits';
import getOrdinal from 'client/utils/getOrdinal';

import { TextIconContainer } from '../changeDate/changeDateConfirmation/ChangeDateConfirmationPage.styled';
import ManageDirectDebitContent from '../ManageDirectDebitPage.config';
import { DetailsRow, Panel } from '../ManageDirectDebitPage.styled';

interface DirectDebitDetailsPanelProps {
  directDebit: DirectDebitDetails;
}

function DirectDebitDetailsPanel({
  directDebit: {
    subAccountNumber,
    directDebitAmount,
    paymentDate,
    accountName,
    accountNumber,
    sortCode,
  },
}: DirectDebitDetailsPanelProps) {
  const { directDebitDetailsPanel } = useContent<ManageDirectDebitContent>();
  const {
    currentDDLabel,
    paymentDateLabel,
    paymentDateSuffix,
    accountNameLabel,
    accountNumberLabel,
    sortCodeLabel,
  } = directDebitDetailsPanel;

  const testIdPrefix = `dd-panel-subaccount-${subAccountNumber}`;

  return (
    <Panel data-testid={`${testIdPrefix}`}>
      <DetailsRow data-testid={`${testIdPrefix}-amount-row`}>
        <TextIconContainer>
          <IconPound color="brand" size="s4" trim />
          <Text weight="bold" marginLeft="02">
            {currentDDLabel}
          </Text>
        </TextIconContainer>
        <Text
          weight="bold"
          color="subdued"
          data-testid={`${testIdPrefix}-amount`}
        >
          {formatCurrency(directDebitAmount)}
        </Text>
      </DetailsRow>

      <Hr marginTop="02" marginBottom="02" />

      <DetailsRow data-testid={`${testIdPrefix}-date-row`}>
        <TextIconContainer>
          <IconCalendar color="brand" size="s4" trim />
          <Text weight="bold" marginLeft="02">
            {paymentDateLabel}
          </Text>
        </TextIconContainer>
        <Text
          color="subdued"
          weight="bold"
          data-testid={`${testIdPrefix}-date`}
        >
          {`${paymentDate}${getOrdinal(paymentDate)} ${paymentDateSuffix}`}
        </Text>
      </DetailsRow>

      <Hr marginTop="02" marginBottom="02" />

      <DetailsRow data-testid={`${testIdPrefix}-account-name-row`}>
        <Text>{accountNameLabel}</Text>
        <Text color="subdued" data-testid={`${testIdPrefix}-account-name`}>
          {accountName}
        </Text>
      </DetailsRow>

      <Hr marginTop="02" marginBottom="02" />

      <DetailsRow data-testid={`${testIdPrefix}-account-number-row`}>
        <Text>{accountNumberLabel}</Text>
        <Text color="subdued" data-testid={`${testIdPrefix}-account-number`}>
          {accountNumber}
        </Text>
      </DetailsRow>

      <Hr marginTop="02" marginBottom="02" />

      <DetailsRow data-testid={`${testIdPrefix}-sort-code-row`}>
        <Text>{sortCodeLabel}</Text>
        <Text color="subdued" data-testid={`${testIdPrefix}-sort-code`}>
          {sortCode}
        </Text>
      </DetailsRow>
    </Panel>
  );
}

export default DirectDebitDetailsPanel;
