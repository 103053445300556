import { AppContent } from '../AppContent';

const restartCancelledDirectDebit: AppContent['restartCancelledDirectDebit'] = {
  backButtonLabel: 'Back',
  heading: 'Restart a cancelled direct debit',
  para1a:
    "If you've cancelled a direct debit, and haven't changed your bank account and need to set it back up, please call us on ",
  helpline: '0345 300 2627',
  para1b: '.',
  para2:
    "We're available Monday to Friday 8am to 8pm and Saturday 9am to 1pm. We're closed on Sundays and bank holidays.",
  secondaryBackButtonLabel: 'Return to Manage',
};

export default restartCancelledDirectDebit;
