import { parse, isValid, isFuture, isPast, isToday } from 'date-fns';

// validate a date string in the shorter ISO8601 format YYYY-MM-DD
export interface Iso8601DateSchemaErrorMessages {
  wrongType: string;
  empty: string;
  badPattern: string;
  invalidDate: string;
  dateOutOfRange: string;
}

interface Iso8601DateSchemaOptions {
  mustBe?: 'inFuture' | 'inPast' | 'today';
}

export function Iso8601DateSchema(
  errorMessages: Iso8601DateSchemaErrorMessages,
  options?: Iso8601DateSchemaOptions,
) {
  return (value: any) => {
    const ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
    const VALID_TYPE = 'string';
    const getDate = () => parse(value, 'yyyy-MM-dd', new Date());

    if (typeof value !== VALID_TYPE) {
      return errorMessages.wrongType;
    }

    if (!value) {
      return errorMessages.empty;
    }

    if (!ISO_DATE_REGEX.test(value)) {
      return errorMessages.badPattern;
    }

    if (!isValid(getDate())) {
      return errorMessages.invalidDate;
    }

    if (options?.mustBe === 'inFuture' && !isFuture(getDate())) {
      return errorMessages.dateOutOfRange;
    }

    if (options?.mustBe === 'inPast' && !isPast(getDate())) {
      return errorMessages.dateOutOfRange;
    }

    if (options?.mustBe === 'today' && !isToday(getDate())) {
      return errorMessages.dateOutOfRange;
    }

    return undefined;
  };
}
