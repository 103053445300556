import React from 'react';

import { Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation } from '@interstellar/react-app-routing';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { StyledTabLink, StyledTabsContainer } from './NavigationTabs.styled';
import * as routes from '../../../manifest';
import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function NavigationTabs() {
  const location = useLocation();
  const { pathname } = location;
  const { tabHeaders } = useContent<MortgageOverviewPageContent>();

  const overviewTabSelected = pathname === routes.MortgageOverview;
  const manageTabSelected = pathname === routes.Manage;
  const toolsTabSelected = pathname === routes.Tools;

  return (
    <StyledTabsContainer>
      <StyledTabLink
        selected={overviewTabSelected}
        to={routes.MortgageOverview}
        onClick={() => logTealiumButtonClickEvent({ label: 'button/overview' })}
      >
        <Text
          inverse={overviewTabSelected}
          color={overviewTabSelected ? undefined : 'brand'}
          size="s2"
          weight={overviewTabSelected ? 'bold' : 'normal'}
        >
          {tabHeaders.overview}
        </Text>
      </StyledTabLink>
      <StyledTabLink
        selected={manageTabSelected}
        to={routes.Manage}
        onClick={() => logTealiumButtonClickEvent({ label: 'button/manage' })}
      >
        <Text
          inverse={manageTabSelected}
          color={manageTabSelected ? undefined : 'brand'}
          size="s2"
          weight={manageTabSelected ? 'bold' : 'normal'}
        >
          {tabHeaders.manage}
        </Text>
      </StyledTabLink>
      <StyledTabLink
        selected={toolsTabSelected}
        to={routes.Tools}
        onClick={() => logTealiumButtonClickEvent({ label: 'button/tools' })}
      >
        <Text
          inverse={toolsTabSelected}
          color={toolsTabSelected ? undefined : 'brand'}
          size="s2"
          weight={toolsTabSelected ? 'bold' : 'normal'}
        >
          {tabHeaders.tools}
        </Text>
      </StyledTabLink>
    </StyledTabsContainer>
  );
}

export default NavigationTabs;
